
import React from "react";
import {
    Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm,
    Tooltip, Modal, Table, Select, Row, Col, Checkbox, message, Drawer, Collapse
} from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import SelectorSalesAlerts from "./SelectorSalesAlerts";
import SelectorTaxType from "./SelectorTaxType";
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";

import "../style/editCreateCustomerStyle.css";
import GenericSelectorCustTags from "./GenericSelectorCustTags";
import GenericSelector from "./GenericSelector";
import SignatureInput from "./SignatureInputV2";
import { disabledDatePicker } from "../utils/utils";

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


//type State = {}
class ModalCreateEditCustomer extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = 'customer';
        this.id_tags = 'CustomerTa';
        //this.id = "CREATE_CUST";              // create edit customer
        //this.id_tags = "CREATE_CUST_CUST_TAGS";   // create edit customer tags
        this.id_format = "FORMAT_CUST";             // format

        this.state.collapeKey = null;
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.itemPick = null;
        this.fieldsMeta = { unknown: { validateStatus: null, value: null } };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_format] === undefined) { props.data[this.id_format] = { ...props.data.genericData }; }
        if (props.data[this.id_tags] === undefined) { props.data[this.id_tags] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorPriceList] === undefined) {
            props.data[TableIDs.genericSelectorPriceList] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorCustomerTitles] === undefined) {
            props.data[TableIDs.genericSelectorCustomerTitles] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }

        this.api = {
            get_format: "get_customer_fields_full",
            get: "get_customer_by_id",
            //edit: "edit_create_customer", // and create
            edit: "v2/set_customer",
            selector: "get_customer_tags_list",
            get_tags: "get_customer_tags_by_id",
            //delete: "delete_customer", //"delete_priceList",
            selector_price_list: "get_prices",
            selector_titles: "get_customer_titles",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        },/* {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];
        //this.init();
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()

    }

    collapeCallback = (key) => {
        this.setState({ collapeKey: key });
    }

    refreshData = () => {
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode,
            this.props.user.token, this.api.selector);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPriceList, this.props.user.companyCode, this.props.user.token, this.api.selector_price_list);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorCustomerTitles, this.props.user.companyCode, this.props.user.token, this.api.selector_titles);

        this.props.dataActions.genericDataSetFilter(
            this.id_format, this.props.user.companyCode, this.props.user.token, { rID: this.props.roleId }, this.api.get_format);
    }

    generateFieldsFormat = () => {
        const dataSource = Jax.get(this.props.data, this.id_format + ".data", []).map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                CustomerFieldId: y[0],
                CustomerFieldName: y[1],
                CustomerFieldDescription: y[2],
                CustomerFieldIsActive: y[3],
                CustomerFieldIsMandatory: y[4],
                CustomerFieldIsSearchable: y[5],
                CustomerFieldContainer: y[6],
                CustomerFieldContainerOrder: y[7],
                CustomerFieldControlType: y[8],
                CustomerFieldQuery: y[9],
                CustomerFieldMaxLength: y[10],
                CustomerFieldRoleListIsReadOnly: y[11],
                CustomerFieldRoleListDefaultValue: y[12],
                CustomerFieldSelectorData: y[13],
                index: index
            })
        });
        return dataSource;
    }
    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditCustomer);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        if (this.props.roleId === 3) {
            window.open("/", "_self");
        } else {
            this.props.uiActions.hideModal(TableIDs.modalCreateEditCustomer);
        }
    }
    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        let emailValue = this.props.data[this.id].editedRecord.CustomerContactPersonEmail;
        // if (emailValue && (emailValue.indexOf("@") == -1 || ["il", "com"].indexOf(emailValue?.toLowerCase().split(".").slice(-1)[0]) == -1)) {
      // אילן ביקש לשנות בתאריך 2024-08-15
        if (emailValue && !this.emailValidate(emailValue)) {
            message.error(this.getText(19340));
            return;
        }
        if (this.isValid()) {
            //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
            /*
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEditMulti,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit,
                [
                    { name: 'customer' }, //{ name: this.id },
                    { name: 'CustomerTa', sendDataTable: true } //{ name: this.id_tags, sendDataTable: true }
                ]]
            })
            */

            if (this.props.isRefreshParentTable) {
                console.log("with refresh parent");
                this.props.dataActions.genericDataJustSaveEditMulti(
                    this.id, this.props.user.companyCode, this.props.user.token, this.api.edit,
                    [
                        { name: 'customer' }, //{ name: this.id },
                        { name: 'CustomerTa', sendDataTable: true } //{ name: this.id_tags, sendDataTable: true }
                    ], {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: ["CUSTOMER_TABLE", this.props.user.companyCode, this.props.user.token, "get_customers"]
                }
                )
            } else if (this.props.onSave) {
                const { editedRecord } = this.props.data[this.id];
                let customer = Object.keys(editedRecord).join("\f") + "\r" + Object.keys(editedRecord).map(x => editedRecord[x] ?? "").join("\f");
                let CustomerTa = "CustTagTagId\r" + editedRecord.CustomerGroupID.split(",").join("\r")
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, this.api.edit, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                job.setSection("customer", customer)
                job.setSection("CustomerTa", CustomerTa)
                job.send("/cgi-bin/CashOnTab", ob => {
                    if (ob.data) this.props.onSave({ code: ob.data.split("\r")[1], name: editedRecord.CustomerContactPersonName })
                }, e => { console.error(e) });
            } else {
                this.props.dataActions.genericDataJustSaveEditMulti(
                    this.id, this.props.user.companyCode, this.props.user.token, this.api.edit,
                    [
                        { name: 'customer' }, //{ name: this.id },
                        { name: 'CustomerTa', sendDataTable: true } //{ name: this.id_tags, sendDataTable: true }
                    ]
                )
            }



            /*
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataGetRequested,
                args: ["CUSTOMER_TABLE", this.props.user.companyCode, this.props.user.token, "get_customers"]
            })
            */

            this.props.uiActions.hideModal(TableIDs.modalCreateEditCustomer);
        }
        else {
            message.warning(this.getText(10737));
        }
    }

    createFormItem = (field: Field) => {
        this.fieldsMeta[field.key] = { validateStatus: null, value: null };
        let showStyle = (field.toggle === '1') ? { display: 'block' } : { display: 'none' };
        let isError = (field.isMandatory === '1' && this.isValidField(field.name) === false)
            ? true : false;
        //? { color: '#f5222d' } : { display: 'none' }

        return (
            <FormItem
                key={field.key}
                label={field.label}
                style={{ marginBottom: 8, ...showStyle }}

                help={field.help}
                //validateStatus={this.fieldsMeta[field.key].validateStatus}
                validateStatus={(isError) ? "error" : "success"}
                required={field.isMandatory === '1'}
                extra={<label style={(isError) ? { color: '#f5222d' } : { display: 'none' }}>{this.getText(10738)}</label>}

                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}>
                {field.node}
            </FormItem>)
    }

    isValidField = (field) => {
        return (this.props.data[this.id].editedRecord[field] === '' ||
            this.props.data[this.id].editedRecord[field] === null) ? false : true;
    }

    isValid = () => {

        return this.dataSource
            .filter((item) => item.CustomerFieldIsMandatory === '1' && item.CustomerFieldIsActive === '1' && item.CustomerFieldRoleListIsReadOnly === '0')
            .reduce((acc, item) => acc && this.isValidField(item.CustomerFieldName), true)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    emailValidate = (email) => {
       var patt = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9][a-zA-Z0-9.-]*\.[a-zA-Z]{2,4}$/);
        return patt.test(email);
    }

    getNodeByControlerType = (item) => {
        const field = item.CustomerFieldName;
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";
        const dateFormat = "DD/MM/YYYY";

        let isNew = !(this.props.data[this.id].editedRecord.key > 0);

        let isActive = item.CustomerFieldIsActive;
        let isSearch = item.CustomerFieldIsSearchable;
        let isMandatory = item.CustomerFieldIsMandatory;
        let toggle = (isActive) ? { display: "inline" } : { display: "none" };
        let readOnly = item.CustomerFieldRoleListIsReadOnly == "1" ? true : false;
        if (!isNew && field === 'CustomerID') readOnly = true;
        console.log("readOnly bug", readOnly, isNew, this.props.data[this.id].editedRecord)

        let defValue = item.CustomerFieldRoleListDefaultValue;
        let iStyle = { width: '100%', paddingLeft: '4px' }

        let onChange = (value) => {
            this.props.dataActions.genericDataSetEdit(this.id, { [field]: value ? value : '' });
        }

        let onBlur = (e) => {
            if (isNew && field === 'CustomerID') {
                this.sendAPI('check_if_exists_customer', e.target.value, (ob) => {
                    if (parseInt(ob.data)) {
                        message.error(this.getText(16225));
                        onChange('');
                    }
                });
            }

            if (field === 'CustomerContactPersonMobile' && e.target.value) {
                this.sendAPI('check_if_exists_cust_mobile_v2', '_mobile\f_id\r' + e.target.value + '\f' + this.props.data[this.id].editedRecord.key, (ob) => {
                    if (parseInt(ob.data)) {
                        message.error(this.getText(16405));
                        onChange('');
                    }
                });
            }

        }



        switch (item.CustomerFieldControlType) {
            case '0':   // container title
                return (<span>title</span>);

            case '1':   // text
                return (<Input
                    onBlur={onBlur}
                    disabled={readOnly}
                    // autoComplete="off" // לא עובד
                    readOnly={true}
                    onFocus={readOnly ? () => { } : e => e.target.removeAttribute('readonly')} // על מנת למנוע השלמה אוטומטית של סיסמאות
                    value={this.props.data[this.id].editedRecord[field]}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: value.target.value });
                    }} />);
            case '2':   // price
                return (<InputNumber min={0} precision={2}
                    onBlur={onBlur}
                    disabled={readOnly} style={iStyle}
                    value={this.props.data[this.id].editedRecord[field]}
                    onChange={onChange} />);
            case '3':   // float
                return (<InputNumber precision={2}
                    onBlur={onBlur}
                    disabled={readOnly} style={iStyle}
                    value={this.props.data[this.id].editedRecord[field]}
                    onChange={onChange} />);
            case '4':   // int
                return (<InputNumber min={0}
                    onBlur={onBlur}
                    disabled={readOnly} style={iStyle}
                    value={this.props.data[this.id].editedRecord[field]}
                    onChange={onChange} />);
            case '5':   // date
                return (
                    <DatePicker
                        onBlur={onBlur}
                        disabled={readOnly}
                        value={(this.props.data[this.id].editedRecord[field] === '') ? null :
                            moment(this.props.data[this.id].editedRecord[field], sqlFormat)}
                        //defaultValue={moment('2000/01/01', dateFormat)}
                        format={dateFormat}
                        onChange={(date: moment, dateString: string) => {
                            this.props.dataActions.genericDataSetEdit(this.id, { [field]: date != null ? date.format("YYYY-MM-DD") : "" });
                        }}
                        disabledDate={d => field == "CustomerBirthDate" && disabledDatePicker(d, null, moment().format("YYYY-MM-DD"))}
                    />
                );

            case '6':   // bool
                return (<Checkbox
                    //onChange={(e) => { this.handleChangeCheckBox(e, text, "mIsIncludeVat", record); }}
                    onBlur={onBlur}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: (e.target.checked) ? 1 : 0 });
                    }}
                    checked={(this.props.data[this.id].editedRecord[field] === 1
                        || this.props.data[this.id].editedRecord[field] === '1') ? true : false}
                    defaultChecked={false}
                    //disabled={!(this.props.data[this.id].editing === record.key)} />
                    disabled={readOnly}
                />
                )

            case '7': case '8':  // select
                if (field === "CustomerGroupID") {
                    let custTagsValue = this.props.data[this.id_tags].data;

                    return (
                        <GenericSelectorCustTags
                            {...this.props}
                            multi
                            onBlur={onBlur}
                            onChange={(e) => {
                                this.props.dataActions.setData("tagsEditCust", e);
                                this.props.dataActions.genericDataSetEdit(this.id, { [field]: e ? e.join(',') : '' })
                            }}
                            style={{ ...toggle }}//, width: '100%' }}
                            //value={this.props.data[this.id_tags].editedRecord[field]}
                            value={custTagsValue && custTagsValue[0] ? custTagsValue : []}//['CustTagTagId']}
                            disabled={readOnly}
                        />
                    );

                } else {

                    let list = [];
                    if (item.CustomerFieldSelectorData?.indexOf('[') > -1) list = JSON.parse(item.CustomerFieldSelectorData);
                    let value = this.props.data[this.id].editedRecord[field];
                    let onChange = e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }

                    if (item.CustomerFieldControlType == '7') {

                        return (<Select {...{ onBlur, value, onChange, disabled: readOnly, style: iStyle }}>
                            <Option key={"0"} value={""} >-</Option>
                            {list.map(obj => (<Option key={obj['code']} value={obj['code']?.toString()} valuefilter={obj['code'] + obj['name']}>{obj['code'] + " - " + obj['name']}</Option>))}
                        </Select>)

                    } else {

                        return (<Checkbox
                            disabled={readOnly}
                            checked={value && list.length === value.split(",").length}
                            indeterminate={value?.split(",").length && value?.split(",").length < list.length}
                            onChange={(e) => { onChange(e.target.checked ? list.map(x => x.code).join(",") : "") }}
                        >
                            <Select
                                mode={"multiple"} maxTagCount={4} onBlur={onBlur} disabled={readOnly} style={iStyle}
                                value={value ? value.split(",") : []}
                                onChange={(e) => { onChange(e ? e.join(",") : "") }}>
                                {list.map(obj => (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>))}
                            </Select>
                        </Checkbox>)
                    }
                }
            // case '7':   // select
            //     //let list = this.props.data[TableIDs.genericSelectorCustomerTags].dataset.map((obj, index) => {
            //     //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
            //     //});

            //     // let list = [];
            //     if (field === "CustomerSumDocPrintFormat") {
            //         return (<Select
            //             onBlur={onBlur}
            //             disabled={readOnly}
            //             value={this.props.data[this.id].editedRecord[field]}
            //             defaultValue=""
            //             style={iStyle}
            //             onChange={(e) => {
            //                 this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
            //             }} >
            //             <Option value={'0'}>{this.getText(18979)}</Option>
            //             <Option value={'1'}>{this.getText(18980)}</Option>
            //             <Option value={'6'}>{this.getText(19360)}</Option>
            //             <Option value={'2'}>{this.getText(18981)}</Option>
            //             <Option value={'3'}>{this.getText(18982)}</Option>
            //             <Option value={'5'}>{this.getText(19359)}</Option>
            //             <Option value={'7'}>{this.getText(19361)}</Option>
            //             <Option value={'4'}>{this.getText(19105)}</Option>
            //         </Select>)
            //     }

            //     let selectorName = field === 'CustomerTitleID' ? TableIDs.genericSelectorCustomerTitles : TableIDs.genericSelectorPriceList
            //     let list = Jax.get(this.props.data, selectorName + ".dataset", []).map((obj, index) => {
            //         return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            //     });

            //     return (
            //         <Select
            //             //disabled={!(this.props.data[this.id].editing === record.key)}
            //             onBlur={onBlur}
            //             disabled={readOnly}
            //             value={this.props.data[this.id].editedRecord[field]}
            //             defaultValue=""
            //             style={iStyle}
            //             onChange={(e) => {
            //                 this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
            //             }}
            //         >
            //             <Option key={"0"} value={""} >-</Option>
            //             {list}
            //         </Select>
            //     )


            case '9':   // image
                // if (readOnly) {
                //     return (<img alt="example" style={{ width: 50 }} src={this.props.data[this.id].editedRecord[field]} />)
                // } else {
                return (<PictureView
                    onBlur={onBlur}
                    user={this.props.user} data={this.props.data} dataActions={this.props.dataActions} ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                    url={this.props.data[this.id].editedRecord[field]}
                    onChange={(url) => this.props.dataActions.genericDataSetEdit(this.id, { [field]: url })}
                    onRemove={() => this.props.dataActions.genericDataSetEdit(this.id, { [field]: '' })}
                    onUploadedSuccess={(response) => { }}
                    onUploadedFailed={(errorMessage, errorCode) => { }}
                    imageType={'C'}
                    readOnly={readOnly}
                    aspect={1}
                />);
            // }

            case '10':
                return (<Input.Password
                    onBlur={onBlur}
                    disabled={readOnly}
                    value={this.props.data[this.id].editedRecord[field]}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: value.target.value });
                    }} />)

            case '11':
                return (<Select
                    onBlur={onBlur}
                    disabled={readOnly}
                    value={this.props.data[this.id].editedRecord[field]}
                    defaultValue=""
                    style={iStyle}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
                    }} >
                    {/* <Option key={"0"} value={""} >-</Option> */}
                    <Option value={'1'}>{this.getText(16788)}</Option>
                    <Option value={'2'}>{this.getText(16790)}</Option>
                    <Option value={'3'}>{this.getText(16789)}</Option>
                    <Option value={'4'}>{this.getText(16791)}</Option>
                    <Option value={'5'}>{this.getText(18136)}</Option>
                </Select>)

            case '12':
                return (<GenericSelector
                    {...this.props}
                    id={TableIDs.genericSelectorEmployees}
                    api={"get_employee_list"}
                    onBlur={onBlur}
                    disabled={readOnly}
                    value={this.props.data[this.id].editedRecord[field]}
                    style={iStyle}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
                    }}
                    addFirstRow={{ code: "0", name: "-" }}
                />)

            case '13':
                return (<SignatureInput
                    {...this.props}
                    key={field}
                    idKey={field}
                    value={this.props.data[this.id].editedRecord[field]}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: e });
                    }}
                    disabled={readOnly}
                />)


            default:
                return (<div>undefined controller</div>);
        }
    }

    createFormItemList = () => {
        return this.dataSource.map(item => {
            let isActive = item.CustomerFieldIsActive;
            let isSearch = item.CustomerFieldIsSearchable;
            let isMandatory = item.CustomerFieldIsMandatory;
            let node = this.getNodeByControlerType(item);
            /*
            CustomerFieldName:
            CustomerFieldDescription:
            CustomerFieldIsActive: 
            CustomerFieldIsMandatory:
            CustomerFieldIsSearchable:
            CustomerFieldContainer:
            CustomerFieldContainerOrder:
            CustomerFieldControlType:
            CustomerFieldQuery: 
            CustomerFieldMaxLength:
            */
            return {
                key: item.CustomerFieldId,
                name: item.CustomerFieldName,
                label: item.CustomerFieldDescription,
                //help: "קוד פריט של הקמת לקוח חדש במועדון",
                isMandatory: isMandatory,
                type: item.CustomerFieldControlType,
                container: item.CustomerFieldContainer,
                containerOrder: item.CustomerFieldContainerOrder,
                toggle: isActive,
                node: node, // control type
            }
        }
        )
    };

    postFetchCreditCardInformation(actionName, store, data) {
        let json = [];
        if (data.length > 0) {
            let arr = data.split("\r");
            for (let i = 0, j = arr.length; i < j; i++) {
                let record = arr[i].split("\f");
                json.push({
                    id: parseInt(record[0], 10),
                    number: record[1],
                    expirationDate: record[2],
                    cvv: record[3],
                    isActive: record[4] === "1",
                    remarks: record[5]
                });
            }
        }
        return Jax.set(store, "customerCreditCards", json);
    }

    fetchCreditCardInformation() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    script: "list_customer_credit_cards",
                    params: { customerId: Jax.get(this.props.data, "customer.editedRecord.CustomerID", -1) }
                },
                "customerCreditCards",
                { loading: "", failed: this.getText(10739), success: "" },
                false,
                null,
                this.postFetchCreditCardInformation
            ]
        });
    }

    render() {
        const { loading } = this.state;
        let request = { _id: 0, };
        this.dataSource = this.generateFieldsFormat();
        const formFields: Array<Field> = this.createFormItemList();


        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(10740)}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                bodyStyle={{ background: "#f7f7f7" }}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>


                <Form id="create_edit_customer_form" className="data-entry-form" layout='vertical' /*layout='horizontal' layout='inline'*/
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}>

                    {
                        [1, 2, 3, 4, 5, 6].map((n) => {
                            let title = formFields.find(val => {
                                return (val.container === n.toString()
                                    && val.type === '0')
                            });
                            // TODO: check bug where cards doesnt have unique keys

                            let fieldArr = formFields.filter((val, index) => (val.container === n.toString() && val.type !== '0' && val.toggle !== '0'));
                            return (fieldArr.length > 0)
                                ? (
                                    <Card
                                        style={{ breakInside: 'avoid-column' }}
                                        headStyle={{ fontSize: 18, backgroundColor: '#dce0e8' }}
                                        title={(title) ? <div>{title.label}</div> : <div>{this.getText(10741)}</div>}
                                        hoverable={true}
                                    >
                                        {formFields
                                            .filter((val, index) => (val.container === n.toString() && val.type !== '0'))
                                            .map((value) => this.createFormItem(value))}
                                    </Card>
                                )
                                : (<div />)
                        }
                        )
                    }
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_edit_customer_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>{this.getText(10742)}</Button>
                </div>
                {/*
                <Drawer title={"הוסף כרטיס אשראי"} width={720} placement={"right"} onClose={() => { this.setState(Jax.set(this.state, "isCreditCardDrawerVisible", false)) }} maskClosable={false} visible={Jax.get(this.state, "isCreditCardDrawerVisible", false)} style={{ overflow: "auto", height: "calc(100% - 55px)", paddingBottom: 53, direction: "rtl" }}>
                    <FormItem label={"מספר כרטיס אשראי"}>
                        <Input value={Jax.get(this.props.data, "tokenizer.editedRecord.creditCard", "")}
                            onChange={(e) => {
                                this.props.ActionQueue.addToQueue({
                                    action: this.props.dataActions.genericDataSetEdit,
                                    args: ["tokenizer", { creditCard: e.target.value }]
                                });
                            }}
                        />
                    </FormItem>

                    <FormItem label={"תוקף"}>
                        <Input value={Jax.get(this.props.data, "tokenizer.editedRecord.expiry", "")}
                            onChange={(e) => {
                                this.props.ActionQueue.addToQueue({
                                    action: this.props.dataActions.genericDataSetEdit,
                                    args: ["tokenizer", { expiry: e.target.value }]
                                });
                            }}
                        />
                    </FormItem>

                    <FormItem label={"שלוש ספרות בגב הכרטיס (CVV)"}>
                        <Input value={Jax.get(this.props.data, "tokenizer.editedRecord.cvv", "")}
                            onChange={(e) => {
                                this.props.ActionQueue.addToQueue({
                                    action: this.props.dataActions.genericDataSetEdit,
                                    args: ["tokenizer", { cvv: e.target.value }]
                                });
                            }}
                        />
                    </FormItem>

                    <FormItem label={"מספר זהות של בעל הכרטיס"}>
                        <Input value={Jax.get(this.props.data, "tokenizer.editedRecord.nationalId", "")}
                            onChange={(e) => {
                                this.props.ActionQueue.addToQueue({
                                    action: this.props.dataActions.genericDataSetEdit,
                                    args: ["tokenizer", { nationalId: e.target.value }]
                                });
                            }}
                        />
                    </FormItem>

                    <FormItem label={"הערות"}>
                        <Input value={Jax.get(this.props.data, "tokenizer.editedRecord.remarks", "")}
                            onChange={(e) => {
                                this.props.ActionQueue.addToQueue({
                                    action: this.props.dataActions.genericDataSetEdit,
                                    args: ["tokenizer", { remarks: e.target.value }]
                                });
                            }}
                        />
                    </FormItem>

                    <Button type={"primary"} onClick={() => {
                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.networkPostJsonAsData,
                            args: [this.props.user.companyCode, this.props.user.token, {
                                script: "cardcom_tokenize",
                                params: {
                                    posId: 999,
                                    source: 1,
                                    customerId: parseInt(Jax.get(this.props.data, "customer.editedRecord.CustomerID", -1), 10)
                                }
                            }, "tokenizer.editedRecord", "", {
                                loading: "יוצר טוקן אשראי...",
                                failed: "יצירת טוקן האשראי נכשלה",
                                success: "טוקן אשראי נוצר בהצלחה"
                            }, {
                                action: this.props.dataActions.networkFetch,
                                args: [this.props.user.companyCode, this.props.user.token, { script: "list_customer_credit_cards", params: { customerId: Jax.get(this.props.data, "customer.editedRecord.CustomerID", -1) } }, "customerCreditCards", { loading: "", failed: "חלה שגיאה בטעינת נתוני כרטיסי אשראי", success: "" }, false, null, this.postFetchCreditCardInformation]
                            }, null]
                        });
                    }}>צור טוקן</Button>
                </Drawer>
                */}

            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        let _id = this.props.ui.data;
        let prev_id = prevProps.ui.data;
        if ((typeof _id !== 'object' && _id !== prev_id) || (prevProps.toggle === false && this.props.toggle === true)) {
            if (_id > 0) {
                this.props.dataActions.genericDataGetEditWithParams(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id },
                    this.api.get
                );

                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericDataGetWithParams,
                    args: [
                        this.id_tags,
                        this.props.user.companyCode,
                        this.props.user.token,
                        { _id },
                        this.api.get_tags
                    ]
                });

            } else {

                let obj = {}
                let fieldsData = this.generateFieldsFormat();
                fieldsData.map((x) => {
                    if (x.CustomerFieldName !== "") obj = { ...obj, [x.CustomerFieldName]: x.CustomerFieldRoleListDefaultValue }
                    if (x.CustomerFieldControlType === "8") {
                        let customerTags = x.CustomerFieldRoleListDefaultValue.split(",");
                        // this.props.dataActions.setData("tagsEditCust", customerTags)
                        this.props.dataActions.setJson(this.id_tags, { data: customerTags, headers: ["CustTagTagId"] })
                    }
                    if (x.CustomerFieldControlType === "5" && x.CustomerFieldRoleListDefaultValue === 'NOW') {
                        obj = { ...obj, [x.CustomerFieldName]: moment().format("YYYY-MM-DD") }
                    }
                })

                this.props.dataActions.genericDataSetEdit(this.id, obj);
            }
        }

    }

    init = () => {
        this.props.dataActions.genericDataSetEdit(this.id, {
            CustomerAccountingNumber: ""
            , CustomerBirthDate: null //"2018-01-02"
            , CustomerClubValidDate: null //"2018-05-15"
            , CustomerContactPersonAddress: ""
            , CustomerContactPersonEmail: ""
            , CustomerContactPersonFax: ""
            , CustomerContactPersonMobile: ""
            , CustomerContactPersonName: ""
            , CustomerContactPersonPhone: ""
            , CustomerContactPersonZipCode: ""
            , CustomerDeletingUser: "0"
            , CustomerDeletionDate: ""
            , CustomerFirstClubJoin: null //"2018-05-15"
            , CustomerFirstName: ""
            , CustomerFreeDate1: null //"2018-05-15"
            , CustomerFreeDate10: null //"2018-05-15"
            , CustomerFreeDate2: null //"2018-05-15"
            , CustomerFreeDate3: null //"2018-05-15"
            , CustomerFreeDate4: null //"2018-05-15"
            , CustomerFreeDate5: null //"2018-05-15"
            , CustomerFreeDate6: null //"2018-05-15"
            , CustomerFreeDate7: null //"2018-05-15"
            , CustomerFreeDate8: null //"2018-05-15"
            , CustomerFreeDate9: null //"2018-05-15"
            , CustomerFreeNum1: ""
            , CustomerFreeNum10: ""
            , CustomerFreeNum2: ""
            , CustomerFreeNum3: ""
            , CustomerFreeNum4: ""
            , CustomerFreeNum5: ""
            , CustomerFreeNum6: ""
            , CustomerFreeNum7: ""
            , CustomerFreeNum8: ""
            , CustomerFreeNum9: ""
            , CustomerFreeSum1: ""
            , CustomerFreeSum10: ""
            , CustomerFreeSum2: ""
            , CustomerFreeSum3: ""
            , CustomerFreeSum4: ""
            , CustomerFreeSum5: ""
            , CustomerFreeSum6: ""
            , CustomerFreeSum7: ""
            , CustomerFreeSum8: ""
            , CustomerFreeSum9: ""
            , CustomerFreeText1: ""
            , CustomerFreeText10: ""
            , CustomerFreeText2: ""
            , CustomerFreeText3: ""
            , CustomerFreeText4: ""
            , CustomerFreeText5: ""
            , CustomerFreeText6: ""
            , CustomerFreeText7: ""
            , CustomerFreeText8: ""
            , CustomerFreeText9: ""
            , CustomerFreeYesNo1: ""
            , CustomerFreeYesNo10: ""
            , CustomerFreeYesNo2: ""
            , CustomerFreeYesNo3: ""
            , CustomerFreeYesNo4: ""
            , CustomerFreeYesNo5: ""
            , CustomerFreeYesNo6: ""
            , CustomerFreeYesNo7: ""
            , CustomerFreeYesNo8: ""
            , CustomerFreeYesNo9: ""
            , CustomerGender: ""
            , CustomerGroupID: ""
            , CustomerID: "0"
            , CustomerIdentificationNumber: ""
            , CustomerIsActive: "1"
            , CustomerLastClubJoin: null //"2018-05-15"
            , CustomerLastName: ""
            , CustomerMagneticCard: ""
            , CustomerPassword: ""
            , CustomerPicUrl: ""
            , CustomerRemarks: ""
            , CustomerTaxIdentificationNumber: ""
            , CustomerTitleID: ""
            , CustomerUpdateDate: null //"2018-07-22 17:40:53"
            , key: "0"
        });
    }



}

export default ModalCreateEditCustomer;


/*

                    <Collapse style={{ marginBottom: 16 }} defaultActiveKey={['1']}
                        onChange={this.collapeCallback} activeKey={this.state.collapeKey}>

                    </Collapse>

                                    <Panel
                                        header={(title) ? <div>{title.label}</div> : <div>'אחר'</div>}
                                        key={n} forceRender={true}
                                        style={{ margin: '4px', breakInside: 'avoid-column' }}
                                    >
*/