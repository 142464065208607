
import React from "react";
import {
    Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm,
    Tooltip, Modal, Table, Select, Row, Col, Checkbox, message
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import Jax from "../Jax/jax.es6.module";

import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import moment from "moment";
import MatrixGrid from "./MatrixGrid";
// import MatrixBarcodeTable from "./MatrixBarcodeTable";
import MultiBarcodeTable from "./MultiBarcodeTable";
import LangContext from "../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//type State = {}
class ModalMatrixReverse extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.matrixGridMode = true;
        this.state.id = null;
        this.state._PriceList = undefined;

        this.id = 'MODAL_MATRIX_REVERSE';
        this.id_meta = 'META_MATRIX';
        this.id_edit = 'MATRIX_GRID_EDIT';

        this.id_item = 'ITEM_CREATE_EDIT';
        this.id_priceList = 'ITEM_PRICE_LIST_CREATE_EDIT';
        this.id_suppliers = 'ITEM_SUPPLIERS_CREATE_EDIT';
        this.id_tags = "ITEM_TAGS_CREATE_EDIT";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_item] === undefined) { props.data[this.id_item] = { ...props.data.genericData }; }
        if (props.data[this.id_meta] === undefined) { props.data[this.id_meta] = { ...props.data.genericData }; }
        if (props.data[this.id_edit] === undefined) { props.data[this.id_edit] = { ...props.data.genericData }; }

        if (props.data[TableIDs.genericSelectorPrices] === undefined) {
            props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_father_item",
            //create_edit: "create_edit_item",
            //create: "create_item",
            edit: "edit_item",
            //get_priceList: "get_prices_by_item",
            //get_suppliers: "get_suppliers_by_item",
            //get_tags: "get_tags_by_item_with_headers",
            selector_price_list: "get_prices",
            create: "add_to_matrix_reverse_grid",
            get_meta: "get_matrix_meta",
            overrideUPC: "set_UPC_matrix_reverse",
            get_size_bar: "get_size_bar",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'add',
            buttonFunction: () => {
                const { editing } = this.props.data.MATRIX_BARCODE;
               
                if (!editing || editing === -1) {
                    let rulerArr = Jax.get(this.props.data, TableIDs.genericSelectorSizes + ".dataset", [])
                    let sizeId = (rulerArr.length > 0) ? (rulerArr[0])['code'] : 0;

                    let record = {
                        key: 0,
                        mCode: '',
                        mBarcode: this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode'],
                        mBarCode2: "",
                        mDescription: this.props.data['ITEM_CREATE_EDIT'].editedRecord['mShortName'],

                        mRuler1ID: this.props.data[this.id/*'ITEM_CREATE_EDIT'*/].editedRecord['mRuler1ID'],
                        mRuler2ID: 0,
                        mSize1ID: sizeId,
                        //record['mSize2ID'] = 
                        mColorID: null,
                    };

                    this.props.dataActions.genericDataStartInlineCreate("MATRIX_BARCODE");
                    this.props.uiActions.showModal("MATRIX_BARCODEEditor"/*,{...this.props.ui.data}*/);
                    setTimeout(() => {
                        this.props.dataActions.genericDataSetEdit("MATRIX_BARCODE", record);
                    }, 100);
                    // this.props.dataActions.genericDataStartInlineCreate(this.id_edit); //, { mIsDefault: "0" });
                    // this.props.dataActions.dataTableSetRow("MATRIX_GRID_EDIT", 0, record, true);
                    // this.addGridData()
                }
            }
        }];

        this.genericActionButtons = []
        this.dataSource = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']';
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID", this.props.user.companyCode,
            this.props.user.token, { _id: this.state.id }, "get_matrix_grid");
        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID_EDIT", this.props.user.companyCode,
            this.props.user.token, { _id: -1 }, "get_matrix_grid");
        this.props.dataActions.genericDataGetRequested('MATRIX_BARCODE', this.props.user.companyCode,
            this.props.user.token, 'get_barcode_table');
    }

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector_price_list);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSizes, this.props.user.companyCode, this.props.user.token, this.api.get_size_bar, { _id: this.props.mParentItemCode });

        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID_EDIT", this.props.user.companyCode,
            this.props.user.token, { _id: -1 }, "get_matrix_grid");
        /*
        this.props.dataActions.genericDataGetEditWithParams(
            this.id_meta,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: this.props.ui.data, mItemCode: this.props.ui.data },
            this.api.get_meta
        );
        */
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalMatrixReverse);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        if(this.props.onClose) this.props.onClose()
       else this.props.uiActions.hideModal(TableIDs.modalMatrixReverse);
    }
    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        if (this.isNameValid() === 'success') {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            });
            //this.props.ActionQueue.addToQueue({
            //    action: this.props.dataActions.genericDataGetRequested,
            //    args: ["SUPPLIERS_MAIN", this.props.user.companyCode, this.props.user.token, "get_suppliers_table_list"]
            ///});
            this.props.uiActions.hideModal(TableIDs.modalMatrixReverse);
        }
        else {
            message.warning(this.getText(12765));
        }
    }

    isNameValid = () => {
        const name = this.props.data[this.id].editedRecord['mName'];
        return (name === '' || name === null) ? 'error' : 'success';
    }

    render() {
        // const { loading } = this.state;
        //let request = { _id: 0, };
        // NOT USED
        // const genericActionButtons = [].concat(this.genericActionButtons).concat(
        //     [(<Tooltip title={(this.state.matrixGridMode == true) ? "מצב תצוגה מטריצה" : "מצב תצוגה ברקוד"}>
        //         <Button key="toggle_grid" className={"btn"} // disabled={(this.props.data[this.id_directdebit].editing > -1)} 
        //             style={{ marginTop: "5px", marginLeft: "5px" }}
        //             //onClick={this.getFile}
        //             onClick={() => this.setState({ matrixGridMode: !this.state.matrixGridMode })}
        //         >
        //             {(this.state.matrixGridMode == true)
        //                 ? <Icon style={{ fontSize: 16 }} type="table" theme="outlined" />
        //                 : <Icon style={{ fontSize: 16 }} type="barcode" theme="outlined" />
        //             }
        //         </Button>
        //     </Tooltip>),]
        // );

        let fluxProps = { ui: this.props.ui, uiActions: this.props.uiActions, data: this.props.data, dataActions: this.props.dataActions, user: this.props.user }

        let list = Jax.get(this.props.data, TableIDs.genericSelectorPrices + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(12760)}
                    actionButtons={this.actionButtons}
                    //genericActionButtons={genericActionButtons}
                    genericActionButtons={[]}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                data={this.props.data} dataAction={this.props.dataAction}>

                <div id={'headers'} style={{ padding: 8 }}>
                    <span>{this.getText(12761)}
                        <Input
                            disabled={true}
                            style={{ width: 150, marginLeft: 8, marginRight: 8 }}
                            value={this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode']}
                        />
                    </span>
                    <span>{this.getText(12762)}
                        <Input
                            disabled={true}
                            style={{ width: 200, marginLeft: 8, marginRight: 8 }}
                            value={this.props.data['ITEM_CREATE_EDIT'].editedRecord['mShortName']}
                        />
                    </span>
                    {/* <span style={(this.state.matrixGridMode)
                        ? { display: 'none' } : {}} >מחירון
                        <Select key={"PriceListList"} style={{ width: 250, marginLeft: 8, marginRight: 8 }}
                            placeholder='בחר מחירון'
                            showsearch
                            filterOption={true}
                            optionFilterProp="valuefilter"
                            onChange={(e) => {
                                this.props.dataActions.genericDataSetEdit(this.id_meta, { ['mDefaultPriceListId']: e });
                            }}
                            value={this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']}
                            notFoundContent={"לא נמצאו מחירונים"}
                        >
                            {list}
                        </Select>
                    </span>
                    */}
                </div>
                {
                    //
                    //                <div style={{ padding: 8, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
                    //                    {(/*this.state.matrixGridMode == true*/false) ? "תצוגת מטריצה" : "תצוגת פריט ברקוד"}
                    //                </div>
                }

                <div key="grid_container" style={{ padding: 8 }} >
                    {/* {(this.state.matrixGridMode)
                        ? <MatrixGrid {...fluxProps} itemCode={this.props.mParentItemCode} />
                        : <MatrixBarcodeTable {...fluxProps}
                            itemCode={this.props.mParentItemCode}
                            priceListId={this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']}
                            isReverse={true} />
                    } */}
                    <MultiBarcodeTable {...fluxProps}
                        itemCode={this.props.mParentItemCode}
                        priceListId={
                            this.props.data[this.id_meta] ?
                                this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']
                                : 0
                        }
                        isReverse={true} />
                </div>
                <div className="action_footer" style={(/*this.state.matrixGridMode*/false) ? {} : { display: 'none' }}>
                    <Button
                        onClick={this.handleCancel}
                        type="primary" //htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                    >{this.getText(12763)}</Button>
                    <Button
                        onClick={this.addGridData}
                        type="primary" //htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                        //className={"form-button-save"} 
                        icon={"check"}>{this.getText(12764)}</Button>
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        //this.props.mParentItemCode
        if (/*this.props.ui.data !== prevProps.ui.data*/!this.props.onClose &&
            (this.props.mParentItemCode !== prevProps.mParentItemCode
            || (prevProps.toggle === false && this.props.toggle === true))) {
            this.setState({ id: this.props.mParentItemCode/*ui.data*/, matrixGridMode: true }, () => {
                this.props.dataActions.genericDataGetEditWithParams(
                    this.id_meta,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    this.api.get_meta
                );

                this.props.dataActions.genericDataGetEditWithParams(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    this.api.get
                );

                this.props.dataActions.genericDataGetEditWithParams(
                    'ITEM_CREATE_EDIT',
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    "get_father_item"
                );

            })
        }
    }

    addGridData = () => {
        // let request = {
        //     page: pagination.current,
        //     recordsPerPage: pagination.pageSize,
        //     sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        //     sortColumn: col,
        //     _page_num: pagination.current,
        //     _rows_num: pagination.pageSize,
        //     _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        //     _sort_by: 'col_' + col,
        //   };
        let request = {
            page: 1,
            recordsPerPage: 8,
            sortType: 1,
            sortColumn: 1,
            _page_num: 1,
            _rows_num: 8,
            _isAsc: 1,
            _sort_by: 'col_1',

            mItemCode: this.props.mParentItemCode
        };

        this.props.dataActions.genericDataJustSaveEditMulti(
            this.id_edit,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.create,
            [
                { name: this.id_edit, sendDataTable: true },
                { name: this.id_meta, sendDataTable: false },
                //{ name: this.id_priceList, sendDataTable: true },
                //{ name: this.id_tags, sendDataTable: true }
            ],
            // refresh parent dataset (like when closing a modal)
            {
                // action: this.props.dataActions.genericDataGetEditWithParams,
                // args: ["MATRIX_GRID", this.props.user.companyCode,
                //     this.props.user.token, { _id: this.state.id }, "get_matrix_grid"]
                action: this.props.dataActions.genericDataSetFilter,
                args: ["MATRIX_BARCODE", this.props.user.companyCode, this.props.user.token, request, 'get_barcode_table']
            }
        )

        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID_EDIT", this.props.user.companyCode,
            this.props.user.token, { _id: -1 }, "get_matrix_grid");
    }
}

export default ModalMatrixReverse;

