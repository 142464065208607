/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import SearchBox from "./SearchBox";
import TableIDs from '../../data/TableIDs.js';
import { Button, Input, Icon, Modal, Tooltip, Row, Col, Spin, message, InputNumber } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable.js";
import moment from "moment";

class FoodOrderBenefits extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            couponInput: '',
            modalCouponInput: false,
            checkCouponLoading: false,
            errorCoupon: null,
            modalAddToCartData: null,
            modalAddToCartCouponData: null,
        }

        this.mobile = window.innerWidth <= 600 ? true : false;

        this.id = "FORMAT_SITE_BENEFITS";
        this.fields_id = 'FOOD_FORMAT_ORDER_FIELDS';

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [], distributorList: [] };
        if (props.data[this.fields_id] === undefined) props.data[this.fields_id] = { orderFields: {} };

        this.api = {
            check: 'get_coupon_to_site',
            check_mp: 'multi_pass_get_budget',

            get_items: "get_items_to_site",

        }

        this.sendAPI = this.props.sendAPI;
        this.getText = this.props.getText;
        this.setJson = this.props.setJson;

    }

    searchInXML = (xml, search) => {
        let res = xml ? xml.split('<' + search + '>').map(x => x ? x.split('</' + search + '>')[0] : '') : [];
        return res.slice(1);
    }

    checkCoupon = () => {
        console.log('couponsList', this.props.couponsList)
        if (this.props.couponsList && this.props.couponsList.indexOf(this.state.couponInput) > -1) {
            message.error(this.getText(15696))
        } else {
            this.setState({ checkCouponLoading: true }, () => {

                let distributorList = this.props.data[this.id].distributorList;
                let issetMultipass = distributorList.indexOf('2') > -1;
                let notMultipassList = distributorList.filter(f => f !== '2');
                let nextCheck = issetMultipass ? this.multipassCheck : this.couponNotFound

                if (notMultipassList.length) {
                    this.notMultipassCheck(notMultipassList, nextCheck)
                } else {
                    nextCheck();
                }
            })
        }
    }

    notMultipassCheck = (notMultipassList, nextCheck) => {
        let dataSend = 'mBranchId\fmDistributorId\fmCouponCode\fmCustId\fmInCart';
        const { cID, dBranch } = this.props.data[this.fields_id].orderFields;
        let mInCart = this.props.inCart ? '1' : '0';
        notMultipassList.forEach(x => { dataSend += '\r' + dBranch + '\f' + x + '\f' + this.state.couponInput + '\f' + cID + '\f' + mInCart });
        this.sendAPI(this.api.check, dataSend, (ob) => {
            let resAll = ob.data ? ob.data.split('\r').map(x => {
                let res = x.split('\f');
                let nRequest = { mDistributorId: res[20], mCouponCode: this.state.couponInput }
                let nResponse = {
                    nId: res[0],
                    nCompanyId: res[1],
                    nCode: this.state.couponInput,
                    nName: res[2],
                    nDescription: res[3],
                    nValidDateTimeBegin: res[4],
                    nValidDateTimeEnd: res[5],
                    nValidationMethod: res[6],
                    nConfirmationCode: res[7],
                    nType: res[8],
                    nItemCode: res[9],
                    nDealCode: res[10],
                    nPrice: res[11],
                    mHash: res[12],
                    nCustomerName: res[13],
                    nCustomerPhone: res[14],
                    nCustomerNumber: res[15],
                    nCustomerMail: res[16],
                    nIsOverrideDiscount: res[17],
                    _errorCode: res[18],
                    _errorMessage: res[19],
                }

                return { nRequest, nResponse, ...nResponse }
            }) : [];

            let findCoupon = resAll.find(f => f._errorCode != '1')

            if (findCoupon) {
                if (findCoupon._errorCode == '0') this.onCouponOk(findCoupon);
                else this.couponNotFound(findCoupon._errorMessage);
            } else {
                nextCheck();
            }
        }, this.props.errorCallback)
    }

    multipassCheck = () => {
        let dataSend = { CardCode: this.state.couponInput }
        this.sendAPI(this.api.check_mp, "data\r" + JSON.stringify(dataSend), (ob) => {
            let ResultId = this.searchInXML(ob.data, 'ResultId')[0];
            if (ResultId === '0') {
                let BenefitInfo = this.searchInXML(ob.data, 'BenefitInfo')
                let itemList = [];
                BenefitInfo.forEach(x => {
                    let CouponCodes = this.searchInXML(x, 'CouponCodes')[0];
                    let list = this.searchInXML(CouponCodes, 'string');
                    itemList = [...itemList, ...list];
                });
                console.log('itemList', itemList)
                this.onCouponOk({
                    nCode: this.state.couponInput,
                    nName: this.searchInXML(ob.data, 'ClientName'),
                    nItemCode: itemList.join(','),
                    nIsMultipass: true,
                });
            } else {
                this.couponNotFound(this.searchInXML(ob.data, 'ErrorMessage')[0])
            }
        })
    }

    onCouponOk = (res) => {
        this.setState({
            modalCouponInput: false,
            couponInput: '',
            checkCouponLoading: false,
            errorCoupon: null,
        }, () => {
            if (this.props.onCouponOk) {
                this.props.onCouponOk(res);
            } else {
                const { orderFields } = this.props.data[this.fields_id];
                let _priceList = orderFields.dPriceList ? orderFields.dPriceList : "";
                let dataSend = "_tag\f_words\f_priceList\f_couponItem\f_mivzaim\r\f\f" + _priceList + "\f" + res.nItemCode + "\f";
                this.props.sendAPI(this.api.get_items, dataSend, (ob) => {
                    let x = ob.data.split("\r");
                    if (x.length > 1) {
                        const { dBranch } = this.props.data[this.fields_id].orderFields;

                        let headers = x[0].split("\f");
                        let data = x.slice(1);
                        data = data.map((a) => {
                            let b = a.split("\f");
                            let obj = {};
                            headers.map((c, i) => { obj = { ...obj, [c]: b[i] } });
                            return obj;
                        }).filter(f => !dBranch || !f.iExcBranches || !f.iExcBranches.split(',').find(fi => fi == dBranch));



                        if (data.length === 1) {
                            this.props.addToCart({ ...data[0], couponData: res });
                        } else {
                            this.setState({
                                modalAddToCartData: data,
                                modalAddToCartCouponData: res,
                            });
                        }

                    } else {
                        message.error(this.getText(15694))
                    }

                }, this.errorCallback);
            }
        })
    }

    couponNotFound = (message) => {
        this.setState({ checkCouponLoading: false, errorCoupon: message ? message : this.getText(15691) });
    }

    qtyInput = (indexItem) => {
        let sData = [...this.state.modalAddToCartData];
        let x = sData[indexItem];
        let stepQty = parseFloat(x.stepQty) ? parseFloat(x.stepQty) : 1;
        let minQty = parseFloat(x.minQty) ? parseFloat(x.minQty) : 1;
        let maxQty = parseFloat(x.maxQty) ? parseFloat(x.maxQty) : 999999;
        let iQty = parseFloat(x.iQty) ? parseFloat(x.iQty) : minQty;

        let precision = stepQty.toString().split('.')[1] ? stepQty.toString().split('.')[1].length : 0;

        return (<div onClick={(e) => { e.stopPropagation() }} >
            <InputNumber
                step={stepQty}
                min={minQty}
                max={maxQty}
                precision={precision}
                value={iQty}
                onChange={(e) => {
                    sData[indexItem] = { ...sData[indexItem], iQty: parseFloat(e).toFixed(precision) }
                    this.setState({ modalAddToCartData: sData });
                }} />
        </div>)
    }

    render() {
        if ((this.props.isShop || !this.props.inCart) && this.props.data[this.id].distributorList.length) {

            let btnStyle = {
                color: this.props.fontColor,
                backgroundColor: this.props.backgroundColor,
                display: this.props.data[this.id].data.length ? 'inline-block' : 'none',
                cursor: 'pointer',
                borderRadius: 5,
                border: '1px solid ' + this.props.fontColor,
                fontSize: 15,
                padding: 5,
            }

            let btnModalStyle = {
                margin: 10,
                width: 140,
                height: 50,
                background: this.props.backgroundColor,
            }


            let modalTitle = (text) => {
                return (<div className={"title_block"} style={{
                    color: this.props.fontColor,
                    backgroundColor: this.props.backgroundColor,
                }} >{this.getText(text)}</div>)
            }

            let btnClassName = "";
            let btnText = this.getText(15688)

            if (this.props.typeStyle == '2') {
                btnClassName = "menu_button font_style_2";
                btnStyle = {
                    ...btnStyle,
                    border: 0,
                    width: '90%',
                    height: 65,
                    fontSize: 27,
                    textAlign: 'center',
                }
            } else if (this.props.typeStyle == '4' || this.props.typeStyle == '6') {
                btnStyle = {
                    ...btnStyle,
                    borderRadius: 0,
                    width: '99%',
                    // height: 65,
                    fontSize: 18,
                    fontWeight: "bold",
                    textAlign: 'center',
                    backgroundColor: this.props.typeStyle == '6' ? "#ddc268" : "#BE1E2D",
                    color: this.props.typeStyle == '6' ? "black" : "white",
                }

                btnText = (<div style={{ border: "1px solid white", padding: 20 }}>{btnText}</div>)
            }



            return (<div style={this.props.style} className={this.props.className}>
                {this.props.typeStyle == '4' ? <hr style={{ borderTop: "1px solid #cccccc", marginTop: 2, marginBottom: 4 }} /> : ""}
                <div className={btnClassName} style={btnStyle} onClick={() => { this.setState({ modalCouponInput: true }) }}>{btnText}</div>
                <Modal
                    title={modalTitle(this.props.couponCustList?.length ? 19893 : 15690)}
                    visible={this.state.modalCouponInput}
                    onCancel={() => {
                        this.setState({
                            couponInput: '',
                            modalCouponInput: false,
                            checkCouponLoading: false,
                            errorCoupon: null,
                        })
                    }}
                    destroyOnClose={true}
                    width={this.props.couponCustList?.length ? 800 : 300}
                    footer={[
                        <Button style={btnModalStyle} onClick={this.checkCoupon}>
                            <span className={"text_item"} style={{ color: this.props.fontColor }}>{this.getText(15689)}</span>
                        </Button>,
                        <Button style={btnModalStyle} onClick={() => {
                            this.setState({
                                couponInput: '',
                                modalCouponInput: false,
                                checkCouponLoading: false,
                                errorCoupon: null,
                            })
                        }}>
                            <span className={"text_item"} style={{ color: this.props.fontColor }}>{this.getText(13101)}</span>
                        </Button>
                    ]}>
                    {this.props.couponCustList?.length ?
                        [<ResponsiveTable
                            tableOnly
                            dataSource={this.props.couponCustList}
                            columns={[
                                { title: this.getText(19894), key: "nCode", dataIndex: "nCode", width: "20%" },
                                { title: this.getText(19895), key: "nDescription", dataIndex: "nDescription", width: "40%", render: (t, r) => [r.nName, <br />, t] },
                                { title: this.getText(19896), key: "nValidDateTimeEnd", dataIndex: "nValidDateTimeEnd", width: "20%", render: t => <div dir="ltr">{moment(t).format("DD/MM/YYYY")}</div> },
                                { width: "20%", render: (t, r) => (<Button onClick={() => { this.setState({ couponInput: r.nCode }) }}>{this.getText(19897)}</Button>) }
                            ]}
                        />,
                        <div style={{ display: "inline-block", marginLeft: 10 }} className={"text_item"}>{this.getText(15690)}</div>]
                        : ""}
                    <Input
                        className={"text_item"}
                        style={{ height: 40, width: 250 }}
                        value={this.state.couponInput}
                        onChange={(e) => { this.setState({ couponInput: e.target.value }) }} />
                    {this.state.checkCouponLoading ? (<Spin />) : ""}
                    {this.state.errorCoupon}
                </Modal>
                <Modal
                    title={modalTitle(15695)}
                    visible={this.state.modalAddToCartData !== null}
                    onCancel={() => {
                        this.setState({
                            modalAddToCartData: null,
                            modalAddToCartCouponData: null,
                        })
                    }}
                    destroyOnClose={true}
                    width={'95%'}
                    footer={false}>
                    {this.state.modalAddToCartData ?
                        this.state.modalAddToCartData.map((x, i) => {
                            return this.props.getStyleCard(
                                2.5,
                                x.iPictureFile,
                                x.iCode,
                                x.iName,
                                x.iPrice,
                                x.iRemarks,
                                0,
                                () => {
                                    let couponData = this.state.modalAddToCartCouponData;
                                    this.setState({
                                        modalAddToCartData: null,
                                        modalAddToCartCouponData: null,
                                    }, () => {
                                        this.props.addToCart({ ...x, couponData })
                                    })
                                },
                                x.mivzaList,
                                (x.iFoodDishId || !parseFloat(x.stepQty) || x.iIsFather == 1) ? '' : this.qtyInput(i),
                                x.iIsByWeight == '1',
                                null,
                                x.modifier1Text,
                                true
                            )
                        })
                        : []}
                </Modal>
            </div >)
        } else {
            return (<span></span>)
        }
    }


}

export default FoodOrderBenefits;