/* @flow */
/*jshint esversion: 6 */
import { Input, InputNumber, message } from "antd";
import { indexOf } from "lodash";
import React, { Component } from "react";
import "../../App.css";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import PictureView from "../../components/PictureView";


class LabelFormats extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "ADMIN_LABEL_FORMATS";

        this.api = {
            get: "get_pos_admin_label_formats",
            set: "set_pos_admin_label_formats",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10206);
    }


    // renderCol = (text, record, field) => {
    //     const { editing, editedRecord } = this.props.data[this.id];
    //     if (record.key == editing) {
    //         let value = editedRecord[field]
    //         switch (field) {
    //             case '_code': case '_subCode':
    //                 return (<InputNumber value={value} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }} />)
    //             case '_desc':
    //                 return (<Input value={value} onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.value }) }} />)
    //         }
    //     } else {
    //         return text;
    //     }
    // }

    // renderEditCol = (text, record) => {
    //     const { editing, editedRecord } = this.props.data[this.id];
    //     if (record.key == editing) {
    //         return (<TableLineIcons
    //             onCheck={() => {
    //                 let isExists = this.props.data[this.id].data.map((x, index) => {
    //                     let y = x.split("\f");
    //                     return { index, _code: y[1], _subCode: y[2] }
    //                 }).filter(f => f.index !== record.index && f._code == editedRecord._code && f._subCode == editedRecord._subCode)

    //                 if (isExists.length) {
    //                     message.error(this.getText(17248))
    //                 } else {
    //                     this.props.dataActions.genericDataSaveEdit(
    //                         this.id, this.props.user.companyCode, this.props.user.token, this.api.set, this.api.get)
    //                 }
    //             }}
    //             onClose={() => {
    //                 if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
    //                 else this.props.dataActions.genericDataCancelEditing(this.id);
    //             }}
    //         />)
    //     } else {
    //         return (<TableLineIcons
    //             onEdit={() => {
    //                 this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index)
    //             }}
    //         />);
    //     }
    // }

    colDesc = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (record.key === editing) {
            return (<Input value={editedRecord.mDesc} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.id, { mDesc: e.target.value })
            }} />)
        } else {
            return text;
        }
    }

    colImageFile = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (record.key === editing) {
            return (<PictureView
                {...this.props}
                isShared={true}
                uploadScriptName={"upload_shared_image"}
                url={editedRecord.mImageFile}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mImageFile: e }) }}
                onRemove={() => { this.props.dataActions.genericDataSetEdit(this.id, { mImageFile: "" }) }}
                onUploadedSuccess={() => { }} onUploadedFailed={() => { }} />)
        } else if (text) {
            let origin = window.location.origin;
            const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
            let baseUrl = isDev ? "http://147.235.163.248" : origin;
            return (<img src={baseUrl + '/uploads/shared/' + text} height={100} />)
        }
    }

    colEdit = (text, record) => {
        const { editing } = this.props.data[this.id];
        const { companyCode, token } = this.props.user
        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, companyCode, token, this.api.set, this.api.get) }}
                onClose={() => { this.props.dataActions.genericDataCancelEditing(this.id) }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            />)
        }
    }


    render() {
        const gt = this.getText;
        const sorter = true

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1200,
            margin: 'auto'
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                title={gt(10206)}
                id={this.id}
                getApi={this.api.get}
                searchBox
                columns={[
                    { title: gt(20040), key: "mID", dataIndex: "mID", width: "15%", sorter, render: t => t },
                    { title: gt(20041), key: "mDesc", dataIndex: "mDesc", width: "50%", sorter, render: this.colDesc },
                    { title: gt(20042), key: "mImageFile", dataIndex: "mImageFile", width: "20%", sorter, render: this.colImageFile },
                    { title: gt(20043), width: "15%", render: this.colEdit },
                ]}
            />
        </div>)
    }

}

export default LabelFormats;