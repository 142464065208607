/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, InputNumber, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesItems extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            radioSelect: '',
            type: "pdf",
            SalesItems: {
                mRequestedReport: '0',
                mLowerSelectionDate: null,
                mLowerSelectionTime: "00:00:00",
                mUpperSelectionDate: null,
                mUpperSelectionTime: "23:59:59",
                mPosList: '',
                mApplyRangeApply: 0,
                mApplyRangeLower: 0,
                mApplyRangeUpper: 0,
                mEmployeeList: '',
                mGroupList: '',
                mSupplierList: '',
                mTagList: '',
                mMinimalPercentage: 0,
                mStorageList: '',
                mTagRelation: '1',
                mUpperItems: 0,
                mLowerItems: 0,
                mSortPerSum: 0,
                mSortPerSumAsc: '0',
                mOnlyDefault: 0,
                mSplitPerStorage: 0,
                mViewStock: 0,
                _withSendingDocs: '1',
                mBreakType: '0',
                mIsPurchaseReport: 0,
                mCustTagList: "",
                reportName: "reports/salesItems.pdf"
            }
        };

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            if (params.reportName === "reports/salesItems.xls") {
                params = { ...params, reportName: "reports/salesItemsExcel.xls" }
            }
            ['5', '6', '7', '8'].forEach(x => {
                if (params.reportName === "reports/salesItems" + x + ".xls") {
                    params = { ...params, reportName: "reports/salesItems5Excel.xls" }
                }
            })
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.mPosList === '') params = { ...params, mPosList: fullPosList }
        if (params.mStorageList === '') params = { ...params, mStorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10198);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }


        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let type = this.props.ui.favoriteParams.reportName.split('.')[1];
            let radioSelect = '';
            if (parseInt(this.props.ui.favoriteParams.mMinimalPercentage)) radioSelect = '1';
            if (parseInt(this.props.ui.favoriteParams.mUpperItems)) radioSelect = '2';
            if (parseInt(this.props.ui.favoriteParams.mLowerItems)) radioSelect = '3';

            this.setState({ type, radioSelect }, () => {

                this.setReportParameters("SalesItems", this.props.ui.favoriteParams)
            })
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesItems.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesItems.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("SalesItems", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("SalesItems", { [field]: data });
                else
                    this.setReportParameters("SalesItems", { [field]: '' });
            }} />);
    }

    createRadioBox = (val, title, field) => {
        let radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        if (this.state.radioSelect === val) {
            return (<Radio style={radioStyle} value={val}>{title}
                <InputNumber min={1} max={100} style={{ marginRight: 10 }}
                    onChange={(v) => this.setReportParameters("SalesItems", { [field]: v })}
                    value={this.state.SalesItems[field]}
                />
            </Radio>)
        } else {
            return (<Radio style={radioStyle} value={val}>{title}</Radio>)
        }
    }


    render() {
        const { SalesItems, endOpen } = this.state;
        const { mLowerSelectionDate, mLowerSelectionTime,
            mUpperSelectionDate, mUpperSelectionTime } = SalesItems;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { SalesItems } = this.state;
        const isMinPercShownStyle = { display: (['5', '6', '7', '8'].indexOf(SalesItems.mRequestedReport) >= 0) ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listItemTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listItemTagTypes = Jax.get(this.props.data, TableIDs.genericSelectorItemTagTypes + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listItemGroup = Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listSuppliers = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        // //console.log('yuda jasper ', this.state.SalesItems);

        let mobile = window.innerWidth > 600 ? false : true;

        let params = { ...this.state.SalesItems }
        let xlsReportName = params.reportName.split('.')[0] + "Excel.xls";

        ['5', '6', '7', '8'].forEach(x => {
            if (params.reportName === "reports/salesItems" + x + ".pdf") {
                xlsReportName = "reports/salesItems5Excel.xls";
            }
        })

        if (params.mBreakType > 0) params.mRequestedReport += params.mBreakType;

        let radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(12003)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(12004)}>
                            <Select key={"mRequestedReport"} style={{ width: '90%' }}
                                //size={"large"}
                                //className={""}
                                //combobox={null}
                                value={SalesItems.mRequestedReport}
                                onChange={
                                    (value) => {
                                        console.log("request pick: ", value);
                                        this.setReportParameters("SalesItems",
                                            {
                                                mRequestedReport: value,
                                                mMinimalPercentage: 0,
                                                mUpperItems: 0,
                                                mLowerItems: 0,
                                                // mIsFather: 0,
                                                reportName: "reports/salesItems" + ((value == '0') ? '' : value) + "." + this.state.type
                                            }
                                        );
                                        this.setState({ radioSelect: '' });
                                    }
                                }
                            >
                                <Option key={'0'} value={'0'}>{this.getText(12021)}</Option>
                                <Option key={'1'} value={'1'}>{this.getText(12022)}</Option>
                                <Option key={'2'} value={'2'}>{this.getText(12023)}</Option>
                                <Option key={'3'} value={'3'}>{this.getText(12024)}</Option>
                                <Option key={'9'} value={'9'}>{this.getText(14077)}</Option>
                                <Option key={'4'} value={'4'}>{this.getText(12025)}</Option>
                                <Option key={'5'} value={'5'}>{this.getText(12026)}</Option>
                                <Option key={'6'} value={'6'}>{this.getText(12027)}</Option>
                                <Option key={'7'} value={'7'}>{this.getText(12028)}</Option>
                                <Option key={'8'} value={'8'}>{this.getText(12029)}</Option>
                            </Select>
                        </FormItem>

                        {SalesItems.mRequestedReport == '1' ?
                            (<FormItem>
                                <Radio.Group value={SalesItems.mBreakType} onChange={(e) => {
                                    this.setReportParameters("SalesItems", { mBreakType: e.target.value });
                                }}>
                                    <Radio value={'0'}>{this.getText(17630)}</Radio><br />
                                    <Radio value={'1'}>{this.getText(17631)}</Radio><br />
                                    <Radio value={'2'}>{this.getText(17632)}</Radio>
                                </Radio.Group>
                            </FormItem>)
                            : ""}

                        <FormItem label={this.getText(12010)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, null, SalesItems.mUpperSelectionDate)}
                                // disabledDate={this.disabledStartDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                //value={FromDate ? moment(FromDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12032)}
                                value={SalesItems.mLowerSelectionDate ? moment(SalesItems.mLowerSelectionDate, "YYYY-MM-DD") : null}
                                onChange={this.onStartChange}
                            //onOpenChange={this.handleStartOpenChange}
                            />
                        </FormItem>
                        <FormItem label={this.getText(12011)} /*style={isEndDateShownStyle}*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, SalesItems.mLowerSelectionDate, null)}
                                //  disabledDate={this.disabledEndDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={SalesItems.mUpperSelectionDate ? moment(SalesItems.mUpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12033)}
                                onChange={this.onEndChange}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>

                        {this.state.SalesItems.mRequestedReport == 9 ? [
                            <FormItem>
                                <Checkbox
                                    style={radioStyle}
                                    checked={this.state.SalesItems.mOnlyDefault == 1}
                                    onChange={(e) => {
                                        this.setReportParameters("SalesItems", { mOnlyDefault: e.target.checked ? 1 : 0 });
                                    }}>{this.getText(15368)}</Checkbox>
                            </FormItem>,
                            <FormItem>
                                <Checkbox
                                    style={radioStyle}
                                    checked={this.state.SalesItems.mSplitPerStorage == 1}
                                    onChange={(e) => {
                                        this.setReportParameters("SalesItems", { mSplitPerStorage: e.target.checked ? 1 : 0 });
                                    }}>{this.getText(19930)}</Checkbox>
                            </FormItem>
                        ]
                            : ''}


                        <FormItem label={this.getText(12006)}>
                            {this.createCheckBox('mEmployeeList', Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mEmployeeList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesItems", { mEmployeeList: value.join(',') })}
                                value={(this.state.SalesItems.mEmployeeList === '') ? [] : this.state.SalesItems.mEmployeeList.split(',')}
                            >
                                {listEmployee}
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(12005)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setReportParameters("SalesItems", { mPosList: value.join(','), mStorageList: typeList.join(',') })}
                                value={(this.state.SalesItems.mPosList === '') ? [] : this.state.SalesItems.mPosList.split(',')}
                            />
                            {/* {this.createCheckBox('mPosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mPosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesItems", { mPosList: value.join(',') })}
                                value={(this.state.SalesItems.mPosList === '') ? [] : this.state.SalesItems.mPosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>
                        {/* <FormItem label={this.getText(12007)}>
                            {this.createCheckBox('mStorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mStorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesItems", { mStorageList: value.join(',') })}
                                value={(this.state.SalesItems.mStorageList === '') ? [] : this.state.SalesItems.mStorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}
                        <FormItem label={this.getText(12008)}>
                            {/* {
                                this.createCheckBox('mTagList', Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", [])
                                    .reduce((acc, i) => {
                                        //console.log("acc: ", acc); 
                                        return acc.concat((i.tags.map(j => j.id)));
                                    }, [])
                                    .join(',')
                                )
                            }
                            <Select key={"mTagList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesItems", { mTagList: value.join(',') })}
                                value={(this.state.SalesItems.mTagList === '') ? [] : this.state.SalesItems.mTagList.split(',')}
                            >
                                {listItemTags}
                            </Select> 
                            <Switch checkedChildren={this.getText(12030)} unCheckedChildren={this.getText(12031)} 
                             <TagRelationRadio showed={this.state.SalesItems.mTagList}
                                onChange={(value) => this.setReportParameters("SalesItems", { mTagRelation: (value === true) ? 1 : 0 })}
                                checked={this.state.SalesItems.mTagRelation == 1}
                            //checked={}
                            />
                            <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("SalesItems", { mTagList: value.join(',') })}
                                value={(this.state.SalesItems.mTagList === '') ? [] : this.state.SalesItems.mTagList.split(',')}
                            /> */}

                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.SalesItems.mTagList}
                                onChange={(value) => { this.setReportParameters("SalesItems", { mTagList: value }) }}
                                tagRelationValue={this.state.SalesItems.mTagRelation}
                                tagRelationOnChange={(value) => {
                                    let mTagList = this.state.SalesItems.mTagList;
                                    let from = this.state.SalesItems.mTagRelation;
                                    let to = value.target.value;
                                    let oldJson = mTagList.indexOf("{") > -1 ? JSON.parse(mTagList) : {}
                                    if (from == "2") mTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") mTagList = JSON.stringify({ tagList1: mTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("SalesItems", { mTagRelation: to, mTagList })
                                }}
                            />

                        </FormItem>
                        <FormItem label={this.getText(19246)}>
                            <GenericSelectorCustTags
                                {...this.props}
                                multi
                                value={this.state.SalesItems.mCustTagList ? this.state.SalesItems.mCustTagList.split(",") : []}
                                onChange={(value) => { this.setReportParameters("SalesItems", { mCustTagList: value ? value.join(",") : "" }) }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(12009)}>
                            {/* {this.createCheckBox('mSupplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mSupplierList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesItems", { mSupplierList: value.join(',') })}
                                value={(this.state.SalesItems.mSupplierList === '') ? [] : this.state.SalesItems.mSupplierList.split(',')}
                            >
                                {listSuppliers}
                            </Select> */}
                            <GenericSelectorSuppliers {...this.props} multi notApi
                                onChange={(value) => this.setReportParameters("SalesItems", { mSupplierList: value.join(',') })}
                                value={(this.state.SalesItems.mSupplierList === '') ? [] : this.state.SalesItems.mSupplierList.split(',')}
                            />
                        </FormItem>

                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state.SalesItems._withSendingDocs}
                                onChange={e => { this.setReportParameters("SalesItems", { _withSendingDocs: e }) }}
                            />
                            {/* // <Checkbox
                            //     checked={this.state.SalesItems._withSendingDocs == 1}
                            //     onChange={e => { this.setReportParameters("SalesItems", { _withSendingDocs: e.target.checked ? 1 : 0 }) }}
                            // >{this.getText(16616)}</Checkbox> */}
                        </FormItem>


                        <FormItem label={this.getText(12012)} /*style={isRangeShownStyle}*/>
                            <Checkbox //onChange={this.handleCheckBoxRange}
                                //ApplyRange_Apply
                                //checked={(this.props.data[this.id].editedRecord['mIsActive'] == 1) ? true : false}
                                checked={this.state.SalesItems.mApplyRangeApply == 1}
                                onChange={(value) => this.setReportParameters("SalesItems", { mApplyRangeApply: value.target.checked ? 1 : 0 })}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Lower
                                //value={}
                                value={this.state.SalesItems.mApplyRangeLower}
                                onChange={(value) => this.setReportParameters("SalesItems", { mApplyRangeLower: value })}
                                disabled={this.state.SalesItems.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                            <InputNumber
                                //ApplyRange_Upper
                                //value={}
                                value={this.state.SalesItems.mApplyRangeUpper}
                                onChange={(value) => this.setReportParameters("SalesItems", { mApplyRangeUpper: value })}
                                disabled={this.state.SalesItems.mApplyRangeApply != 1}
                                style={{ marginLeft: '4px' }}

                            />
                        </FormItem>


                        <RadioGroup
                            style={isMinPercShownStyle}
                            value={this.state.radioSelect}
                            onChange={(v) => {
                                this.setReportParameters("SalesItems", { mMinimalPercentage: 0, mUpperItems: 0, mLowerItems: 0 });
                                this.setState({ radioSelect: v.target.value });
                            }}>
                            {this.createRadioBox('1', this.getText(12013), 'mMinimalPercentage')}
                            {this.createRadioBox('2', this.getText(12014), 'mUpperItems')}
                            {this.createRadioBox('3', this.getText(12015), 'mLowerItems')}
                        </RadioGroup>

                        {this.state.SalesItems.mRequestedReport == 9 ? (
                            <FormItem>
                                <Checkbox
                                    style={radioStyle}
                                    checked={this.state.SalesItems.mSortPerSum == 1}
                                    onChange={(e) => {
                                        this.setReportParameters("SalesItems", { mSortPerSum: e.target.checked ? 1 : 0 });
                                    }}>{this.getText(14549)}</Checkbox>

                                {this.state.SalesItems.mSortPerSum == 1 ? (
                                    <RadioGroup
                                        value={this.state.SalesItems.mSortPerSumAsc}
                                        onChange={(v) => {
                                            this.setReportParameters("SalesItems", { mSortPerSumAsc: v.target.value })
                                        }}>
                                        <Radio style={radioStyle} value={'1'}>{this.getText(14550)}</Radio>
                                        <Radio style={radioStyle} value={'0'}>{this.getText(14551)}</Radio>
                                    </RadioGroup>
                                ) : ''}

                            </FormItem>) : ''}

                        {['0', '5', '6', '7', '8'].indexOf(this.state.SalesItems.mRequestedReport) > -1 ? (
                            <FormItem>
                                <Checkbox
                                    checked={this.state.SalesItems.mViewStock == 1}
                                    onChange={(e) => {
                                        this.setReportParameters("SalesItems", { mViewStock: e.target.checked ? 1 : 0 });
                                    }}>{this.getText(15965)}</Checkbox>
                            </FormItem>
                        ) : ""}

                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            storagesField={'mStorageList'}
                            tagsField={'mTagList'}
                            posField={'mPosList'}
                            xlsReportName={xlsReportName}
                            datesToFavorites={[
                                { field: 'mLowerSelectionDate', label: this.getText(12010) },
                                { field: 'mUpperSelectionDate', label: this.getText(12011) },
                            ]}
                            encodeURIFields={this.state.SalesItems.mTagRelation == '2' ? ['mTagList'] : null}
                            favoriteSave
                        />
                    </Form>
                </Card>
            </div>
        );
    }
}
export default SalesItems;