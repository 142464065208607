/* @flow */
/*jshint esversion: 6 */
import { Button, Row, Col, Input, InputNumber, Form, Checkbox, Tooltip, Icon, message } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "../../App.css";
import DateTimePicker from "../../components/DateTimePicker";
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import CopyDocumentReport from "../reports/PaymentsSearchReport";
import GenericSelector from "../../components/GenericSelector";

const nowDate = moment(Date.now()).format("YYYY-MM-DD");

class DepositDocs extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalDepositCash: null,
            modalDepositChecks: null,
            modalDepositDocsView: null,

            depositSum: null,
            depositValDate: null,
            depositRef2: null,
            depositBankId: null,
            depositRemarks: "",

            cashBalanceFromDatetime: null,
            cashBalanceToDatetime: null,

            checksBalanceFromDate: null,
            checksBalanceToDate: null,
            checksSearch: null,

            modalCompanyBanks: false,
            // modalCreateCompanyBank: false,
            // modalCreateCompanyBankBankNum: null,
            // modalCreateCompanyBankBranchNum: null,
            // modalCreateCompanyBankAccountNum: null,
            // modalCreateCompanyBankDescription: null,
            // modalCreateCompanyBankAccountingNumber: null,

            userPosDefault: null,

            jointCashDeposit: 0,

        };

        this.id = "DEPOSIT_DOCS";
        this.id_checks = "NOT_DEPOSIT_CHECKS";
        this.id_banks = "COMPANY_BANKS";
        this.id_banks_selector = "COMPANY_BANKS_SELECTOR";

        this.api = {
            get: "get_pos_deposit_balance",
            calc: "calc_cash_deposit_balance",

            create: "create_deposit_doc",
            get_checks: "get_not_deposit_checks",
            set_checks: "set_deposit_checks",

            get_banks: "get_company_banks",
            create_edit_banks: "create_edit_company_banks",
            delete_banks: "delete_company_banks",
        }


        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.id_checks] === undefined) props.data[this.id_checks] = { ...props.data.genericData };
        if (props.data[this.id_banks] === undefined) props.data[this.id_banks] = { ...props.data.genericData };
        if (props.data[this.id_banks_selector] === undefined) props.data[this.id_banks_selector] = { ...props.data.genericSelector }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.genericDataGetRequested(
            this.id_banks, this.props.user.companyCode, this.props.user.token, this.api.get_banks);
        this.updateBankSelector();
        this.sendAPI("get_default_user_pos", "", ob => { this.setState({ userPosDefault: ob.data }) })
        this.sendAPI("get_all_gp_by_user", "", ob => {
            if (ob.data) {
                ob.data.split("\r").forEach(x => {
                    let y = x.split("\f");
                    if (y[0] == 'GP_JointCashDeposit' && y[1]) this.setState({ jointCashDeposit: y[1] })
                })
            }
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(18202);
    }

    updateBankSelector = () => {
        setTimeout(() => {
            let depositBankId = null;
            const dataset = this.props.data[this.id_banks].data.map(x => {
                let y = x.split('\f');
                if (y[6] == '1') depositBankId = y[0];
                return { code: y[0], name: y[4] }
            });
            this.setState({ depositBankId }, () => {
                this.props.dataActions.setJson(this.id_banks_selector, { dataset })
            })
        }, 1000);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    createDepositCashDoc = () => {
        const { modalDepositCash, depositSum, depositValDate, depositRef2, depositBankId, depositRemarks } = this.state;
        let dataSend = "_pos\f_type\f_sum\f_valDate\f_ref2\f_bankId\f_checkList\f_remarks\r" +
            modalDepositCash.pId + "\f42\f" +
            depositSum + "\f" +
            depositValDate + "\f" +
            (depositRef2 ?? "") + "\f" +
            depositBankId + "\f\f" + depositRemarks;

        this.sendAPI(this.api.create, dataSend, ob => {
            this.generateReport({
                DocumentNumber: ob.data,
                DocumentTypeID: 42,
                DocumentPOSID: modalDepositCash.pId > 0 ? modalDepositCash.pId : this.state.userPosDefault,
                isOriginal: true,
            });
            this.setState({ modalDepositCash: null }, this.refreshData)
        })
    }

    createDepositChecksDoc = () => {
        const { modalDepositChecks, depositValDate, depositRef2, depositBankId, depositRemarks } = this.state;
        let depositSum = 0;
        let dataList = this.props.data[this.id_checks].data.map(x => x.split("\f"))
            .filter(f => f[8] == '1')
            .map(y => {
                depositSum += parseFloat(y[6]);
                let keys = y[0].split("-");
                return {
                    DocNumber: keys[0],
                    DocType: keys[1],
                    DocPOS: keys[2],
                    PayNumber: keys[3],
                    ValueDate: y[1],
                    CheckNumber: y[2],
                    BankNumber: y[3],
                    BranchNumber: y[4],
                    AccountNumber: y[5],
                    Sum: y[6],
                }
            });

        let dataSend = "_pos\f_type\f_sum\f_valDate\f_ref2\f_bankId\f_checkList\f_remarks\r" +
            modalDepositChecks.pId + "\f43\f" +
            depositSum + "\f" +
            depositValDate + "\f" +
            depositRef2 + "\f" +
            depositBankId + "\f" +
            JSON.stringify(dataList) + "\f" +
            depositRemarks;

        this.sendAPI(this.api.create, dataSend, ob => {
            let dataChecksSend = "_docNum\f_docType\f_docPos\f_docPayNumber\f_depositNumber";
            dataList.forEach(x => { dataChecksSend += "\r" + x.DocNumber + "\f" + x.DocType + "\f" + x.DocPOS + "\f" + x.PayNumber + "\f" + ob.data })

            this.sendAPI(this.api.set_checks, dataChecksSend, () => {
                this.generateReport({
                    DocumentNumber: ob.data,
                    DocumentTypeID: 43,
                    DocumentPOSID: modalDepositChecks.pId > 0 ? modalDepositChecks.pId : this.state.userPosDefault,
                    isOriginal: true,
                });
                this.setState({ modalDepositChecks: null }, this.refreshData)
            })
        })
    }

    generateReport = (params) => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    ...params,
                    CompanyCode: this.props.user.companyCode,
                    userID: this.props.user.userId,
                    userName: encodeURIComponent(this.props.user.displayName),
                    CentralFatherItem: '0',
                    REPORT_LOCALE: this.getText(101),
                    reportName: "reports/DocumentCopy.pdf"
                }
            ]
        });
    }

    apiChecks = () => {
        let _pos = this.state.modalDepositChecks ? this.state.modalDepositChecks.pId : null
        this.props.dataActions.genericDataSetFilter(
            this.id_checks, this.props.user.companyCode, this.props.user.token, { _pos }, this.api.get_checks);
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    companyBankField = (text, record, field) => {
        const { editing, editedRecord } = this.props.data[this.id_banks];
        if (field == "bID") {
            return (<TableLineIcons
                onEdit={record.key == editing ? null : () => {
                    this.props.dataActions.genericDataStartEditing(this.id_banks, record.key, record.index)
                }}
                deleteQuetion={record.key == editing ? null : { title: this.getText(19065), okText: this.getText(19066), cancelText: this.getText(19067) }}
                onDelete={record.key == editing ? null : () => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id_banks, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_banks, this.api.get_banks);
                }}
                onCheck={record.key != editing ? null : () => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.id_banks, this.props.user.companyCode, this.props.user.token, this.api.create_edit_banks, this.api.get_banks);
                    this.updateBankSelector()
                }}
                onClose={record.key != editing ? null : () => {
                    if (editing == '0') this.props.dataActions.genericDataCancelInlineCreate(this.id_banks);
                    else this.props.dataActions.genericDataCancelEditing(this.id_banks);
                }}
            />)
        } else if (field == "bDefault") {
            return (<Checkbox
                checked={(record.key == editing) ? editedRecord[field] == 1 : text == 1}
                disabled={(record.key != editing)}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id_banks, { [field]: e.target.checked ? '1' : '0' }) }}
            />)
        } else {
            return (record.key == editing) ?
                (<Input
                    value={editedRecord[field]}
                    onChange={e => { this.props.dataActions.genericDataSetEdit(this.id_banks, { [field]: e.target.value }) }}
                />)
                : text
        }
    }

    createModalTitle = (first, data) => {
        let res = first;
        if (data) {
            if (data.pId > 0) res += " - " + data.pId;
            if (data.pName) res += " - " + data.pName;
        }
        return res;
    }

    render() {
        const gt = this.getText;
        const render = t => t;
        const renderSum = t => (<div style={{ marginRight: 10, textAlign: 'right' }}>{t ? parseFloat(t).toFixed(2) : '0.00'}</div>);
        const renderActions = (t, r) => {
            return (<TableLineIcons
                key={"DepositDocs-pos-" + r.pId}
                genericIcons={[
                    this.state.jointCashDeposit == '1' ? "" : { type: "dollar", onClick: () => { this.setState({ modalDepositCash: r, depositValDate: nowDate, depositSum: null, depositRef2: null }) }, tooltip: gt(18208), disabled: !(parseFloat(r.pCashBalance) > 0) },
                    { type: "dollar", onClick: () => { this.setState({ modalDepositChecks: r, depositValDate: nowDate, depositSum: null, depositRef2: null }, this.apiChecks) }, tooltip: gt(18209), disabled: !(parseFloat(r.pChecksBalance) > 0) },
                    { type: "eye", onClick: () => { this.setState({ modalDepositDocsView: { ...r, typeView: '42' } }) }, tooltip: gt(18210) },
                    { type: "eye", onClick: () => { this.setState({ modalDepositDocsView: { ...r, typeView: '43' } }) }, tooltip: gt(18211) },
                ]}
            />)
        }

        const renderCBCheck = (t, r) => {
            return (<Checkbox
                checked={t == 1}
                onChange={e => {
                    let data = this.props.data[this.id_checks].data.map(x => {
                        let y = x.split("\f");
                        if (y[0] == r.key) {
                            y[8] = e.target.checked ? "1" : "0";
                            return y.join("\f");
                        } else {
                            return x
                        }
                    });
                    this.props.dataActions.setJson(this.id_checks, { data })
                }} />)
        }

        const renderPrintCheckDoc = t => {
            return (<TableLineIcons genericIcons={[{
                type: "printer",
                onClick: () => {
                    let p = t.split("-");
                    this.generateReport({
                        DocumentNumber: p[0],
                        DocumentTypeID: p[1],
                        DocumentPOSID: p[2],
                        isOriginal: false,
                    });
                }
            }]} />)
        }

        const renderPayType = text => {
            switch (text) {
                case '2': return gt(19559);
                case '6': return gt(19560);
                case '100': return gt(19561);
            }
        }

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",

            maxWidth: 1000,
            margin: 'auto'
        };

        const {
            modalDepositCash, modalDepositChecks, modalDepositDocsView,
            depositSum, depositValDate, depositRef2, depositBankId, depositRemarks,
            cashBalanceFromDatetime, cashBalanceToDatetime,
            checksBalanceFromDate, checksBalanceToDate, checksSearch
        } = this.state;

        const headersChecks = this.props.data[this.id_checks].headers;
        const dataSourceChecks = this.props.data[this.id_checks].data.map((x, index) => {
            let y = x.split("\f");
            let obj = { key: y[0], index }
            headersChecks.forEach((z, i) => { obj = { ...obj, [z]: y[i] } })
            let isHide = false;
            if (checksBalanceFromDate && checksBalanceFromDate.valueOf() > obj.cValueDate.valueOf()) isHide = true;
            else if (checksBalanceToDate && checksBalanceToDate.valueOf() < obj.cValueDate.valueOf()) isHide = true;
            else if (checksSearch && !y.find(f => f.indexOf(checksSearch) > -1)) isHide = true;
            return { ...obj, isHide };
        })

        const dataSourceChecksFilter = dataSourceChecks.filter(f => !f.isHide)

        const checkedChecksList = dataSourceChecks.filter(f => f.cIsChecked == '1')
        let checkedChecksSum = 0;
        checkedChecksList.forEach(x => { checkedChecksSum += parseFloat(x.cSum) })
        const numRegexp = new RegExp(/^[0-9]+$/)
        const modalFields = [
            <Form.Item label={gt(18214)}>
                <DateTimePicker format={"YYYY-MM-DD"} value={depositValDate} onChange={e => { this.setState({ depositValDate: e }) }} />
            </Form.Item>,
            <Form.Item label={gt(18215)}>
                <Input value={depositRef2} onChange={e => { if (numRegexp.test(e.target.value)) this.setState({ depositRef2: e.target.value }) }} />
            </Form.Item>,
            <Form.Item label={gt(19054)}>
                <Row>
                    <Col span={20}>
                        <GenericSelector {...this.props} id={this.id_banks_selector} value={depositBankId} onChange={e => { this.setState({ depositBankId: e }) }} />
                    </Col>
                    <Col span={4}>
                        <Button onClick={() => { this.setState({ modalCompanyBanks: true }) }}><Icon type="table" /></Button>
                    </Col>
                </Row>
            </Form.Item>,
            <Form.Item label={gt(19999)}>
                <Input.TextArea value={depositRemarks} onChange={e => { this.setState({ depositRemarks: e.target.value }) }} />
            </Form.Item>,
        ]

        const checkboxMultiChecks = (type) => {
            let list = dataSourceChecksFilter.filter(f => {
                if (type == 1) return f.cValueDate <= moment().format("YYYY-MM-DD")
                if (type == 2) return f.cValueDate > moment().format("YYYY-MM-DD")
                if (type == 3) return f.cPayType == '2';
                if (type == 4) return f.cPayType == '6';
                if (type == 5) return f.cPayType == '100';
                return true;
            });
            let cList = list.filter(f => f.cIsChecked == '1')
            return {
                disabled: !list.length,
                checked: list.length && list.length === cList.length,
                indeterminate: list.length !== cList.length && cList.length,
                onChange: e => {
                    let data = this.props.data[this.id_checks].data.map(x => {
                        let y = x.split("\f");
                        if (type == 1 && y[1] > moment().format("YYYY-MM-DD")) return x
                        if (type == 2 && y[1] <= moment().format("YYYY-MM-DD")) return x
                        if (type == 3 && y[7] != '2') return x
                        if (type == 4 && y[7] != '6') return x
                        if (type == 5 && y[7] != '100') return x
                        y[8] = e.target.checked ? "1" : "0";
                        return y.join("\f");
                    });
                    this.props.dataActions.setJson(this.id_checks, { data })
                }
            }
        }

        let centralDeposit = {
            pId: '0',
            pName: gt(19555),
            pCashBalance: 0,
            pChecksBalance: 0,
        }

        this.props.data[this.id].data.forEach(x => {
            let y = x.split("\f");
            if (parseFloat(y[2])) centralDeposit.pCashBalance = (parseFloat(centralDeposit.pCashBalance) + parseFloat(y[2])).toFixed(2)
            if (parseFloat(y[3])) centralDeposit.pChecksBalance = (parseFloat(centralDeposit.pChecksBalance) + parseFloat(y[3])).toFixed(2)
        })

        const centralDepositBtn = (tooltip, state) => {
            return (<Tooltip title={this.getText(tooltip)}>
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                    if (this.state.userPosDefault) {
                        this.setState({ [state]: centralDeposit, depositValDate: nowDate, depositSum: null, depositRef2: null },
                            state == "modalDepositChecks" ? this.apiChecks : undefined)
                    } else {
                        message.error(this.getText(19557))
                    }
                }}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"dollar"} /></Button>
            </Tooltip>)
        }

        let genericActionButtons = [
            this.state.jointCashDeposit == '1' ? centralDepositBtn(19553, 'modalDepositCash') : "",
            centralDepositBtn(19554, 'modalDepositChecks'),
        ]

        let columns = [
            { title: gt(18204), key: "pId", dataIndex: "pId", width: "15%", render },
            { title: gt(18205), key: "pName", dataIndex: "pName", width: "35%", render },
            this.state.jointCashDeposit == '1' ? null : { title: gt(18206), key: "pCashBalance", dataIndex: "pCashBalance", width: "15%", render: renderSum },
            { title: gt(18207), key: "pChecksBalance", dataIndex: "pChecksBalance", width: "15%", render: renderSum },
            { title: "", width: "20%", render: renderActions },
        ].filter(f => f)



        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(18203)}
                columns={columns}
                pagination={{ pageSize: parseInt((window.innerHeight - 340 > 30) ? (window.innerHeight - 340) / 30 : 1) }}
                genericActionButtons={genericActionButtons}
                rowsFilter={r => r.pCashBalance > 0 || r.pChecksBalance > 0}
            />
            <GenericModal
                visible={modalDepositCash != null}
                onCancel={() => { this.setState({ modalDepositCash: null }) }}
                width={800}
                title={this.createModalTitle(gt(18208), modalDepositCash)}
                footer={[
                    <Button type="primary" onClick={this.createDepositCashDoc} disabled={!depositSum || !depositValDate}>{gt(18212)}</Button>,
                    <Button onClick={() => { this.setState({ modalDepositCash: null }) }}>{gt(18213)}</Button>,
                ]}>
                <Row>
                    <Col span={12}>
                        <h2>{gt(18206)}:</h2>
                        <h1>{modalDepositCash ? renderSum(modalDepositCash.pCashBalance) : ""}</h1>
                        <br />
                        <h3>{gt(18217)}:</h3>
                        <Form.Item label={gt(18218)}>
                            <DateTimePicker format={"YYYY-MM-DD HH:mm:ss"} showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} maxDate={cashBalanceToDatetime ? cashBalanceToDatetime.split(" ")[0] : null}
                                value={cashBalanceFromDatetime} onChange={e => { this.setState({ cashBalanceFromDatetime: e }) }} />
                        </Form.Item>
                        <Form.Item label={gt(18219)}>
                            <DateTimePicker format={"YYYY-MM-DD HH:mm:ss"} showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} minDate={cashBalanceFromDatetime ? cashBalanceFromDatetime.split(" ")[0] : null}
                                value={cashBalanceToDatetime} onChange={e => { this.setState({ cashBalanceToDatetime: e }) }} />
                        </Form.Item>
                        <Button type="primary" onClick={() => {
                            let dataSend = "_fromDate\f_toDate\f_pos\r" + cashBalanceFromDatetime + "\f" + cashBalanceToDatetime + "\f" + modalDepositCash.pId;
                            this.sendAPI(this.api.calc, dataSend, ob => {
                                this.setState({ depositSum: ob.data })
                            })
                        }}>{gt(18220)}</Button>

                    </Col>
                    <Col span={12}>
                        {modalFields}
                        <Form.Item label={gt(18216)}>
                            <InputNumber max={modalDepositCash ? modalDepositCash.pCashBalance : 0} value={depositSum} onChange={e => { this.setState({ depositSum: e }) }} />
                        </Form.Item>
                    </Col>
                </Row>

            </GenericModal>

            <GenericModal
                visible={modalDepositChecks != null}
                onCancel={() => { this.setState({ modalDepositChecks: null }) }}
                width={1000}
                title={this.createModalTitle(gt(18209), modalDepositChecks)}
                genericActionButtons={[
                    <Tooltip title={gt(18233)}>
                        <Button style={{ margin: 5 }}>
                            <Checkbox {...checkboxMultiChecks(0)}
                            // checked={dataSourceChecks.length === checkedChecksList.length}
                            // indeterminate={dataSourceChecks.length !== checkedChecksList.length && checkedChecksList.length}
                            // onChange={e => {
                            //     let data = this.props.data[this.id_checks].data.map(x => {
                            //         let y = x.split("\f");
                            //         y[8] = e.target.checked ? "1" : "0";
                            //         return y.join("\f");
                            //     });
                            //     this.props.dataActions.setJson(this.id_checks, { data })
                            // }}
                            />
                        </Button>
                    </Tooltip>
                ]}
                showsearch={val => { this.setState({ checksSearch: val }) }}
                footer={[
                    <Button type="primary" onClick={this.createDepositChecksDoc} disabled={!checkedChecksSum || !depositValDate}>{gt(18212)}</Button>,
                    <Button onClick={() => { this.setState({ modalDepositChecks: null }) }}>{gt(18213)}</Button>,
                ]}>
                <ResponsiveTable
                    tableOnly
                    dataSource={dataSourceChecksFilter}
                    columns={[
                        { title: gt(18223), key: "cValueDate", dataIndex: "cValueDate", width: "14%", render },
                        { title: gt(18224), key: "cCheckNumber", dataIndex: "cCheckNumber", width: "14%", render },
                        { title: gt(18225), key: "cBankNumber", dataIndex: "cBankNumber", width: "8%", render },
                        { title: gt(18226), key: "cBranchNumber", dataIndex: "cBranchNumber", width: "8%", render },
                        { title: gt(18227), key: "cAccountNumber", dataIndex: "cAccountNumber", width: "14%", render },
                        { title: gt(18228), key: "cSum", dataIndex: "cSum", width: "14%", render },
                        { title: gt(19558), key: "cPayType", dataIndex: "cPayType", width: "12%", render: renderPayType },
                        { title: gt(18229), key: "cIsChecked", dataIndex: "cIsChecked", width: "8%", render: renderCBCheck },
                        { title: gt(18230), key: "cDocPayKey", dataIndex: "cDocPayKey", width: "8%", render: renderPrintCheckDoc },
                    ]}
                    pagination={{ pageSize: parseInt((window.innerHeight - 440 > 30) ? (window.innerHeight - 340) / 30 : 1) }}
                />
                <Row>
                    <Col span={12}>
                        <Form.Item label={gt(18234)}>
                            <DateTimePicker format={"YYYY-MM-DD"} maxDate={checksBalanceToDate}
                                value={checksBalanceFromDate} onChange={e => { this.setState({ checksBalanceFromDate: e }) }} />
                        </Form.Item>
                        <Form.Item label={gt(18235)}>
                            <DateTimePicker format={"YYYY-MM-DD"} minDate={checksBalanceFromDate}
                                value={checksBalanceToDate} onChange={e => { this.setState({ checksBalanceToDate: e }) }} />
                        </Form.Item>
                        <Form.Item><Checkbox {...checkboxMultiChecks(1)}>{this.getText(19068)}</Checkbox></Form.Item>
                        <Form.Item><Checkbox {...checkboxMultiChecks(2)}>{this.getText(19069)}</Checkbox></Form.Item>
                        <Form.Item><Checkbox {...checkboxMultiChecks(3)}>{this.getText(19562)}</Checkbox></Form.Item>
                        <Form.Item><Checkbox {...checkboxMultiChecks(4)}>{this.getText(19563)}</Checkbox></Form.Item>
                        <Form.Item><Checkbox {...checkboxMultiChecks(5)}>{this.getText(19564)}</Checkbox></Form.Item>

                    </Col>
                    <Col span={12}>
                        {modalFields}
                        <h2>{gt(18236)} {checkedChecksList.length} {gt(18237)} ₪{checkedChecksSum}</h2>
                    </Col>
                </Row>


            </GenericModal>

            <GenericModal
                visible={this.state.modalCompanyBanks}
                onCancel={() => { this.setState({ modalCompanyBanks: false }) }}
                width={800}
                title={this.getText(19055)}
                actionButtons={[
                    {
                        buttonType: 'add',
                        buttonFunction: () => {
                            if (this.props.data[this.id_banks].editing === -1) {
                                this.props.dataActions.genericDataStartInlineCreate(this.id_banks);
                                setTimeout(() => {
                                    this.props.dataActions.genericDataSetEdit(this.id_banks, { bDefault: '1' })
                                }, 500);
                            }
                        }
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: () => {
                            this.props.dataActions.genericDataGetRequested(
                                this.id_banks, this.props.user.companyCode, this.props.user.token, this.api.get_banks);
                        }
                    }
                ]}>
                <ResponsiveTable
                    idTable={this.props.data[this.id_banks]}
                    tableOnly
                    columns={[
                        { title: this.getText(19057), key: "bBankNum", dataIndex: "bBankNum", width: "10%", render: (t, r) => this.companyBankField(t, r, "bBankNum") },
                        { title: this.getText(19058), key: "bBranchNum", dataIndex: "bBranchNum", width: "10%", render: (t, r) => this.companyBankField(t, r, "bBranchNum") },
                        { title: this.getText(19056), key: "bAccountNum", dataIndex: "bAccountNum", width: "10%", render: (t, r) => this.companyBankField(t, r, "bAccountNum") },
                        { title: this.getText(19059), key: "bDescription", dataIndex: "bDescription", width: "40%", render: (t, r) => this.companyBankField(t, r, "bDescription") },
                        { title: this.getText(19060), key: "bAccountingNumber", dataIndex: "bAccountingNumber", width: "10%", render: (t, r) => this.companyBankField(t, r, "bAccountingNumber") },
                        { title: this.getText(19063), key: "bDefault", dataIndex: "bDefault", width: "10%", render: (t, r) => this.companyBankField(t, r, "bDefault") },
                        { title: this.getText(19064), key: "bID", dataIndex: "bID", width: "10%", render: (t, r) => this.companyBankField(t, r, "bID") },
                    ]}
                />
            </GenericModal>

            {/* <GenericModal
                visible={this.state.modalCreateCompanyBank}
                onCancel={() => { this.setState({ modalCreateCompanyBank: false }) }}
                width={600}
                title={this.getText(19055)}
                footer={[
                    <Button type="primary" onClick={() => {
                        const {
                            modalCreateCompanyBankBankNum,
                            modalCreateCompanyBankBranchNum,
                            modalCreateCompanyBankAccountNum,
                            modalCreateCompanyBankDescription,
                            modalCreateCompanyBankAccountingNumber,
                        } = this.state;
                        let dataSend = "bankNum\fbranchNum\faccountNum\fdescription\faccountingNumber\r" +
                            modalCreateCompanyBankBankNum + "\f" +
                            modalCreateCompanyBankBranchNum + "\f" +
                            modalCreateCompanyBankAccountNum + "\f" +
                            modalCreateCompanyBankDescription + "\f" +
                            modalCreateCompanyBankAccountingNumber
                        this.sendAPI("create_company_bank", dataSend, (ob) => {
                            this.setState({
                                modalCreateCompanyBank: false,
                                depositBankId: ob.data,
                                modalCreateCompanyBankBankNum: null,
                                modalCreateCompanyBankBranchNum: null,
                                modalCreateCompanyBankAccountNum: null,
                                modalCreateCompanyBankDescription: null,
                                modalCreateCompanyBankAccountingNumber: null,
                            }, () => {
                                this.props.dataActions.genericSelectorRefreshDataset(
                                    this.selectors[0].id, this.props.user.companyCode, this.props.user.token, this.selectors[0].api);
                            })
                        })
                    }}>{this.getText(19061)}</Button>,
                    <Button onClick={() => { this.setState({ modalCreateCompanyBank: false }) }}>{this.getText(19062)}</Button>,
                ]}
            >
                <Form.Item label={this.getText(19057)}><Input value={this.state.modalCreateCompanyBankBankNum} onChange={e => { this.setState({ modalCreateCompanyBankBankNum: e.target.value }) }} /></Form.Item>
                <Form.Item label={this.getText(19058)}><Input value={this.state.modalCreateCompanyBankBranchNum} onChange={e => { this.setState({ modalCreateCompanyBankBranchNum: e.target.value }) }} /></Form.Item>
                <Form.Item label={this.getText(19056)}><Input value={this.state.modalCreateCompanyBankAccountNum} onChange={e => { this.setState({ modalCreateCompanyBankAccountNum: e.target.value }) }} /></Form.Item>
                <Form.Item label={this.getText(19059)}><Input value={this.state.modalCreateCompanyBankDescription} onChange={e => { this.setState({ modalCreateCompanyBankDescription: e.target.value }) }} /></Form.Item>
                <Form.Item label={this.getText(19060)}><Input value={this.state.modalCreateCompanyBankAccountingNumber} onChange={e => { this.setState({ modalCreateCompanyBankAccountingNumber: e.target.value }) }} /></Form.Item>
            </GenericModal> */}

            <CopyDocumentReport
                {...this.props}
                isModalFromDashboard
                visible={modalDepositDocsView != null}
                onCancel={() => { this.setState({ modalDepositDocsView: null }) }}
                docType={modalDepositDocsView ? modalDepositDocsView.typeView : ""}
                pos={modalDepositDocsView ? modalDepositDocsView.pId : ""}
            />
        </div>)
    }

}

export default DepositDocs;