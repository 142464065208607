/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Tooltip, message, Button, Form, Popover, Input, Checkbox, Radio } from "antd";
import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalCreateEditPointsOfSale from "../../components/ModalCreateEditPointsOfSale";
import ModalNumeratorsPointsOfSale from "../../components/ModalNumeratorsPointsOfSale";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
//import GenericSelector from "../../components/GenericSelector";
//import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import ModalDocumentPrintOptions from "../../components/ModalDocumentPrintOptions";
import { escapeRegExp } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const topHeight = 340; // px
const rowHeight = 30;  // px


type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PointsOfSale extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            posParametersModal: false,
            posParametersCopyFrom: null,
            posParametersCopyTo: null,
            posParametersImportDefaults: '0',
            modalTransactionDesTags: false,
            modalDocumentPrintOptions: null,
            modalDocumentPrintOptionsIsBO: null,
        };

        this.api = {
            get: "get_points_of_sale_table",
            delete: "delete_points_of_sale",
            copy_params: "copy_pos_parameters",
            def_params: "import_defaults_pos_parameters",

            get_t_des: "get_pos_transaction_des_tags",
            set_t_des: "set_pos_transaction_des_tags",
            delete_t_des: "delete_pos_transaction_des_tags",
            order_t_des: "order_pos_transaction_des_tags",
            all_t_des: "all_pos_transaction_des_tags",
        }

        this.id = 'POINTS_OF_SALE_TABLE';
        this.t_des_id = 'POS_TRANSACTION_DES_TAGS';

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        if (props.data[this.t_des_id] === undefined) props.data[this.t_des_id] = { ...props.data.genericData };

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.createEditModal(0) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    createButton = (title, type, onclick) => {
        return (<Tooltip title={title}><Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} onClick={onclick} /></Tooltip>)
    }

    createPopconfirm = (title, titleQ, type, onconfirm) => {
        return (<Popconfirm title={titleQ} onConfirm={onconfirm} okText={this.getText(11694)} cancelText={this.getText(11695)}>
            {this.createButton(title, type, () => { })}
        </Popconfirm>)
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10208);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 7;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "sID": col--;
            case "sName": col--;
            case "sTypeID": col--;
            case "sTypeName": col--;
            case "sStorageID": col--;
            case "sStorageName": col--;
            case "sActivationTime": break; // 7
            default:
                col = 0;
        }

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    createEditModal = (id) => {
        this.props.uiActions.showModal(TableIDs.modalCreateEditPointsOfSale, { sID: id });
    }

    deActivePos = (id) => {
        this.sendAPI(this.api.delete, id, () => message.success(this.getText(11696)));
        this.refreshData();
    }

    createNumeratorsModal = (id, name) => {
        this.props.uiActions.showModal(TableIDs.modalNumeratorsPointsOfSale, { sID: id, sName: name });
    }

    createNumeratorsAuto = (id) => {
        this.sendAPI("create_document_numerators", "sID\r" + id, () => message.success(this.getText(11697)))
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
    }

    showsearch = (val) => {
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                ...this.props.data[this.id].filters,
                // ui-pagination 
                page: 1,
                // sortType: 1,
                // api
                _page_num: 1,
                // _isAsc: 1,
                // _sort_by: 'col_1',
                _words: escapeRegExp(val),
                //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
            },
            this.api.get)
    }

    copyParametersField = (field, label) => {
        return (<Form.Item label={this.getText(label)}>
            <GenericSelectorPosStorages
                {...this.props}
                // id={TableIDs.genericSelectorPos}
                // api={"list_pos"}
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                disabled={field == "posParametersCopyFrom" && this.state.posParametersImportDefaults == "1"}
            />
        </Form.Item>)
    }

    copyParameters = () => {
        const { posParametersCopyFrom, posParametersCopyTo, posParametersImportDefaults } = this.state;
        let dataSend = '_from\f_to\r' + posParametersCopyFrom + '\f' + posParametersCopyTo;
        let script = posParametersImportDefaults == "1" ? this.api.def_params : this.api.copy_params;

        this.sendAPI(script, dataSend, () => {
            message.success(this.getText(16882));
            this.copyParametersClose()
        });
    }

    copyParametersClose = () => {
        this.setState({
            posParametersModal: false,
            posParametersCopyFrom: null,
            posParametersCopyTo: null,
            posParametersImportDefaults: "0",
        })
    }

    openTransactionDesTags = (id) => {
        this.setState({ modalTransactionDesTags: id }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.t_des_id, this.props.user.companyCode, this.props.user.token, { _pos: id }, this.api.get_t_des);
        })
    }

    renderTdes = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.t_des_id];
        if (record.key === editing) {
            return (<Input value={editedRecord.name} onChange={e => { this.props.dataActions.genericDataSetEdit(this.t_des_id, { name: e.target.value }) }} />)
        } else {
            return text;
        }
    }

    renderTdesEdit = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.t_des_id];
        if (record.key === editing) {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.t_des_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            this.api.set_t_des,
                            this.api.get_t_des,
                            { pos: this.state.modalTransactionDesTags });
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        if (editing == 0)
                            this.props.dataActions.genericDataCancelInlineCreate(this.t_des_id);
                        else
                            this.props.dataActions.genericDataCancelEditing(this.t_des_id);
                    }} />
            </span>)
        } else {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"}
                    onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.t_des_id, record.key, record.index)
                    }} />
                <Popconfirm title={this.getText(16906)}
                    onConfirm={() => {
                        this.props.dataActions.genericDataDeleteRow(
                            this.t_des_id,
                            record.key,
                            this.props.user.companyCode,
                            this.props.user.token,
                            this.api.delete_t_des,
                            this.api.get_t_des);
                    }}
                    okText={this.getText(16907)} cancelText={this.getText(16908)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                </Popconfirm>
            </span>)
        }
    }

    onDragAndDropTdes = (keyFrom, keyTo) => {
        let _pos = this.state.modalTransactionDesTags;
        this.sendAPI(this.api.order_t_des, "_pos\f_id\f_toId\r" + _pos + "\f" + keyFrom + "\f" + keyTo, () => { this.openTransactionDesTags(_pos) })

        console.log("dragOrderTable onDragAndDrop ", keyFrom, keyTo)
    }

    renderTdesECheckBox = (text, record) => {
        let isTitle = !record;
        let checked = false;
        let indeterminate = false;
        if (isTitle) {
            checked = true;
            this.props.data[this.t_des_id].data.forEach(x => {
                let _is_checked = x.split('\f')[3];
                console.log("_is_checked", _is_checked)
                if (_is_checked > 0) {
                    indeterminate = true;
                } else {
                    checked = false;
                }
            })
        } else {
            checked = record._is_checked > 0 ? true : false;
        }

        return (<Checkbox
            checked={checked}
            indeterminate={indeterminate && !checked}
            onChange={e => {
                if (isTitle) {
                    let _pos = this.state.modalTransactionDesTags;
                    this.sendAPI(this.api.all_t_des,
                        "_pos\f_checked\r" + _pos + "\f" + (e.target.checked ? '1' : '0'),
                        () => { this.openTransactionDesTags(_pos) }
                    )
                } else {
                    if (e.target.checked) {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.t_des_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            this.api.set_t_des,
                            this.api.get_t_des,
                            { pos: this.state.modalTransactionDesTags, code: '0', name: record._name });
                    } else {
                        this.props.dataActions.genericDataDeleteRow(
                            this.t_des_id,
                            record._is_checked,
                            this.props.user.companyCode,
                            this.props.user.token,
                            this.api.delete_t_des,
                            this.api.get_t_des);
                    }
                }
            }}
        />)
    }


    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };


        let columns = [
            {
                title: this.getText(11687),
                dataIndex: "sID",
                key: "sID",
                width: '7%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11688),
                dataIndex: "sName",
                key: "sName",
                width: '18%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11689),
                dataIndex: "sTypeName",
                key: "sTypeName",
                width: '18%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11690),
                dataIndex: "sStorageID",
                key: "sStorageID",
                width: '7%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11691),
                dataIndex: "sStorageName",
                key: "sStorageName",
                width: '18%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11692),
                dataIndex: "sActivationTime",
                key: "sActivationTime",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11693),
                width: '12%',
                render: (text, record) => {
                    let id = record['sID'];
                    let name = record['sName'];
                    return (
                        <span style={{ float: 'left' }}>
                            {/* {(record['sActivationTime']) ? this.createPopconfirm(this.getText(11698), this.getText(11702), "close-square", () => { this.deActivePos(id) }) : ''} */}
                            {this.createButton(this.getText(11699), "edit", () => { this.createEditModal(id) })}
                            {this.createButton(this.getText(11700), "diff", () => { this.createNumeratorsModal(id, name) })}
                            {this.createButton(this.getText(11701), "plus-circle", () => { this.createNumeratorsAuto(id) })}
                            {this.createButton(this.getText(16903), "profile", () => { this.openTransactionDesTags(id) })}
                            {this.createButton(this.getText(17397), "exception", () => { this.setState({ modalDocumentPrintOptions: id, modalDocumentPrintOptionsIsBO: record['sIsBOPos'] }) })}
                        </span >);
                }
            }
        ]

        let copyParametersBtn = (
            <Tooltip title={this.getText(16877)}>
                <Button style={{ marginLeft: 5, marginTop: 5 }} onClick={() => { this.setState({ posParametersModal: true }) }}>
                    <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="copy" theme="outlined" />
                </Button>
            </Tooltip>);

        const { posParametersCopyFrom, posParametersCopyTo, posParametersImportDefaults } = this.state;

        let posParametersCopyDisabled = (!posParametersCopyFrom && posParametersImportDefaults == '0') || !posParametersCopyTo || posParametersCopyFrom === posParametersCopyTo

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    showsearch={this.showsearch}
                    columns={columns}
                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(11686)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={copyParametersBtn}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalCreateEditPointsOfSale
                    id={TableIDs.modalCreateEditPointsOfSale}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditPointsOfSale)}
                />
                <ModalNumeratorsPointsOfSale
                    id={TableIDs.modalNumeratorsPointsOfSale}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalNumeratorsPointsOfSale)}
                />
                <GenericModal
                    visible={this.state.posParametersModal}
                    onCancel={this.copyParametersClose}
                    width={500}
                    title={this.getText(16877)}
                    footer={[
                        <Button onClick={this.copyParametersClose}>{this.getText(16881)}</Button>,
                        <Button disabled={posParametersCopyDisabled} type={"primary"} onClick={this.copyParameters}>{this.getText(16880)}</Button>,
                    ]}>
                    <Radio.Group value={this.state.posParametersImportDefaults} onChange={e => { this.setState({ posParametersImportDefaults: e.target.value }) }}>
                        <Radio value={'1'}>{this.getText(19410)}</Radio><br />
                        <Radio value={'0'}>{this.getText(19411)}</Radio><br />
                    </Radio.Group>
                    {this.copyParametersField('posParametersCopyFrom', 16878)}
                    {this.copyParametersField('posParametersCopyTo', 16879)}
                </GenericModal>
                <GenericModal
                    visible={this.state.modalTransactionDesTags}
                    onCancel={() => { this.setState({ modalTransactionDesTags: false }) }}
                    width={500}
                    title={this.getText(16904)}
                    genericActionButtons={[
                        <Button style={{ margin: 5 }}>
                            {this.renderTdesECheckBox()}
                        </Button>
                    ]}
                >
                    <ResponsiveTable
                        tableOnly
                        id={this.t_des_id}
                        onDragAndDrop={this.onDragAndDropTdes}
                        idTable={this.props.data[this.t_des_id]}
                        columns={[
                            { title: this.getText(16905), key: "_name", dataIndex: "_name", width: "40%", render: t => t },
                            { title: this.getText(16922), key: "_desc", dataIndex: "_desc", width: "40%", render: t => t },
                            { title: "", key: "_is_checked", dataIndex: "_is_checked", width: "20%", render: this.renderTdesECheckBox },
                        ]}
                        pagination={false}
                        scroll={{ y: window.innerHeight - 200 }}
                    />
                </GenericModal>
                <ModalDocumentPrintOptions
                    {...this.props}
                    visible={this.state.modalDocumentPrintOptions}
                    onCancel={() => { this.setState({ modalDocumentPrintOptions: null }) }}
                    isBO={this.state.modalDocumentPrintOptionsIsBO}
                />
            </div>
        )

    }
}
export default PointsOfSale;