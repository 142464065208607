
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Checkbox, Select, Radio, message } from "antd";
//import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import * as XLSX from 'xlsx/xlsx.mjs';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentsAnalysis extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.filterSuppliers = '1,2,3,4,7,48,76';
      
        this.state = {
            dateFrom: null,
            dateTo: null,
            branchList: '',
            compDetaild: 1,
            centralType: 1,
            payTypeList: '',
            payItemList: '',
            payItemsSumType: '1',
            orderSuppliers: this.filterSuppliers,
            reportName: "reports/PaymentsAnalysis.pdf",
            reportType: "pdf"
        };


        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorPaymentTypes] === undefined) props.data[TableIDs.genericSelectorPaymentTypes] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorExternalOrderSuppliers] === undefined) props.data[TableIDs.genericSelectorExternalOrderSuppliers] = { ...props.data.genericSelector };
        if (props.data.PAY_ITEMS_LIST === undefined) props.data.PAY_ITEMS_LIST = { ...props.data.genericSelector };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14584);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    createDatePicker = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == 'dateTo' ? this.state.dateFrom : null}
                maxDate={field == 'dateFrom' ? this.state.dateTo : null}
            />
        </FormItem>)
    }


    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, e => { console.error(e) });
    }

    generateXlsxReport = (params) => {
        let dataSend = "dateFrom\fdateTo\fbranchList\fcompDetaild\fpayItemList\fpayItemsSumType\forderSuppliers\r" +
            (params.dateFrom ?? "") + "\f" +
            (params.dateTo ?? "") + "\f" +
            (params.branchList ?? "") + "\f" +
            (params.compDetaild ?? "") + "\f" +
            (params.payItemList ?? "") + "\f" +
            (params.payItemsSumType ?? "") + "\f" +
            (params.orderSuppliers ?? "");

        this.sendAPI("get_payments_analysis_excel_data", dataSend, (ob) => {
            let abcList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            let colList = [...abcList];
            abcList.forEach(x => { abcList.forEach(y => { colList.push(x + y) }) });
            let excelData = {}
            let lastRow = 1;
            let lastCol = "A";
            let lastColIndex = 0;

            let styles = [{ width: 8 }];

            let fullData = ob.data ? ob.data.split("\r").map(x => x.split("\f").slice(1)) : [];

            fullData.forEach((x, i) => {
                let row = i + 1
                lastRow = row;
                x.forEach((y, ii) => {
                    let col = colList[ii];
                    if (lastColIndex < ii) {
                        lastColIndex = ii;
                        lastCol = col;
                        styles.push({ width: 15 })
                    }
                    if (ii == 1 || i == 0) excelData = { ...excelData, [col + row]: { t: "s", v: y } }
                    else excelData = { ...excelData, [col + row]: { t: "n", v: y, z: "#,##0" } }
                })
            })

            excelData = {
                ...excelData,
                ['!cols']: styles,
                ["!ref"]: "A1:" + lastCol + lastRow,
            }

            console.log("excelData", excelData)

            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

            const wb = { Sheets: { data: excelData }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const blob = new Blob([excelBuffer], { type: fileType });
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = params.reportName + "x";
            a.click();
        })

    }

    render() {

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let params = this.state;

        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(14585)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: window.innerWidth < 1200 ? '95%' : 1100 }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 18}>
                        <FormItem label={this.getText(19070)}>
                            <Select
                                style={{ width: 250 }}
                                value={this.state.centralType}
                                onChange={(e) => { this.setState({ centralType: e }) }}>
                                <Option value={1}>{this.getText(19072)}</Option>
                                <Option value={2}>{this.getText(19073)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(14588)}>
                            <GenericSelector
                                multi
                                id={TableIDs.genericSelectorBranches}
                                api={"get_branch_list"}
                                value={this.state.branchList ? this.state.branchList.split(',') : []}
                                onChange={(e) => { this.setState({ branchList: e ? e.join(',') : '' }) }}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                user={this.props.user}
                            />
                        </FormItem>
                        <Row>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14586, 'dateFrom')}</Col>
                            <Col span={mobile ? 24 : 12}>{this.createDatePicker(14587, 'dateTo')}</Col>
                        </Row>

                        {this.state.centralType == 1 ?
                            [
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.compDetaild == 1}
                                        onChange={(e) => { this.setState({ compDetaild: e.target.checked ? 1 : 0 }) }}
                                    >{this.getText(14589)}</Checkbox>
                                </FormItem>,
                                this.state.reportType == "xls" ?
                                    <FormItem label={this.getText(19915)}>
                                        <GenericSelector
                                            {...this.props}
                                            multi
                                            optionsGroup
                                            id={'PAY_ITEMS_LIST'}
                                            api={"get_pay_items_list"}
                                            value={this.state.payItemList ? this.state.payItemList.split(',') : []}
                                            onChange={(e) => { this.setState({ payItemList: e ? e.join(',') : '' }) }}
                                        />
                                    </FormItem>
                                    : "",
                                this.state.reportType == "xls" && this.state.payItemList.length ?
                                    <FormItem>
                                        <Radio.Group
                                            value={this.state.payItemsSumType}
                                            onChange={(e) => { this.setState({ payItemsSumType: e.target.value }) }}
                                        >
                                            <Radio value={"1"}>{this.getText(19916)}</Radio><br />
                                            <Radio value={"2"}>{this.getText(19917)}</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                    : "",
                                this.state.reportType == "xls" ?
                                    <FormItem label={this.getText(20030)}>
                                        <GenericSelector
                                            {...this.props}
                                            multi
                                            id={TableIDs.genericSelectorExternalOrderSuppliers}
                                            api={'external_orders_supplier_logo_list'}
                                            filterList={this.filterSuppliers.split(',')}
                                            value={this.state.orderSuppliers ? this.state.orderSuppliers.split(",") : []}
                                            onChange={(e) => { this.setState({ orderSuppliers: e?.join(",") }) }}
                                        />
                                    </FormItem>
                                    : "",
                            ]
                            : <FormItem label={this.getText(19071)}>
                                <GenericSelector
                                    multi
                                    id={TableIDs.genericSelectorPaymentTypes}
                                    api={"get_payment_types_list"}
                                    value={this.state.payTypeList ? this.state.payTypeList.split(',') : []}
                                    onChange={(e) => { this.setState({ payTypeList: e ? e.join(',') : '' }) }}
                                    data={this.props.data}
                                    dataActions={this.props.dataActions}
                                    user={this.props.user}
                                />
                            </FormItem>
                        }
                    </Col>
                    <Col span={mobile ? 24 : 6}>
                        <ReportTypeButton
                            {...this.props}
                            branchesField={'branchList'}
                            params={params}
                            datesToFavorites={[
                                { field: 'dateFrom', label: this.getText(14586) },
                                { field: 'dateTo', label: this.getText(14587) },
                            ]}
                            onChange={e => { this.setState({ reportType: e }) }}
                            favoriteSave
                            xlsReportName={"reports/PaymentsAnalysisExcel.xls"}
                            generateXlsxReport={params.centralType == 1 ? this.generateXlsxReport : null}
                            validSubmit={() => {
                                let df = new Date(this.state.dateFrom).getTime();
                                let dt = new Date(this.state.dateTo).getTime();
                                if (this.state.payItemList && (dt - df) > (31 * 24 * 60 * 60 * 1000)) message.error(this.getText(19918))
                                else return true
                            }}
                        />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }
}
export default PaymentsAnalysis;