/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import { Menu, Icon, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"


type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object,
};
type State = {};

class AppMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            // menuTag: (<div></div>),
            // menuItemsTop: [
            //     { KeyComp: "900", key: "dashboard", type: "dashboard", text: "חדר מצב" },
            //     { KeyComp: "901", key: "items", type: "shopping-cart", text: "פריטים" },
            //     { KeyComp: "902", key: "matrixItems", type: "table", text: "פריטי מטריצה" },
            //     { KeyComp: "903", key: "customers", type: "team", text: "לקוחות" },
            //     { KeyComp: "904", key: "employees", type: "contacts", text: "עובדים" },
            //     { KeyComp: "905", key: "documents", type: "form", text: "מסמכים" },
            //     { KeyComp: "906", key: "user-settings", type: "tool", text: "הגדרות משתמש" },
            //     { KeyComp: "907", key: "settings", type: "setting", text: "הגדרות מערכת" },
            //     { KeyComp: "908", key: "reports_inventory", type: "appstore", text: "מלאי" },
            //     { KeyComp: "909", key: "reports_sales", type: "calculator", text: "דוחות מכירות" },
            //     { KeyComp: "910", key: "reports_payments", type: "line-chart", text: "דוחות תקבולים" },
            //     { KeyComp: "911", key: "reports_items", type: "car", text: "דוחות פריטים" },
            //     { KeyComp: "912", key: "mailing", type: "mail", text: "דיוור" },
            //     { KeyComp: "913", key: "taxes", type: "audit", text: "מס הכנסה" },
            //     { KeyComp: "914", key: "admin", type: "poweroff", text: "ניהול" },
            // ].filter((route, index) => this.isActive(route.KeyComp)),
            // menuItemsDrop: [],
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    onMenuSelect(args) {
        this.myHistory.push(this.baseUrl + "/" + args.key, {});
    }

    getMenuItem = (key, icon, text, uiComponentsList) => {

        if (
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.filter(f => uiComponentsList.indexOf(f.code) > -1).length
            || key === 'user-favorites'
            || key === 'help-documentations'
        ) {
            return (<Menu.Item key={key}>
                <Icon type={icon} className="menu-icon" />
                <span>{this.getText(text)}</span>
            </Menu.Item>)
        }
    }

    render() {
        /*
        const nodeList = this.renderMenuItemsTop(menuList).map((obj) => {
            return (
                <Menu.Item key={obj.key}>
                    <Icon type={obj.type} className="menu-icon" />
                    <span>{obj.text}</span>
                </Menu.Item>
            )
        });*/
        /*
    return (
        <Menu theme="dark" mode="inline"
            defaultSelectedKeys={[this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[1] || "dashboard"]}
            onSelect={this.onMenuSelect.bind(this)}>
            { this.renderMenuItemsTop()}
        </Menu>
    )
    */

        return (
            <Menu theme="dark" mode="inline" style={{ paddingBottom: 50 }}
                selectedKeys={[this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[1] || "dashboard"]}
                defaultSelectedKeys={[this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[1] || "dashboard"]}
                onSelect={this.onMenuSelect.bind(this)}>
                {this.getMenuItem("dashboard", "dashboard", 10002, ["200"])}
                {this.getMenuItem("user-favorites", "tags", 16411, [])}
                {this.getMenuItem("items", "shopping-cart", 10003, ["31", "34", "32", "33", "35", "37", "112", "39", "40"])}
                {this.getMenuItem("matrixItems", "table", 10004, ["111", "112", "113"])}
                {this.getMenuItem("customers", "team", 10005, ["1", "3", "4", "5", "6", "8", "9", "10", "127", "121", "123", "125", "126", "128", "129", "130", "251", "252"])}
                {this.getMenuItem("crm", "like", 19932, ["271", "272"])}
                {this.getMenuItem("best-club", "file-protect", 17961, ["231", "232", "233", "234", "235", "236", "237", "238", "239", "240", "241"])}
                {this.getMenuItem("employees", "contacts", 10006, ["21", "22", "23", "24", "25", "26", "27", "28", "9999"])}
                {this.getMenuItem("documents", "form", 10007, ["11", "12", "13", "14", "15", "17", "18", "16", "19", "20", "201", "202", "203", "204", "205", "206"])}
                {this.getMenuItem("user-settings", "tool", 10008, ["132", "81", "83", "84", "85", "86", "87", "88", "89", "131", "133", "142", "134", "135", "136", "137", "138", "139", "140", "221", "222", "223", "224", "225"])}
                {this.getMenuItem("settings", "setting", 10009, ["91", "82", "93", "94", "95", "96", "98", "92", "144", "145", "147", "148", "150", "182", "183", "184", "185", "186", "187", "188"])}
                {this.getMenuItem("reports_inventory", "appstore", 10010, ["45", "41", "42", "43", "44", "46", "47"])}
                {this.getMenuItem("reports_sales", "calculator", 10011, ["51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "171", "172", "173", "174", "175"])}
                {this.getMenuItem("reports_payments", "line-chart", 10012, ["61", "62", "63", "64", "65", "66", "67", "69", "70", "161", "162", "163", "164", "165"])}
                {this.getMenuItem("reports_items", "car", 10013, ["71", "73"])}
                {this.getMenuItem("mailing", "mail", 10014, ["101", "102", "103", "104", "105", "106", "107", "108"])}
                {this.getMenuItem("taxes", "audit", 10015, ["122", "151", "152", "153", "154", "155", "156", "157", "158", "159", "160", "261", "262", "263", "264"])}
                {this.getMenuItem("admin", "poweroff", 15218, ["191", "192", "97", "99", "181", "149", "141", "143", "146", "193", "194", "195", "196", "197", "198", "199", "211", "212"])}
                {this.getMenuItem("help-documentations", "global", 16456, [])}
            </Menu>
        );
    }

    isActive = (key) => {
        return true;
        //return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
        //    .map(obj => obj.code).includes(key);
    };

    // renderMenuItemsTop() {
    //     let res = [];
    //     let list = this.state.menuItemsTop;
    //     if (list.length > 0) {
    //         for (let item of list) {
    //             res.push(
    //                 <Menu.Item key={item.key}>
    //                     <Icon type={item.icon} className="menu-icon" />
    //                     <span>{item.text}</span>
    //                 </Menu.Item>);
    //         }
    //         return res;
    //     }
    // }
}

export default withRouter(AppMenu);




/*

return (
    <Menu theme="dark" mode="inline"
          defaultSelectedKeys={[this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[1] || "dashboard"]}
          onSelect={this.onMenuSelect.bind(this)}>
        <Menu.Item key="dashboard">
            <Icon type="dashboard" className="menu-icon"/>
            <span>חדר מצב</span>
        </Menu.Item>
        <Menu.Item key="items">
            <Icon type="shopping-cart" className="menu-icon"/>
            <span>פריטים</span>
        </Menu.Item>
        <Menu.Item key="matrixItems">
            <Icon type="table" className="menu-icon"/>
            <span>פריטי מטריצה</span>
        </Menu.Item>
        <Menu.Item key="customers">
            <Icon type="team" className="menu-icon"/>
            <span>לקוחות</span>
        </Menu.Item>
        <Menu.Item key="employees">
            <Icon type="contacts" className="menu-icon"/>
            <span>עובדים</span>
        </Menu.Item>
        <Menu.Item key="documents">
            <Icon type="form" className="menu-icon"/>
            <span>מסמכים</span>
        </Menu.Item>
        <Menu.Item key="user-settings">
            <Icon type="tool" className="menu-icon"/>
            <span>הגדרות משתמש</span>
        </Menu.Item>
        <Menu.Item key="settings">
            <Icon type="setting" className="menu-icon"/>
            <span>הגדרות מערכת</span>
        </Menu.Item>
        <Menu.Item key="reports_inventory">
            <Icon type="appstore" className="menu-icon"/>
            <span>מלאי</span>
        </Menu.Item>
        <Menu.Item key="reports_sales">
            <Icon type="calculator" className="menu-icon"/>
            <span>דוחות מכירות</span>
        </Menu.Item>
        <Menu.Item key="reports_payments">
            <Icon type="line-chart" className="menu-icon"/>
            <span>דוחות תקבולים</span>
        </Menu.Item>
        <Menu.Item key="reports_items">
            <Icon type="car" className="menu-icon"/>
            <span>דוחות פריטים</span>
        </Menu.Item>
        <Menu.Item key="mailing">
            <Icon type="mail" className="menu-icon"/>
            <span>דיוור</span>
        </Menu.Item>

    </Menu>
);
*/