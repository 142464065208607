
import React from "react";
import {
    Input, Card, Button, Modal, Select, Form, Checkbox, message, Switch, Row, Col
} from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import FormItem from "antd/lib/form/FormItem";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import GenericSelector from "./GenericSelector";
import DateTimePicker from "./DateTimePicker";

const { Option, OptGroup } = Select;

//type State = {}
class ModalCreateEditUsers extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            rPassword1: '',
            rPassword2: '',
            isLoadData: false,
        }

        this.id = "MODAL_CREATE_EDIT_USERS";

        this.api = {
            get: "get_users_single_data",
            create_edit: "create_edit_users",
            hash: "set_cloud_user",
        }

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.selectors = [
            { id: "POS_LIST_PER_FRANCHISEES", api: "get_pos_list_per_franchisees" },
            { id: "STORAGE_LIST_PER_FRANCHISEES", api: "get_storage_list_per_franchisees" },
            { id: TableIDs.genericSelectorUIComponents, api: "get_ui_component_list" },
            { id: TableIDs.genericSelectorFranchisees, api: "franchisees_list" },
            { id: "EMPLOYEE_LIST_PER_STORAGES", api: "get_employee_list_per_storages" },
            { id: "SELECTOR_TAG_TYPE_9", api: "get_tags_from_type_9" },
        ];

        this.requiredField = [
            "rName",
            "rDisplayName",
            // "rUsersPosDefault", 
            "rUsersStoragesDefault",
            "rUsersComponents",
        ]

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        // this.selectors.map((x) => {
        //     this.props.ActionQueue.addToQueue({
        //         action: this.props.dataActions.genericSelectorRefreshDataset,
        //         args: [x.id, this.props.user.companyCode, this.props.user.token, x.script]
        //     });
        // });
    }

    initFilters = () => {
        this.setState({ isLoadData: true }, () => {
            let request = { rID: this.props.ui.data ? this.props.ui.data['rID'] : 0 }
            this.props.dataActions.genericDataGetEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
            this.setState({ rPassword1: '', rPassword2: '' });
        })

        setTimeout(() => {
            this.setState({ isLoadData: false })
        }, 5000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.initFilters();
        }

        const { editedRecord } = this.props.data[this.id];
        const prevEditedRecord = prevProps.data[this.id].editedRecord;

        let uf = editedRecord ? editedRecord.rUserFranchisees : '';
        let puf = prevEditedRecord ? prevEditedRecord.rUserFranchisees : '';
        let sf = editedRecord ? editedRecord.rUsersStorages : '';
        let psf = prevEditedRecord ? prevEditedRecord.rUsersStorages : '';

        if (puf !== uf) this.apiSelectorsPerFranchisees(uf);
        if (psf !== sf) this.apiSelectorsPerStorages(sf);
    }

    dataObject = (id) => {
        let headers = this.props.data[id].headers;
        return this.props.data[id].data.map((x, indexData) => {
            let y = x.split("\f");
            let ob = { key: y[0], index: indexData }
            headers.map((z, index) => ob = { ...ob, [z]: y[index] });
            return ob;
        });
    }

    handleOk = () => {
        let rData = this.props.data[this.id].editedRecord;
        let remainsCheck = (f) => rData[f] === this.dataObject(this.id)[0][f] ? rData[f] = "" : '';
        if (this.props.ui.data && this.props.ui.data['rCopy']) {
            remainsCheck("rName")
            remainsCheck("rDisplayName")
            rData = { ...rData, rID: 0 }
        }
        let notRequired = () => {
            let res = false;
            this.requiredField.map((x) => {
                if (!rData[x]) res = true;
            });
            let s = this.state;
            let ui = this.props.ui.data;
            if ((ui['rCopy'] || ui['rID'] < 1) && (s.rPassword1 === "" || s.rPassword2 === "")) res = true;
            return res;
        }

        if (notRequired()) {
            message.error(this.getText(11787))
        } else if (this.passCheck()) {

            let saveData = (hash) => {
                rData = { ...rData, rPassword: hash }

                this.props.dataActions.genericDataJustSaveEditWithParams(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    this.api.create_edit,
                    rData);

                this.props.dataActions.genericDataGetRequested(
                    "USERS_TABLE",
                    this.props.user.companyCode,
                    this.props.user.token,
                    "get_users_table");

                this.handleCancel();
                /////console.log('yuda aaaaa ', hash)
            }

            if (this.props.ui.data['rID'] == 0 || this.props.ui.data['rCopy']) {

                let dataSend = "uName\fuPSW\r" + rData['rName'] + "\f" + this.state.rPassword1;
                this.checksIfUserNotExist(() => this.sendAPI(this.api.hash, dataSend, (ob) => { saveData(ob.data) }));
            } else {
                saveData('');
            }
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditUsers);
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
    }

    passCheck = () => {
        let p1 = this.state.rPassword1;
        let p2 = this.state.rPassword2;
        if (p1 !== "" && p2 !== "" && p1 !== p2) message.error(this.getText(11788))
        else return true
    }

    checksIfUserNotExist = (fNext) => {
        let callbeck = (ob) => {
            if (parseInt(ob.data) > 0) {
                message.error(this.getText(11789))
                this.props.dataActions.genericDataSetEdit(this.id, { ['rName']: '' });
            } else {
                fNext();
            }
        }
        let name = this.props.data[this.id].editedRecord['rName'];
        this.sendAPI("checks_if_user_not_exist", name, callbeck)
    }

    apiSelectorsPerFranchisees = (franchisees) => {
        const posSelector = this.selectors[0];
        const storageSelector = this.selectors[1];

        const { rUsersPosDefault, rUsersStoragesDefault, rUsersPosPermission } = this.props.data[this.id].editedRecord;

        this.sendAPI(posSelector.api, "franchisees\r" + franchisees, (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { code: y[0], name: y[1], isBO: y[2] }
            }) : [];
            let datasetList = dataset.map(x => x.code);
            let defIsset = datasetList.indexOf(rUsersPosDefault) > -1;
            this.props.dataActions.setJson(posSelector.id, { dataset })
            if (!this.state.isLoadData) {
                this.props.dataActions.genericDataSetEdit(this.id, {
                    rUsersPos: datasetList.join(','),
                    rUsersPosDefault: defIsset ? rUsersPosDefault : null,
                    rUsersPosPermission: rUsersPosPermission ? rUsersPosPermission.split(",").filter(f => datasetList.indexOf(f) > -1).join(",") : ""
                })
            }
        })

        this.sendAPI(storageSelector.api, "franchisees\r" + franchisees, (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { code: y[0], name: y[1] }
            }) : [];
            let datasetList = dataset.map(x => x.code);
            let defIsset = datasetList.indexOf(rUsersStoragesDefault) > -1;
            this.props.dataActions.setJson(storageSelector.id, { dataset })
            if (!this.state.isLoadData) {
                this.props.dataActions.genericDataSetEdit(this.id, { rUsersStorages: datasetList.join(','), rUsersStoragesDefault: defIsset ? rUsersStoragesDefault : null })
            }
        })

        // this.props.dataActions.genericSelectorRefreshDataset(storageSelector.id, this.props.user.companyCode, this.props.user.token, storageSelector.api, { franchisees });
    }

    apiSelectorsPerStorages = (storages) => {
        const { id, api } = this.selectors[4];
        this.props.dataActions.genericSelectorRefreshDataset(id, this.props.user.companyCode, this.props.user.token, api, { storages });
    }

    getSelector = (index, label, field, maxTagCount, red) => {
        const { editedRecord } = this.props.data[this.id];
        let value = editedRecord && editedRecord[field] ? editedRecord[field].split(',') : [];
        let onChange = v => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: v.join(',') }) }

        return (<FormItem label={red ? (<span><span style={{ color: 'red' }}> * </span>{label}</span>) : label}>
            <GenericSelector {...this.props} {...this.selectors[index]} multi {...{ value, onChange, maxTagCount }} />
        </FormItem>)
    }

    getSelectorPosWithDefault = () => {
        const { editedRecord } = this.props.data[this.id];
        let id = this.selectors[0].id;
        let value1 = editedRecord && editedRecord.rUsersPos ? editedRecord.rUsersPos.split(',') : [];
        let value2 = editedRecord && editedRecord.rUsersPosDefault ? editedRecord.rUsersPosDefault : '';
        let value3 = editedRecord && editedRecord.rUsersPosPermission ? editedRecord.rUsersPosPermission.split(',') : [];

        return (<Row>
            <Col span={window.innerWidth > 600 ? 10 : 24}>
                <FormItem label={this.getText(11810)}>
                    <GenericSelector {...this.props} id={id} multi value={value1} onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, {
                            rUsersPos: value.join(','),
                            rUsersPosDefault: value && value2 && value.indexOf(value2) > -1 ? value2 : null,
                            rUsersPosPermission: value && value3.lenmgth ? value3.filter(f => value.indexOf(f) > -1).join(',') : ''
                        })
                    }} />
                </FormItem>
            </Col>
            <Col span={window.innerWidth > 600 ? 7 : 24}>
                <FormItem label={this.getText(11811)}>
                    <GenericSelector {...this.props} id={id} filterList={value1.filter(f => this.props.data[id].dataset.find(ff => ff.code === f && ff.isBO == '1'))} value={value2} onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { rUsersPosDefault: value })
                    }} />
                </FormItem>
            </Col>
            <Col span={window.innerWidth > 600 ? 7 : 24}>
                <FormItem label={this.getText(18275)}>
                    <GenericSelector {...this.props} id={id} multi filterList={value1} value={value3} onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { rUsersPosPermission: value.join(',') })
                    }} />
                </FormItem>
            </Col>
        </Row>)
    }

    getSelectorStorageWithDefault = () => {
        const { editedRecord } = this.props.data[this.id];
        let id = this.selectors[1].id;
        let value1 = editedRecord && editedRecord.rUsersStorages ? editedRecord.rUsersStorages.split(',') : [];
        let value2 = editedRecord && editedRecord.rUsersStoragesDefault ? editedRecord.rUsersStoragesDefault : '';

        return (<Row>
            <Col span={window.innerWidth > 600 ? 14 : 24}>
                <FormItem label={this.getText(11812)}>
                    <GenericSelector {...this.props} id={id} multi value={value1} onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { rUsersStorages: value.join(','), rUsersStoragesDefault: value && value2 && value.indexOf(value2) > -1 ? value2 : null })
                    }} />
                </FormItem>
            </Col>
            <Col span={window.innerWidth > 600 ? 10 : 24}>
                <FormItem label={<span>{this.getText(11813)}<span style={{ color: 'red' }}> * </span></span>}>
                    <GenericSelector {...this.props} id={id} filterList={value1} value={value2} onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { rUsersStoragesDefault: value })
                    }} />
                </FormItem>
            </Col>
        </Row>)
    }

    render() {

        const { loading } = this.state;

        let userId = this.props.ui.data ? this.props.ui.data['rID'] : 0;
        let userDisplayName = this.dataObject(this.id)[0] ? this.dataObject(this.id)[0]['rDisplayName'] : '';
        let isCopy = this.props.ui.data ? this.props.ui.data['rCopy'] : false;
        let isNew = userId < 1 || isCopy;

        let titleName = this.getText(11790) + " ";
        titleName += (!isNew) ? this.getText(11791) + ' ' + userId + ' - ' + userDisplayName : this.getText(11792);

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={titleName}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let cardStyle = { backgroundColor: '#dddddd', margin: 5 }

        let cStyle = (w) => { return { width: w + '%', padding: 2, display: 'inline-block' } }

        let editedRecord = this.props.data[this.id].editedRecord;

        let updateIn = (f, v) => this.props.dataActions.genericDataSetEdit(this.id, { [f]: v });

        let redS = (l) => (<span>{l}<span style={{ color: 'red' }}> * </span></span>)

        let tInput = (l, f, red, onBlur, disabled) => {
            let p = this.dataObject(this.id)[0] ? this.dataObject(this.id)[0][f] : '';

            return (<FormItem label={red ? redS(this.getText(l)) : this.getText(l)}>
                <Input
                    value={(isCopy && editedRecord[f] === p) ? "" : editedRecord[f]}
                    onChange={(v) => { updateIn(f, v.target.value) }}
                    onBlur={onBlur} disabled={disabled} />
            </FormItem>)
        }

        let pInput = (l, s) => (<FormItem label={redS(l)}><Input.Password value={this.state[s]} onChange={(v) => this.setState({ [s]: v.target.value })} onBlur={this.passCheck} /></FormItem>)

        let gSwitch = (l, f) => {
            return (<div>
                <span style={cStyle(70)}>{l}:</span>
                <span style={cStyle(30)}>
                    <Switch checkedChildren={this.getText(11793)} unCheckedChildren={this.getText(11794)}
                        onChange={(v) => this.props.dataActions.genericDataSetEdit(this.id, { [f]: v ? 1 : 0 })}
                        checked={(editedRecord[f] == 1) ? true : false} />
                </span>
            </div>)
        }

        const validateUserName = () => {
            let n = editedRecord.rName;
            let reg = new RegExp(/^[a-zA-Zא-ת0-9]+$/);
            if (reg.test(n)) {
                this.checksIfUserNotExist(() => {
                    this.props.dataActions.genericDataSetEdit(this.id, { rDisplayName: n });
                })
            } else if (n) {
                message.error(this.getText(17530))
                this.props.dataActions.genericDataSetEdit(this.id, { rName: '', rDisplayName: n });
            }
        }

        let isUserTagsView = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '528')


        return (
            <Modal
                destroyOnClose={true} width={'95%'} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11795)}</Button>,
                    <Button key="clear" loading={loading} onClick={this.initFilters}>{this.getText(11796)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11797)}</Button>,
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >
                <Form //id="search_working_hours_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: '1' }}
                //onSubmit={this.handleSearch}
                >
                    <Row>
                        <Col span={window.innerWidth > 600 ? isNew ? 6 : 8 : 24}>
                            <Card style={cardStyle} title={this.getText(11798)}>
                                {tInput(11803, "rName", true, validateUserName, !isNew)}
                                {tInput(11804, "rDisplayName", true)}
                                {tInput(14078, "rPrivatePhone", false)}
                                {tInput(16953, "rPrivateEmail", false)}
                                <FormItem label={this.getText(14079)}>
                                    <Select key={"rTwoFactor"} value={editedRecord['rTwoFactor']} onChange={(v) => { updateIn("rTwoFactor", v) }}>
                                        <Option key="0" value={"0"}>{this.getText(14080)}</Option>
                                        <Option key="1" value={"1"}>{this.getText(14081)}</Option>
                                        <Option key="2" value={"2"}>{this.getText(14082)}</Option>
                                    </Select>
                                </FormItem>
                                <FormItem label={this.getText(18447)}>
                                    <DateTimePicker
                                        format="DD/MM/YYYY"
                                        value={editedRecord['rPasswordExpirationDate']}
                                        onChange={(v) => { updateIn("rPasswordExpirationDate", v) }}
                                    />
                                </FormItem>
                                {gSwitch(this.getText(11805), "rIsSuper")}
                                {gSwitch(this.getText(11806), "rIsActive")}
                                {gSwitch(this.getText(11807), "rCanUpdateUsers")}
                            </Card>
                        </Col>
                        {isNew ? (
                            <Col span={window.innerWidth > 600 ? 6 : 24}>
                                <Card style={cardStyle} title={this.getText(11799)}>
                                    {pInput(this.getText(11808), "rPassword1")}
                                    {pInput(this.getText(11809), "rPassword2")}
                                </Card>
                            </Col>
                        ) : ""}
                        <Col span={window.innerWidth > 600 ? isNew ? 12 : 16 : 24}>
                            <Card style={cardStyle} title={this.getText(17165)}>
                                {this.getSelector(2, this.getText(11814), 'rUsersComponents', 10, true)}
                                {this.getSelector(3, this.getText(14274), 'rUserFranchisees')}
                                {this.getSelectorPosWithDefault()}
                                {this.getSelectorStorageWithDefault()}
                                <FormItem label={this.getText(17166)}>
                                    <GenericSelector {...this.props}
                                        id={this.selectors[4].id}
                                        value={editedRecord.rEmployee}
                                        onChange={v => { this.props.dataActions.genericDataSetEdit(this.id, { rEmployee: v }) }} />
                                </FormItem>

                                {isUserTagsView ?
                                    <FormItem label={this.getText(19931)}>
                                        <GenericSelector {...this.props} {...this.selectors[5]} multi
                                            value={editedRecord.rTagList ? editedRecord.rTagList.split(',') : []}
                                            onChange={v => { this.props.dataActions.genericDataSetEdit(this.id, { rTagList: v ? v.join(',') : "" }) }} />
                                    </FormItem>
                                    : ""}
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Modal>)
    }
}

export default ModalCreateEditUsers;
