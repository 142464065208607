/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Form, Select, Checkbox, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker, TimePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import StockTransferStorages from "../../components/StockTransferStorages";
import ReportTypeButton from "../../components/ReportTypeButton";

import "../../style/generalUI.css";
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class StockItemBalanceValue extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            type: "pdf",
            StockItemBalanceValue: {
                //mRequestedReport: 0,
                mLowerSelectionDate: null,
                mLowerSelectionTime: "00:00:00",
                mUpperSelectionDate: null,
                mUpperSelectionTime: "23:59:59",
                mApplyRangeApplyItemCode: 0,
                mApplyRangeLowerItemCode: '',
                mApplyRangeUpperItemCode: '',
                mApplyRangeApplyItemName: 0,
                mApplyRangeLowerItemName: '',
                mApplyRangeUpperItemName: '',
                mApplyRangeApplySupplierCode: 0,
                mApplyRangeLowerSupplierCode: '',
                mApplyRangeUpperSupplierCode: '',
                mApplyRangeApplySupplierName: 0,
                mApplyRangeLowerSupplierName: '',
                mApplyRangeUpperSupplierName: '',
                mPosList: '',
                mEmployeeList: '',
                mStorageList: '', // compatibility
                mGroupList: '',
                mSupplierList: '',
                mTagList: '',
                mTagTypeId: '',
                //mMinimalPercentage: 0,
                mIsActive: '-1',
                mShowZeroBalanceItems: '0',
                mTagRelation: '1', // bool
                mSortField: 'code',
                mCalculateBalanceSeparateType: '0',
                mCalculateBalanceWithPrices: null,
                isByPriceList: '0',
                isByFatherItem: '0',
                isCentral: '0',

                mIncludeDeleteItems: '0',
                mIncludeNotInvItems: '0',
                mViewBarcode2: '0',
                mCalculateCostMethod: "2",

                reportName: "reports/stockItemBalanceValue.pdf"
            }
        };

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorPrices] === undefined) {
            props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            selector: "get_prices",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params, "mPosList", "mStorageList");
            let splitName = params.reportName.split('.');
            if (splitName[1] == 'xls') {
                params = { ...params, reportName: splitName[0] + 'Excel.xls' }
            }
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params, posField, storagesField) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params[posField] === '') params = { ...params, [posField]: fullPosList }
        if (params[storagesField] === '') params = { ...params, [storagesField]: fullStoragesList }
        // //console.log('yuda report ', params)
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10185);
        if (this.props.data[TableIDs.genericSelectorPos].dataset[0] &&
            this.props.data[TableIDs.genericSelectorStorages].dataset[0] &&
            !this.state.loading)
            this.setState({ loading: true })

        let pricesData = this.props.data[TableIDs.genericSelectorPrices].dataset;
        let inState = this.state.StockItemBalanceValue.mCalculateBalanceWithPrices;
        if (pricesData && pricesData[0]
            && pricesData[0].code
            && !inState) {
            this.setReportParameters('StockItemBalanceValue', { mCalculateBalanceWithPrices: pricesData[0].code })
        }


        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("StockItemBalanceValue", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        // });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.StockItemBalanceValue.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.StockItemBalanceValue.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("StockItemBalanceValue", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.setReportParameters("StockItemBalanceValue", {
            mUpperSelectionDate: value ? value.format("YYYY-MM-DD") : null,
            mUpperSelectionTime: value ? value.format("HH:mm:ss") : null,
        })
        // this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('mUpperSelectionTime', value ? value.format("HH:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("StockItemBalanceValue", { [field]: data });
                else
                    this.setReportParameters("StockItemBalanceValue", { [field]: '' });
            }} />);
    }

    createRadioButton = (label, field, list, visible) => {
        return visible ?
            (<FormItem label={this.getText(label)}>
                <Radio.Group
                    key={field}
                    value={this.state.StockItemBalanceValue[field]}
                    onChange={(value) => {
                        this.setReportParameters("StockItemBalanceValue", { [field]: value.target.value })
                    }}>
                    {list.map(x => (<Radio value={x.id}>{this.getText(x.text)}</Radio>))}
                </Radio.Group>
            </FormItem>)
            : ""
    }

    render() {
        const { StockItemBalanceValue, endOpen } = this.state;
        const { mLowerSelectionDate, mLowerSelectionTime, mUpperSelectionDate, mUpperSelectionTime } = StockItemBalanceValue;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        ////console.log('yuda jasper ', this.state.StockItemBalanceValue);

        //const { StockItemBalanceValue } = this.state;
        //const isMinPercShownStyle = { display: (StockItemBalanceValue.mRequestedReport === 5 || StockItemBalanceValue.mRequestedReport === 6) ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        //let listItemTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []).map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        //});

        let listItemTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.id + item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        // let listItemTagTypes = Jax.get(this.props.data, TableIDs.genericSelectorItemTagTypes + ".dataset", []).map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        // });
        let listItemGroup = Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listSuppliers = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        });

        let rMobile = window.innerWidth <= 600 ? true : false;

        let mToDate = mUpperSelectionDate && mUpperSelectionTime ? mUpperSelectionDate + ' ' + mUpperSelectionTime : null

        let isUserCalcFifo = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset &&
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '517')



        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(11908)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: rMobile ? '95%' : '80%' }}
                    //headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: -8, height: 66 }}>
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: rMobile ? '1' : '3' }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(16584)} style={{ marginTop: rMobile ? 50 : 0 }}>
                            <Select key={"mCalculateBalanceSeparateType"} style={{ width: 220 }}
                                value={this.state.StockItemBalanceValue.mCalculateBalanceSeparateType}
                                onChange={(value) => {
                                    this.setReportParameters("StockItemBalanceValue", { mCalculateBalanceSeparateType: value, mTagTypeId: '' });
                                }}>
                                <Option key={'0'} value={'0'}> - </Option>
                                <Option key={'1'} value={'1'}>{this.getText(16585)}</Option>
                                <Option key={'2'} value={'2'}>{this.getText(16586)}</Option>
                                <Option key={'3'} value={'3'}>{this.getText(16587)}</Option>
                            </Select>
                        </FormItem>

                        {this.state.StockItemBalanceValue.mCalculateBalanceSeparateType != '0' ? (
                            <FormItem>
                                <Checkbox
                                    checked={this.state.StockItemBalanceValue.isCentral == 1}
                                    onChange={e => {
                                        this.setReportParameters("StockItemBalanceValue", {
                                            isCentral: e.target.checked ? 1 : 0
                                        });
                                    }}
                                >{this.getText(16606)}</Checkbox>
                            </FormItem>
                        ) : ""}

                        {this.state.StockItemBalanceValue.mCalculateBalanceSeparateType == '2' ?
                            (<FormItem label={this.getText(17069)}>
                                <GenericSelector
                                    {...this.props}
                                    id={TableIDs.genericSelectorItemTagTypes}
                                    api={this.api.selector_item_tagTypes}
                                    optionsGroup
                                    value={this.state.StockItemBalanceValue.mTagTypeId}
                                    onChange={(value) => {
                                        this.setReportParameters("StockItemBalanceValue", { mTagTypeId: value });
                                    }}
                                    groupFilterList={['ItemTags']}
                                    addFirstRow={{ id: '0', text: ' - ' }} />
                            </FormItem>)
                            : ""}

                        <StockTransferStorages
                            {...this.props}
                            fieldName="mStorageList"
                            onChange={(value) => this.setReportParameters("StockItemBalanceValue", { mStorageList: value.join(',') })}
                            value={(this.state.StockItemBalanceValue.mStorageList === '') ? [] : this.state.StockItemBalanceValue.mStorageList.split(',')}
                        />

                        {/* <FormItem label={this.getText(11910)}>
                            <Switch checkedChildren={this.getText(11926)} unCheckedChildren={this.getText(11927)}
                                onChange={(e) => {
                                    this.setReportParameters("StockItemBalanceValue", { isByFatherItem: (e) ? 1 : 0 });
                                }}
                                checked={(this.state.StockItemBalanceValue.isByFatherItem == 1) ? true : false} />
                        </FormItem> */}
                        {/* <FormItem>
                            <Checkbox
                                onChange={(e) => {
                                    this.setReportParameters("StockItemBalanceValue", { isByFatherItem: (e.target.checked) ? 1 : 0 });
                                }}
                                checked={(this.state.StockItemBalanceValue.isByFatherItem == 1) ? true : false}
                            >{this.getText(17288)}</Checkbox>
                        </FormItem> */}
                        {this.createRadioButton(17288, "isByFatherItem", [{ id: '0', text: 11924 }, { id: '1', text: 11925 }], true)}

                        <FormItem label={this.getText(11918)}>
                            <Select key={"mSortField"} style={{ width: 220 }}
                                value={this.state.StockItemBalanceValue.mSortField}
                                onChange={(value) => { this.setReportParameters("StockItemBalanceValue", { mSortField: value }); }}
                            >
                                <Option key={1} value={'code'}>{this.getText(11933)}</Option>
                                <Option key={2} value={'name'}>{this.getText(11934)}</Option>
                            </Select>
                        </FormItem>




                        <FormItem label={this.getText(/*11911*/11928)}>
                            {/* <Switch checkedChildren={this.getText(11928)} unCheckedChildren={this.getText(11929)}
                                onChange={(e) => {
                                    this.setReportParameters("StockItemBalanceValue", { isByPriceList: (e) ? 1 : 0 });
                                }}
                                checked={(this.state.StockItemBalanceValue.isByPriceList === 1) ? true : false}
                                style={{ marginLeft: 4 }}
                            /> */}

                            <Select
                                key={"mCalculateBalanceWithPrices"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //disabled={!(this.props.data[this.id].editing === record.key)}
                                // className={(this.state.StockItemBalanceValue.isByPriceList === 1) ? "" : "isHidden"}
                                // defaultValue={'1'}
                                //defaultValue={(this.props.data[TableIDs.genericSelectorPrices].dataset[0] === undefined)
                                //    ? "1" : this.props.data[TableIDs.genericSelectorPrices].dataset[0].code}
                                value={this.state.StockItemBalanceValue.mCalculateBalanceWithPrices}
                                onChange={
                                    (value) => {
                                        // console.log("with price pick: ", value);
                                        // let byStorageStr =
                                        //     (this.state.StockItemBalanceValue.mCalculateBalanceSeparateType == 1)
                                        //         ? 'ByStorage' : '';
                                        this.setReportParameters("StockItemBalanceValue",
                                            {
                                                mCalculateBalanceWithPrices: value, //(value === '') ? 0 : 1,
                                                // reportName: "reports/stockItemBalanceValue" + byStorageStr + "." + this.state.type
                                            }
                                        );
                                    }
                                }
                            >
                                {list}
                            </Select>
                        </FormItem>


                        {this.createRadioButton(11916, "mIsActive", [{ id: '-1', text: 18427 }, { id: '1', text: 18428 }, { id: '0', text: 18429 }], true)}
                        {this.createRadioButton(19484, "mShowZeroBalanceItems", [{ id: '1', text: 19481 }, { id: '0', text: 19482 }, { id: '-1', text: 19483 }], true)}
                        {this.createRadioButton(18420, "mIncludeDeleteItems", [{ id: '0', text: 11900 }, { id: '1', text: 11901 }], true)}
                        {this.createRadioButton(18421, "mIncludeNotInvItems", [{ id: '0', text: 11900 }, { id: '1', text: 11901 }], true)}
                        {this.createRadioButton(18422, "mViewBarcode2", [{ id: '0', text: 18423 }, { id: '1', text: 18424 }],
                            this.state.StockItemBalanceValue.isByFatherItem != 1 && this.state.StockItemBalanceValue.isCentral != 1)}


                        <FormItem label={this.getText(11912)} /*style={isEndDateShownStyle}*/>
                            <DatePicker
                                disabledDate={this.disabledEndDate}
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                value={mUpperSelectionDate ? moment(mUpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(11930)}
                                onChange={(value) => {
                                    this.setReportParameters("StockItemBalanceValue", {
                                        mUpperSelectionDate: value ? value.format("YYYY-MM-DD") : null,
                                    })
                                }}
                            />
                            <TimePicker
                                format="HH:mm:ss"
                                value={mUpperSelectionTime ? moment(mUpperSelectionTime, "HH:mm:ss") : null}
                                onChange={(value) => {
                                    this.setReportParameters("StockItemBalanceValue", {
                                        mUpperSelectionTime: value ? value.format("HH:mm:ss") : null,
                                    })
                                }}
                            />
                        </FormItem>


                        <FormItem label={this.getText(11914)} style={{ display: this.state.StockItemBalanceValue.mTagTypeId > 0 ? "none" : "block" }}>
                            {/* {
                                this.createCheckBox('mTagList', Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", [])
                                    .reduce((acc, i) => {
                                        //console.log("acc: ", acc); 
                                        return acc.concat((i.tags.map(j => j.id)));
                                    }, [])
                                    .join(',')
                                )
                            }
                            <Select key={"mTagList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("StockItemBalanceValue", { mTagList: value.join(',') })}
                                value={(this.state.StockItemBalanceValue.mTagList === '') ? [] : this.state.StockItemBalanceValue.mTagList.split(',')}
                            >
                                {listItemTags}
                            </Select> 
                            <Switch checkedChildren={this.getText(11931)} unCheckedChildren={this.getText(11932)} 
                            <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("StockItemBalanceValue", { mTagList: value.join(',') })}
                                value={(this.state.StockItemBalanceValue.mTagList === '') ? [] : this.state.StockItemBalanceValue.mTagList.split(',')}
                            />
                            <TagRelationRadio showed={this.state.StockItemBalanceValue.mTagList}
                                checked={this.state.StockItemBalanceValue.mTagRelation == 1}
                                onChange={(value) => this.setReportParameters("StockItemBalanceValue", { mTagRelation: (value === true) ? 1 : 0 })}
                            //checked={}
                            />*/}
                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.StockItemBalanceValue.mTagList}
                                onChange={(value) => { this.setReportParameters("StockItemBalanceValue", { mTagList: value }) }}
                                tagRelationValue={this.state.StockItemBalanceValue.mTagRelation}
                                tagRelationOnChange={(value) => {
                                    let mTagList = this.state.StockItemBalanceValue.mTagList;
                                    let from = this.state.StockItemBalanceValue.mTagRelation;
                                    let to = value.target.value;
                                    let oldJson = mTagList.indexOf("{") > -1 ? JSON.parse(mTagList) : {}
                                    if (from == "2") mTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") mTagList = JSON.stringify({ tagList1: mTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("StockItemBalanceValue", { mTagRelation: to, mTagList })
                                }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(11915)}>
                            <GenericSelectorSuppliers  {...this.props} multi notApi
                                onChange={(value) => this.setReportParameters("StockItemBalanceValue", { mSupplierList: value.join(',') })}
                                value={(this.state.StockItemBalanceValue.mSupplierList === '') ? [] : this.state.StockItemBalanceValue.mSupplierList.split(',')}
                            />
                        </FormItem>

                        {/* <FormItem label={this.getText(11916)}>
                            <Select key={"mIsActive"} style={{ width: 220 }}
                                value={this.state.StockItemBalanceValue.mIsActive}
                                onChange={(value) => { this.setReportParameters("StockItemBalanceValue", { mIsActive: value }); }}
                            >
                                <Option key={1} value={'-1'}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(11925)}</Option>
                                <Option key={3} value={'0'}>{this.getText(11924)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(11917)}>
                            <Select key={"mShowZeroBalanceItems"} style={{ width: 220 }}
                                value={this.state.StockItemBalanceValue.mShowZeroBalanceItems}
                                onChange={(value) => { this.setReportParameters("StockItemBalanceValue", { mShowZeroBalanceItems: value }); }}
                            >
                                <Option key={2} value={'1'}>{this.getText(11925)}</Option>
                                <Option key={3} value={'0'}>{this.getText(11924)}</Option>
                            </Select>
                        </FormItem> */}

                        <FormItem label={this.getText(18842)}>
                            <Select key={"mCalculateCostMethod"} style={{ width: 250 }}
                                value={this.state.StockItemBalanceValue.mCalculateCostMethod}
                                onChange={(value) => { this.setReportParameters("StockItemBalanceValue", { mCalculateCostMethod: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'1'}>{this.getText(18843)}</Option>
                                <Option key={2} value={'2'}>{this.getText(18844)}</Option>
                                {isUserCalcFifo ? <Option key={3} value={'3'}>{this.getText(18845)}</Option> : ""}
                                {isUserCalcFifo ? <Option key={4} value={'4'}>{this.getText(18846)}</Option> : ""}
                            </Select>

                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.StockItemBalanceValue}
                            storagesField={'mStorageList'}
                            // tagsField={'mTagList'}
                            xlsReportName={"reports/stockItemBalanceValueExcel.xls"}
                            datesToFavorites={[{ field: 'mUpperSelectionDate', label: this.getText(11912) }]}
                            encodeURIFields={this.state.StockItemBalanceValue.mTagRelation == '2' ? ['mTagList'] : null}
                            favoriteSave
                        />
                    </Form>
                </Card>
            </div>
        );
    }
}
export default StockItemBalanceValue;

