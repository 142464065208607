/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../App.css";
import { Icon, Popconfirm, Button, Tooltip, Modal, message, Checkbox, InputNumber } from "antd";
import TableIDs from "../data/TableIDs";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import GenericModal from "./GenericModal";

class ModalMatchDocs extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            modalMatchDocs: null,
            matchDocsList: [],
            matchDocsIsAll: false,

            modalFilterMatchNum: null,
            modalFilterMatchNumList: [],

            GP_AcceptMatchDifference: 0,

        };

        this.match_id = "MATCH_DOCS_CUSTOMERS";

        if (props.data[this.match_id] === undefined) props.data[this.match_id] = { ...props.data.genericData };

        this.api = {
            get_match: "get_customer_match_docs",
            save_match: "save_customer_match_docs",
            delete_match: "delete_customer_match_docs",
        };

        this.modalMatchDocsOpen = false;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    }

    componentDidMount() {
        this.sendAPI("get_GP_AcceptMatchDifference", "", ob => {
            this.setState({ GP_AcceptMatchDifference: ob.data ? parseFloat(ob.data) : 0 })
        })
    }

    componentDidUpdate(p) {
        console.log("ModalMatchDocs run 1", p.autoOpen, this.props.autoOpen)

        if (!this.modalMatchDocsOpen && this.props.autoOpen) {
            console.log("ModalMatchDocs run 2")
            this.modalMatchDocsOpen = true;
            this.matchDocsOpenModal(this.props.record)
        }
    }

    matchDocsOpenModal = (custData) => {
        console.log("ModalMatchDocs run 3")
        if (custData == null && this.props.onClose) {
            this.props.onClose();
            this.modalMatchDocsOpen = false;
        }
        this.setState({ modalMatchDocs: custData, matchDocsList: [] }, () => {
            console.log("ModalMatchDocs run 4")
            this.props.dataActions.genericDataSetFilter(
                this.match_id,
                this.props.user.companyCode,
                this.props.user.token,
                {
                    _custId: custData ? custData.key : null,
                    _allView: this.state.matchDocsIsAll ? '1' : '0',
                    _isSupplier: this.props.isSupplier ? '1' : '0',
                },
                this.api.get_match);
        })
    }

    checkboxMatchDocs = (text, record) => {
        let checked = false;
        let indeterminate = false;
        let onChange = () => { };

        const { data, headers } = this.props.data[this.match_id];
        const { matchDocsList } = this.state;

        let allData = data.map(x => {
            let y = x.split("\f");
            let obj = {}
            headers.forEach((z, i) => { obj = { ...obj, [z]: y[i] } })
            return obj
        }).filter(f => !parseInt(f._matchNum))

        if (text === 'All') {
            if (matchDocsList.length) {
                if (matchDocsList.length < allData.length) indeterminate = true;
                else checked = true;
            }
            onChange = (e) => {
                this.setState({
                    matchDocsList: e.target.checked ? allData : []
                })
            }
        } else if (record._matchNum > 0) {
            return (<span>
                <Icon type="check" style={{ color: 'green', marginLeft: 10 }} />
                <Tooltip title={this.getText(18134)}>
                    <Icon type="eye" onClick={() => { this.filterMatchNum(record._matchNum) }} />
                </Tooltip>
            </span>)
        } else {
            if (matchDocsList.find(f => f._num == record._num)) checked = true;
            onChange = (e) => {
                this.setState({
                    matchDocsList: e.target.checked ? [...matchDocsList, record] : matchDocsList.filter(f => f._num != record._num)
                })
            }
        }

        return (<Checkbox {...{ checked, onChange, indeterminate }} />)
    }

    getSumMatchDocs = (mType, h) => {
        const { matchDocsList, modalFilterMatchNumList } = this.state;
        const { data } = this.props.data[this.match_id];

        let res = 0;
        if (mType == 1) {
            matchDocsList.forEach(x => {
                if (((h && x._matchType == 'H') || (!h && x._matchType == 'Z')) && parseFloat(x._sum) && !parseInt(x._matchNum)) res += parseFloat(x._sum)
            });
        } else if (mType == 2) {
            let numList = matchDocsList.map(x => x._num)
            data.forEach(x => {
                let y = x.split("\f");
                if (((h && y[7] == 'H') || (!h && y[7] == 'Z')) && parseFloat(y[6]) && numList.indexOf(y[0]) === -1 && !parseInt(y[8])) res += parseFloat(y[6])
            });
        } else if (mType == 3) {
            data.forEach(x => {
                let y = x.split("\f");
                if (((h && y[7] == 'H') || (!h && y[7] == 'Z')) && y[8] > 0) res += parseFloat(y[6])
            });
        } else if (mType == 4) {
            modalFilterMatchNumList.forEach(x => {
                if (((h && x._matchType == 'H') || (!h && x._matchType == 'Z')) && parseFloat(x._sum)) res += parseFloat(x._sum)
            });
        }
        return res ? Math.round(res * 100) / 100 : 0
    }

    matchDocsAuto = () => {
        const { data, headers } = this.props.data[this.match_id];
        let tempList = [];
        let tempListNum = [];

        data.forEach(x => {
            let y = x.split("\f");
            let _sum = y[6] ? Math.round(y[6] * 100) / 100 : 0
            if (!parseInt(y[8])) {
                let _isset = tempList.findIndex(f => f._sum == _sum && !f._finishNum && y[7] !== f._matchType)
                if (_isset > -1) tempList[_isset] = { ...tempList[_isset], _finishNum: y[0] }
                else tempList.push({ _num: y[0], _sum, _matchType: y[7] })
            }
        });

        tempList.forEach(x => {
            if (x._finishNum) {
                tempListNum.push(x._num)
                tempListNum.push(x._finishNum)
            }
        });

        this.setState({
            matchDocsList: data.map(x => {
                let y = x.split("\f");
                let obj = {}
                headers.forEach((z, i) => { obj = { ...obj, [z]: y[i] } })
                return obj
            }).filter(f => {
                return tempListNum.indexOf(f._num) > -1
            })
        })
    }

    matchDocsSave2 = () => {
        const { modalMatchDocs, matchDocsList } = this.state;

        this.sendAPI(this.api.save_match, "_custId\f_docList\r" + this.props.mId + "\f" + matchDocsList.map(x => x._num).join(","), () => {
            this.matchDocsOpenModal(modalMatchDocs)
        }, e => { console.error(e) })
    }

    matchDocsSave = () => {
        let h = this.getSumMatchDocs(1, true);
        let z = this.getSumMatchDocs(1);
        let gp = this.state.GP_AcceptMatchDifference
        if (h != z) {
            if (gp && Math.abs(h - z) <= gp) {
                Modal.confirm({
                    content: this.getText(19009) + " " + Math.abs(h - z) + ", " + this.getText(19010),
                    okText: this.getText(19011),
                    cancelText: this.getText(19012),
                    onOk: this.matchDocsSave2,
                    onCancel() { },
                })
            } else {
                message.error(this.getText(18127));
                return;
            }
        } else {
            this.matchDocsSave2()
        }
    }

    matchDocsFilter = () => {
        const { modalMatchDocs, matchDocsIsAll } = this.state;
        this.setState({ matchDocsIsAll: !matchDocsIsAll }, () => {
            this.matchDocsOpenModal(modalMatchDocs)
        })
    }

    filterMatchNum = (matchNum) => {
        const { data, headers } = this.props.data[this.match_id];

        this.setState({
            modalFilterMatchNum: matchNum,
            modalFilterMatchNumList: data.map(x => {
                let y = x.split("\f");
                let obj = {}
                headers.forEach((z, i) => { obj = { ...obj, [z]: y[i] } })
                return obj
            }).filter(f => f._matchNum == matchNum)
        })
    }

    deleteMatchDocs = () => {
        const { modalMatchDocs, modalFilterMatchNum } = this.state;
        this.sendAPI(this.api.delete_match, modalFilterMatchNum, () => {
            this.filterMatchNum(null)
            this.matchDocsOpenModal(modalMatchDocs)
        }, e => { console.error(e) })
    }

    getSumFormat = (num) => {
        let res = ""
        if (num) {
            let x = parseFloat(num).toFixed(2);
            let y = x.split(".")
            let intNum = y[0];
            if (intNum[0] == "-") {
                res += "-";
                intNum = intNum.slice(1, intNum.length);
            }
            let s = intNum.split("")
            s.forEach((z, i) => {
                if (s.length > 3 && i && i % 3 == s.length % 3) res += ",";
                res += z;
            })

            res += "." + y[1];
        }

        return res;
    }

    renderModals = () => {
        let gt = this.getText;

        const render = t => t;
        const renderReverseDate = t => t ? t.split("-").reverse().join("-") : '';
        const renderSum = t => t ? (<div dir="ltr" style={{ textAlign: "right" }}>{this.getSumFormat(t)}</div>) : ""
        const renderLeft = t => (<div><div style={{ maxWidth: 250, textAlign: 'right', marginRight: 'auto' }}>{t}</div></div>)

        return [
            <GenericModal
                width={1200}
                visible={this.state.modalMatchDocs !== null}
                onCancel={() => { this.matchDocsOpenModal(null) }}
                title={gt(this.props.isSupplier ? 18858 : 18111) + " " + this.props.mId + " - " + this.props.mName}
                footer={[
                    <span style={{ marginLeft: 20 }}>{this.getText(19007)}</span>,
                    <InputNumber value={this.state.GP_AcceptMatchDifference} onChange={e => { this.setState({ GP_AcceptMatchDifference: e }) }} style={{ marginLeft: 20 }} />,
                    <Button type="primary" size="large" style={{ marginLeft: 20 }} onClick={this.matchDocsAuto}>{gt(18123)}</Button>,
                    <Button type="primary" size="large" style={{ marginLeft: 20 }} onClick={this.matchDocsSave}>{gt(18124)}</Button>,
                    <Button type="primary" size="large" style={{ marginLeft: 20 }} onClick={this.matchDocsFilter}>{gt(this.state.matchDocsIsAll ? 18126 : 18125)}</Button>,
                    <Button size="large" onClick={() => { this.matchDocsOpenModal(null) }}>{gt(18130)}</Button>,
                ]}>
                <ResponsiveTable
                    idTable={this.props.data[this.match_id]}
                    columns={[
                        { title: gt(18112), key: "_typeName", dataIndex: "_typeName", width: "20%", render },
                        { title: gt(18113), key: "_pos", dataIndex: "_pos", width: "12%", render },
                        { title: gt(18114), key: "_date", dataIndex: "_date", width: "12%", render: renderReverseDate },
                        { title: gt(18115), key: "_valDate", dataIndex: "_valDate", width: "12%", render: renderReverseDate },
                        { title: gt(18116), key: "_num", dataIndex: "_num", width: "12%", render },
                        { title: gt(18117), key: "_sumH", dataIndex: "_sum", width: "12%", render: (t, r) => renderSum(r._matchType == 'H' ? t : "") },
                        { title: gt(18118), key: "_sumZ", dataIndex: "_sum", width: "12%", render: (t, r) => renderSum(r._matchType == 'Z' ? t : "") },
                        { title: this.checkboxMatchDocs('All'), key: "_matchNum", dataIndex: "_matchNum", width: "8%", render: this.checkboxMatchDocs },
                    ]}
                    tableOnly
                    pagination={false}
                    scroll={{ y: window.innerHeight > 600 ? window.innerHeight - 400 : 200 }}
                />
                <ResponsiveTable
                    dataSource={[
                        { key: 1, _sumDesc: gt(18120), _sumH: this.getSumMatchDocs(1, true), _sumZ: this.getSumMatchDocs(1) },
                        { key: 2, _sumDesc: gt(18121), _sumH: this.getSumMatchDocs(2, true), _sumZ: this.getSumMatchDocs(2) },
                        { key: 3, _sumDesc: gt(18122), _sumH: this.getSumMatchDocs(3, true), _sumZ: this.getSumMatchDocs(3) },
                    ]}
                    columns={[
                        { title: "", key: "_sumDesc", dataIndex: "_sumDesc", width: "67%", render: renderLeft },
                        { title: "", key: "_sumH", dataIndex: "_sumH", width: "12%", render: renderSum },
                        { title: "", key: "_sumZ", dataIndex: "_sumZ", width: "12%", render: renderSum },
                        { title: "", width: "9%", render: () => null },
                    ]}
                    tableOnly
                    pagination={false}
                />
            </GenericModal>,
            <GenericModal
                width={1100}
                visible={this.state.modalFilterMatchNum !== null}
                onCancel={() => { this.filterMatchNum(null) }}
                title={gt(18128)}
                footer={[
                    <Popconfirm title={gt(18131)} okText={gt(18132)} cancelText={gt(18133)} onConfirm={this.deleteMatchDocs}>
                        <Button type="primary" size="large" style={{ marginLeft: 20 }}>{gt(18129)}</Button>
                    </Popconfirm>,
                    <Button size="large" onClick={() => { this.filterMatchNum(null) }}>{gt(18130)}</Button>,
                ]}>
                <ResponsiveTable
                    dataSource={this.state.modalFilterMatchNumList}
                    columns={[
                        { title: gt(18112), key: "_typeName", dataIndex: "_typeName", width: "28%", render },
                        { title: gt(18113), key: "_pos", dataIndex: "_pos", width: "12%", render },
                        { title: gt(18114), key: "_date", dataIndex: "_date", width: "12%", render: renderReverseDate },
                        { title: gt(18115), key: "_valDate", dataIndex: "_valDate", width: "12%", render: renderReverseDate },
                        { title: gt(18116), key: "_num", dataIndex: "_num", width: "12%", render },
                        { title: gt(18117), key: "_sumH", dataIndex: "_sum", width: "12%", render: (t, r) => renderSum(r._matchType == 'H' ? t : "") },
                        { title: gt(18118), key: "_sumZ", dataIndex: "_sum", width: "12%", render: (t, r) => renderSum(r._matchType == 'Z' ? t : "") },
                    ]}
                    tableOnly
                    pagination={false}
                />
                <ResponsiveTable
                    dataSource={[
                        { key: 4, _sumDesc: gt(18122), _sumH: this.getSumMatchDocs(4, true), _sumZ: this.getSumMatchDocs(4) },
                    ]}
                    columns={[
                        { title: "", key: "_sumDesc", dataIndex: "_sumDesc", width: "76%", render: renderLeft },
                        { title: "", key: "_sumH", dataIndex: "_sumH", width: "12%", render: renderSum },
                        { title: "", key: "_sumZ", dataIndex: "_sumZ", width: "12%", render: renderSum },
                    ]}
                    tableOnly
                    pagination={false}
                />

            </GenericModal>
        ]
    }


    render() {

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '509')) {
            return (<span>
                <Tooltip title={this.getText(18119)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file-done"} onClick={() => {
                        this.matchDocsOpenModal(this.props.record)
                    }} />
                </Tooltip>
                {this.state.modalMatchDocs ? this.renderModals() : ""}
            </span>)
        } else if (this.props.autoOpen) {
            return this.renderModals();
        } else {
            return ""
        }

    }
}
export default ModalMatchDocs;
