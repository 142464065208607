/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import GridKeyValues from "../../components/GridKeyValues";
import DateTimePicker from "../../components/DateTimePicker";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import TableLineIcons from "../../components/TableLineIcons";
import ResponsiveTable from "../../components/ResponsiveTable";
import CustPicker from "../../components/CustPicker";
import ModalSearchCoupons from "../../components/ModalSearchCoupons";
import { Input, Select, InputNumber, Tooltip, Button, Icon, Form, Radio, Upload, Popconfirm, Row, Col, Checkbox, message, Popover } from "antd";
import TableIDs from "../../data/TableIDs";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import ModalProgress from "../../components/ModalProgress";
import PageToolbar from "../../components/PageToolbar";
import ReportShowButton from "../../components/ReportShowButton";

class Coupons extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalCreateEdit: null,
            modalReadOnly: false,
            modalMultiCoupons: null,
            countCoupons: 1,
            fileList: [],
            custList: [],
            modalCustList: false,
            custTagList: '',
            custTagGet: false,
            mailingToCust: false,
            mailingMassage: null,

            filterActive: false,
            valueSearch: '',

            progressMultiCounter: 0,
            progressMaxCounter: 0,

            couponCheckInput: "",

            preCouponNumber: 1234,
            lenCouponNumber: 8,

        };

        this.filters = {
            _expiryDateStart: null,
            _expiryDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _customer: null,
            _posList: null,
            _isUsed: null,
            _supplierList: null,
            _itemList: null,
            _priceList: null,
            _branchList: null,
            _fromFranchiseCost: null,
            _toFranchiseCost: null,
            _fromCustomerCost: null,
            _toCustomerCost: null,
            _distributor: null,
            _company: null,
        };

        this.templateFields = {
            cId: 0,
            cDistributorId: null,
            cCompanyId: null,
            cName: null,
            cDescription: null,
            cValidDateTimeBegin: null,
            cValidDateTimeEnd: null,
            cCode: null,
            cValidationMethod: '0',
            cConfirmationCode: null,
            cType: null,
            cItemCode: null,
            cDealCode: null,
            cPrice: null,
            cBranches: null,
            cDateTimeClosed: null,
            cIsUsed: null,
            cDocNumber: null,
            cDocTypeId: null,
            cDocPosId: null,
            cDocLineNumber: null,
            cTransactionId: null,
            cCustomerName: null,
            cCustomerPhone: null,
            cCustomerNumber: null,
            cCustomerMail: null,
            cIsOverrideDiscount: '0',
            cCustomerCost: null,
            cFranchiseCost: null,
            cTagId: null,
            cTagName: null,
            cItemQty: null,
            cMinimumTotalForUse: '0',
            cMaxBenefitValue: null,
        }


        this.id = "COUPONS_TABLE";

        this.api = {
            get: "get_coupons_table",
            create_edit: "create_edit_coupons",
            delete: "delete_coupon",

            create_multi: "create_multi_coupons",
            get_cust: "get_customers_per_tags",
            get_cust_data: "get_cust_mobile_and_email",

            check_coupon: "check_coupon_data",
        }

        this.selectors = [
            { id: "BENEFITS_DISTRIBUTORS_LIST", api: "benefits_distributors_list" },
            { id: "BENEFITS_COMPANIES_LIST", api: "benefits_companies_list" },
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
            // { id: "KUPON_DECLARATION_LIST", api: "kupon_declaration_list" },
            { id: "COUPON_DECLARATION_LIST", api: "coupon_declaration_list" },
            { id: TableIDs.genericMultiSelectorCustomerTags, api: "get_customer_tags_list" },
            { id: TableIDs.genericSelectorPreDefinedMsg, api: "get_pre_defined_msg_list" },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

        this.resMultiCoupons = [];
        this.progressStop = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI("get_all_gp_by_user", "", ob => {
            let lenCouponNumber = 8;
            let preCouponNumber = 1234;

            if (ob.data) {
                ob.data.split("\r").forEach(x => {
                    let y = x.split("\f");
                    if (y[0] == 'GP_CouponPrefix' && y[1]) preCouponNumber = y[1];
                    if (y[0] == 'GP_CouponLen' && y[1]) lenCouponNumber = y[1];
                })
            }

            this.setState({ lenCouponNumber, preCouponNumber })
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(this.props.isCouponCheckComponent ? 18773 : 15614);
    }

    loadSelector = (index, params) => {
        let x = this.selectors[index];
        if (index === 3) {
            this.sendAPI(x.api, 'cdType\r0', (ob) => {
                let dataset = ob.data ? ob.data.split('\r').map(z => {
                    let y = z.split('\f');
                    return {
                        code: y[0],
                        name: y[1],
                        description: y[1],
                        dateFrom: y[2],
                        dateTo: y[3],
                        couponType: y[4],
                        couponItemCode: y[5],
                        couponItemName: y[6],
                        couponTagId: y[7],
                        couponTagName: y[8],
                        couponQty: y[9],
                        couponPrice: y[10],
                        minimumTotalForUse: y[11],
                        maxBenefitValue: y[12],
                    }
                }) : []
                this.props.dataActions.setJson(x.id, { dataset })
            });
        } else {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api, params);
        }
    }

    getParamsToReport = () => {
        const {
            _words, _isAsc, _sort_by,
            _expiryDateStart, _expiryDateEnd, _activityDateStart, _activityDateEnd,
            _customer, _posList, _isUsed, _supplierList, _itemList, _priceList, _branchList,
            _fromFranchiseCost, _toFranchiseCost, _fromCustomerCost, _toCustomerCost,
            _distributor, _company
        } = this.props.data[this.id].filters;

        return {
            _words: encodeURIComponent(_words),
            _isAsc, _sort_by,
            _expiryDateStart, _expiryDateEnd, _activityDateStart, _activityDateEnd,
            _customer, _posList, _isUsed, _supplierList,
            _itemList: encodeURIComponent(_itemList),
            _priceList, _branchList,
            _fromFranchiseCost, _toFranchiseCost, _fromCustomerCost, _toCustomerCost,
            _distributor, _company,
            REPORT_LOCALE: this.getText(101),
            reportName: "reports/Coupons.pdf",
        }
    }

    generateReport = (reportName) => {
        let params = this.getParamsToReport()

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                { ...params, reportName }
            ]
        });
    }

    reportButton = () => {

        let subButton = (text, type, reportName) => {
            return (<Tooltip title={this.getText(text)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={() => {
                    if (this.props.data[this.id].count > 30000) message.error(this.getText(19467))
                    else this.generateReport(reportName)
                }} />
            </Tooltip>)
        }

        return (<Tooltip title={this.getText(12720)}>
            <Popover content={[
                subButton(12721, "file-pdf", "reports/Coupons.pdf"),
                subButton(12722, "file-excel", "reports/CouponsExcel.xls")
            ]} title={this.getText(12723)} trigger="click">
                <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                    <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                </Button>
            </Popover>
        </Tooltip>)
    }


    getCustData = (custID, custName) => {
        this.sendAPI(this.api.get_cust_data, custID, (ob) => {
            let data = ob.data ? ob.data.split('\f') : ['', '']
            this.setState({
                modalCreateEdit: {
                    ...this.state.modalCreateEdit,
                    cCustomerNumber: custID,
                    cCustomerName: custName,
                    cCustomerPhone: data[0],
                    cCustomerMail: data[1],
                }
            })
        })
    }

    getInput = (field, type) => {
        let mData = this.state.modalCreateEdit;
        let value = mData[field];
        let onChange = (e) => {
            let modalCreateEdit = { ...mData, [field]: e }
            this.setState({ modalCreateEdit }, () => {
                if (['cDealCode', 'cType', 'cPrice', 'cTagId', 'cItemQty'].indexOf(field) > -1) this.setDescriptionText()
            });
        }
        let readOnly = this.state.modalReadOnly;
        let selector = (data, oc) => {
            return (<Select style={{ width: '100%' }} value={value} onChange={oc} disabled={readOnly}>
                {data.map(x => (<Select.Option value={x.code}>{x.name}</Select.Option>))}
            </Select>);
        }
        switch (type) {
            case 'text':
                return readOnly ? value : (<Input value={value} onChange={(e) => { onChange(e.target.value) }} />);
            case 'textarea':
                return readOnly ? value : (<Input.TextArea value={value} onChange={(e) => { onChange(e.target.value) }} rows={3} />);
            case 'datetime':
                let timeValue = ' 00:00:00';
                let minDate = null;
                let maxDate = mData.cValidDateTimeEnd ? mData.cValidDateTimeEnd.split(" ")[0] : null;
                if (field === 'cValidDateTimeEnd') {
                    timeValue = ' 23:59:59';
                    minDate = mData.cValidDateTimeBegin ? mData.cValidDateTimeBegin.split(" ")[0] : null;;
                    maxDate = null;
                    console.log("renderValidDateTimeEnd 222", readOnly)
                    if (readOnly) return this.renderValidDateTimeEnd(value, mData);
                }
                return readOnly ? value : (<DateTimePicker {...{ value, minDate, maxDate }} onChange={(e) => { onChange(e ? e + (timeValue) : null) }} format={"DD/MM/YYYY"} />);
            case 'checkbox':
                return (<Checkbox checked={value == '1'} onChange={(e) => { onChange(e.target.checked ? '1' : '0') }} disabled={readOnly} />);
            case 'number':
                return readOnly ? value : (<InputNumber value={value} onChange={onChange} precision={2} />);
            case 'items':
                return readOnly ? mData.cItemName : (<SelectorItemClassic {...this.props} valueId={mData.cItemCode} valueName={mData.cItemName} onChange={(code, name) => {
                    this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cItemCode: code, cItemName: name } }, this.setDescriptionText)
                }} />);
            case 'customer':
                return readOnly ? value : (
                    <CustPicker
                        value={value}
                        onChange={this.getCustData}
                        defaultView={this.getText(12121)}
                        {...this.props} />)
            case 'select1':
                return selector(this.props.data[this.selectors[0].id].dataset, (e) => {
                    onChange(e);
                    this.loadSelector(1, { cDistributorId: e });
                });
            case 'select2':
                return selector(this.props.data[this.selectors[1].id].dataset, onChange);
            case 'select3':
                return selector([
                    { code: '0', name: this.getText(15641) },
                    { code: '1', name: this.getText(15642) },
                    { code: '2', name: this.getText(15643) },
                ], onChange);
            case 'select4':
                return selector([
                    // { code: '1', name: this.getText(15644) },
                    // { code: '2', name: this.getText(15645) },
                    // { code: '3', name: this.getText(15646) },

                    // { code: '1', name: this.getText(15647) },
                    // { code: '2', name: this.getText(15648) },

                    { code: '1', name: this.getText(17343) },
                    { code: '2', name: this.getText(17344) },
                    { code: '3', name: this.getText(17345) },
                    { code: '4', name: this.getText(17346) },
                    { code: '5', name: this.getText(17347) },
                    { code: '6', name: this.getText(17348) },
                    { code: '7', name: this.getText(17349) },
                    { code: '8', name: this.getText(17350) },

                ], //onChange
                    (e) => {
                        this.setState({
                            modalCreateEdit: {
                                ...this.state.modalCreateEdit, cType: e,
                                cItemQty: e < 7 ? '1' : '', cPrice: '', cItemCode: '', cItemName: '', cTagId: '', cTagName: '',
                            }
                        }, this.setDescriptionText)
                    }
                );
            case 'select5':
                return <GenericSelector
                    {...this.props}
                    {...this.selectors[2]}
                    multi
                    disabled={readOnly}
                    value={value ? value.split(",") : []}
                    onChange={e => { onChange(e ? e.join(",") : "") }}
                />
            // return selector([
            //     { code: '0', name: this.getText(15640) },
            //     ...this.props.data[this.selectors[2].id].dataset
            // ], onChange);
            case 'select6':
                return readOnly ? value + ' - ' + mData.cDealName : selector([
                    { code: '0', name: ' - ', description: '' },
                    ...this.props.data[this.selectors[3].id].dataset
                ], onChange);
            case 'tags':
                return (<GenericSelectorItemTags {...this.props} notReport disabled={readOnly} value={value} onChange={(code) => {
                    let name = this.findTagName(code);
                    this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cTagId: code, cTagName: name } }, this.setDescriptionText)
                }} />)

        }
    }

    findTagName = (id) => {
        let text = '';
        this.props.data[this.selectors[6].id].dataset.forEach(x => {
            x.tags.forEach(y => {
                if (y.id === id) text = y.text;
            })
        });

        return text;
    }

    setDescriptionText = () => {
        const { cDealCode, cItemName, cType, cPrice, cTagName, cItemQty } = this.state.modalCreateEdit;
        let gt = this.getText;
        let res = '';
        if (parseInt(cDealCode)) {
            let findItem = this.props.data[this.selectors[3].id].dataset.find(f => f.code === cDealCode);
            this.setState({
                modalCreateEdit: {
                    ...this.state.modalCreateEdit,
                    cDescription: findItem ? findItem.description : "",
                    cItemCode: findItem ? findItem.couponItemCode : "",
                    cItemName: findItem ? findItem.couponItemName : "",
                    cType: findItem ? findItem.couponType : "",
                    cTagId: findItem ? findItem.couponTagId : "",
                    cTagName: findItem ? findItem.couponTagName : "",
                    cItemQty: findItem ? findItem.couponQty : "",
                    cPrice: findItem ? findItem.couponPrice : "",
                    cValidDateTimeBegin: findItem ? findItem.dateFrom : "",
                    cValidDateTimeEnd: findItem ? findItem.dateTo : "",
                    cMinimumTotalForUse: findItem ? findItem.minimumTotalForUse : "",
                    cMaxBenefitValue: findItem ? findItem.maxBenefitValue : "",
                }
            })
            // } else if (cPrice) {
            //     res = cItemName ? cItemName + ' ' + gt(15840) : gt(15838);
            //     res += cPrice + ' ' + gt(cType == '1' ? 15839 : 15841);
            //     if (cType == '1' && !cItemName) res += ' ' + gt(16201);
            //     this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cDescription: res } })
        } else {
            let cDescription = '';
            let isTypeForItem = ['1', '2', '3'].indexOf(cType) > -1;
            let isTypeForTag = ['4', '5', '6'].indexOf(cType) > -1;
            let isTypeDiscountPercent = ['1', '4', '7'].indexOf(cType) > -1;
            let isTypeDiscountSum = ['3', '6', '8'].indexOf(cType) > -1;

            if (cPrice) {
                if (isTypeForItem) {
                    if (cItemName) cDescription = cItemName + ' ' + gt(15840);
                } else if (isTypeForTag) {
                    if (cTagName) cDescription = gt(17360) + ' ' + cTagName + ' ' + gt(15840);
                } else {
                    cDescription = gt(15838);
                }

                cDescription += cPrice + " " + gt(isTypeDiscountPercent ? 15841 : 15839);
                if (isTypeDiscountSum) cDescription += " " + gt(16201);

                if (cItemQty) cDescription += ' ' + gt(17361) + ' ' + cItemQty + ' ' + gt(17362)
            }
            this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cDescription } })
        }

    }

    // getTypeDetailed = () => {
    //     const { cType, cItemCode, cDealCode, cPrice, cItemName, cDealName } = this.state.modalCreateEdit;
    //     let readOnly = this.state.modalReadOnly;
    //     switch (cType) {
    //         case '1':
    //             return readOnly ? cItemName : (<SelectorItemClassic {...this.props} valueId={cItemCode} valueName={cItemName} onChange={(code, name) => {
    //                 this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cItemCode: code, cItemName: name } })
    //             }} />);
    //         case '2': case '3':
    //             return readOnly ? cDealName : (<Select style={{ width: '100%' }} value={cDealCode} onChange={(e) => {
    //                 this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cDealCode: e } })
    //             }}>{this.props.data[this.selectors[3].id].dataset.map(x => (<Select.Option value={x.code}>{x.name}</Select.Option>))}</Select>);
    //         case '4': case '5':
    //             return readOnly ? cPrice : (<InputNumber precision={2} value={cPrice} onChange={(e) => {
    //                 this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, cPrice: e } })
    //             }} />);
    //     }
    // }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    sendMultiCoupuns = (dataSend) => {
        let headers = [...this.props.data[this.id].headers, 'mailingToCust'];
        let sliceDataSend = dataSend.splice(0, 200);

        if (sliceDataSend.length && !this.progressStop) {
            const { lenCouponNumber, preCouponNumber } = this.state;

            let inputSend = headers.join('\f') + "\flenCouponNumber\fpreCouponNumber\r"
                + sliceDataSend.join("\f" + lenCouponNumber + "\f" + preCouponNumber + "\r")
                + "\f" + lenCouponNumber + "\f" + preCouponNumber;

            this.sendAPI(this.api.create_multi, inputSend, (ob) => {
                let couponsList = ob.data ? ob.data.split('\r') : [];
                this.resMultiCoupons = [...this.resMultiCoupons, ...couponsList];
                this.setState({ progressMultiCounter: this.state.progressMultiCounter + 1 }, () => { this.sendMultiCoupuns(dataSend) })
            })
        } else {
            this.printMultiCoupons()
        }
    }

    printMultiCoupons = () => {
        const gt = this.getText;
        const apiData = this.resMultiCoupons.map(x => {
            let y = x.split('\f');
            return {
                [gt(11011)]: y[0],
                [gt(15638)]: y[1],
                [gt(15636)]: y[2],
                [gt(15637)]: y[3],
                [gt(15639)]: y[4]
            }
        })
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: fileType });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = 'newCoupons.xlsx';
        a.click();


        this.setState({
            progressMaxCounter: 0,
            progressMultiCounter: 0
        }, () => {
            this.props.uiActions.hideModal(TableIDs.modalProgress);
            this.resMultiCoupons = [];
            this.progressStop = false;
            this.handleCancel();
            this.dataSetFilter({})
        })
    }

    handleOk = () => {
        let request = this.state.modalCreateEdit;
        if (!request) return;

        let isBranchesReq = !this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '522')

        if (!request.cName) {
            message.error(this.getText(17967));
            return;
        }
        if (!request.cDistributorId) {
            message.error(this.getText(17968));
            return;
        }
        if (!request.cCompanyId) {
            message.error(this.getText(17969));
            return;
        }
        if (!request.cType) {
            message.error(this.getText(17363));
            return;
        }

        if (!request.cPrice) {
            message.error(this.getText(17366));
            return;
        }

        if (request.cMinimumTotalForUse == null) {
            message.error(this.getText(18920));
            return;
        }

        if ((request.cType == '1' || request.cType == '2' || request.cType == '3') && !request.cItemCode) {
            message.error(this.getText(17364));
            return;
        }

        if ((request.cType == '4' || request.cType == '5' || request.cType == '6') && !request.cTagId) {
            message.error(this.getText(17365));
            return;
        }

        if ((request.cType == '1' || request.cType == '2' || request.cType == '3' || request.cType == '4' || request.cType == '5' || request.cType == '6') && !request.cItemQty) {
            message.error(this.getText(18921));
            return;
        }

        if (this.state.modalMultiCoupons == 3 && !this.state.custList.length) {
            message.error(this.getText(18440));
            return;
        }

        if (isBranchesReq && !request.cBranches) {
            message.error(this.getText(19423));
            return;
        }

        if (this.state.modalMultiCoupons) {
            let headers = [...this.props.data[this.id].headers, 'mailingToCust'];
            let dataSend = [];

            if (this.state.modalMultiCoupons == 1) {
                for (let x = 0; x < this.state.countCoupons; x++) {
                    dataSend.push(headers.map(x => request[x]).join('\f') + '0');
                }
            } else {
                let fullData = this.state.custList.map(x => { return { ...request, cCustomerNumber: x.A, cCustomerName: x.B, cCustomerPhone: x.C, cCustomerMail: x.D } });
                fullData.forEach(y => {
                    dataSend.push(headers.map(x => y[x]).join('\f') + (this.state.mailingMassage ? this.state.mailingMassage : '0'));
                })
            }

            this.setState({
                progressMaxCounter: Math.ceil(dataSend.length / 200),
                progressMultiCounter: 1
            }, () => {
                this.resMultiCoupons = [];
                this.progressStop = false;
                this.sendMultiCoupuns(dataSend);
                this.props.uiActions.showModal(TableIDs.modalProgress);
            })
        } else {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create_edit,
                this.api.get,
                {
                    ...request,
                    mailingToCust: this.state.mailingMassage,
                    preCouponNumber: this.state.preCouponNumber,
                    lenCouponNumber: this.state.lenCouponNumber,
                });

            this.handleCancel();
        }
    }

    handleCancel = () => {
        this.setState({
            modalCreateEdit: null,
            modalReadOnly: false,
            modalMultiCoupons: null,
            countCoupons: 1,
            fileList: [],
            custList: [],
            modalCustList: false,
            custTagList: '',
            mailingToCust: false,
            mailingMassage: null,
        })
    }

    handleUnlock = () => {
        let request = this.state.modalCreateEdit;


        this.props.dataActions.genericDataSaveEditWithParams(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            "unlock_new_coupon",
            this.api.get,
            {
                nCode: request.cCode,
                nTransactionId: request.cTransactionId
            });

        this.handleCancel();
    }

    editIcons = (text, record) => {
        // let loadSelectors = () => {
        //     this.loadSelector(0);
        //     this.loadSelector(1, { cDistributorId: record.cDistributorId });
        //     this.loadSelector(2);
        //     // if (record.cType == '2')
        //     this.loadSelector(3, { cdType: 0 });
        //     // if (record.cType == '3') this.loadSelector(3, { cType: '2' });
        // }

        let genericIcons = [{
            type: 'eye',
            onClick: () => {
                this.setState({ modalCreateEdit: record, modalReadOnly: true }, () => {
                    this.loadSelector(0);
                    this.loadSelector(1, { cDistributorId: record.cDistributorId });
                    this.loadSelector(2);
                    // if (record.cType == '2')
                    this.loadSelector(3, { cdType: 0 });
                    // if (record.cType == '3') this.loadSelector(3, { cType: '2' });
                })
            }
        }]


        if (record.cIsUsed == '1') {
            return (<span>
                <ReportShowButton
                    {...this.props}
                    iconButton
                    validSubmit
                    params={{
                        DocumentNumber: record.cDocNumber,
                        DocumentTypeID: record.cDocTypeId,
                        DocumentPOSID: record.cDocPosId,
                        userID: this.props.user.userId,
                        userName: encodeURIComponent(this.props.user.displayName),
                        CompanyCode: this.props.user.companyCode,
                        isOriginal: false,
                        CentralFatherItem: 0,
                        PrintFormat: null,
                        docIsWithSigns: 0,
                        reportName: "reports/DocumentCopy.pdf",
                        REPORT_LOCALE: this.getText(101)
                    }} />
                <TableLineIcons genericIcons={genericIcons} />
            </span>)
        } else {
            return (<TableLineIcons
                genericIcons={genericIcons}
                // onEdit={() => { this.setState({ modalCreateEdit: record }, loadSelectors) }}
                deleteQuetion={{ title: this.getText(15651), okText: this.getText(15652), cancelText: this.getText(15653) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }} />)
        }
    }

    dataSetFilter = (request) => {
        let filters = this.props.data[this.id].filters;
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { ...filters, ...request }, this.api.get)
    }



    uploadExcelButton = () => {

        const { fileList } = this.state;

        let GetTableFromExcel = (data) => {
            let workbook = XLSX.read(data, { type: 'binary' });
            let Sheet = workbook.SheetNames[0];
            let fullData = workbook.Sheets[Sheet];
            let ref = fullData['!ref'].split(':');
            let refStart = parseInt(ref[0].replace(/[A-Z]+/, ''));
            let refEnd = parseInt(ref[1].replace(/[A-Z]+/, ''));
            let dataSend = [];

            if (refEnd - refStart > 30000) {
                message.error(this.getText(19468))
            } else {

                let fd = (key) => {
                    let res = fullData[key] && fullData[key].w ? fullData[key].w : '';
                    return res.replace(/[\x00-\x1F\x80-\xFF]/, '').trim()
                }

                for (let n = refStart; n <= refEnd; n++) {
                    dataSend.push({ A: fd('A' + n), B: fd('B' + n), C: fd('C' + n), D: fd('D' + n) });
                }

                this.setState({ custList: dataSend, modalCustList: true });
            }
        };

        let beforeUpload = (fileUpload) => {
            this.setState({ fileList: [fileUpload] });
            let reader = new FileReader();

            //For Browsers other than IE.
            if (reader.readAsBinaryString) {
                reader.onload = function (e) {
                    GetTableFromExcel(e.target.result);
                };
                reader.readAsBinaryString(fileUpload);
            } else {
                //For IE Browser.
                reader.onload = function (e) {
                    let data = "";
                    let bytes = new Uint8Array(e.target.result);
                    for (let i = 0; i < bytes.byteLength; i++) {
                        data += String.fromCharCode(bytes[i]);
                    }
                    GetTableFromExcel(data);
                };
                reader.readAsArrayBuffer(fileUpload);
            }
            return false;
        }

        let gt = this.getText;

        if (fileList.length > 0) {
            return [
                <Button key={"view"} type="primary" onClick={() => { this.setState({ modalCustList: true }) }}><Icon type="eye" style={{ marginLeft: 5 }} />{gt(15660)}</Button>,
                <Button key={"delete"} onClick={() => { this.setState({ fileList: [], custList: [] }) }}><Icon type="delete" style={{ marginLeft: 5 }} />{gt(15661)}</Button>,
            ]
        } else {
            return (<Upload fileList={fileList} beforeUpload={beforeUpload}>
                <Button key={"upButton"}><Icon type="upload" style={{ marginLeft: 5 }} />{gt(15659)}</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<b>A: </b>{gt(15638)}, <b>B: </b>{gt(15636)}, <b>C: </b>{gt(15637)}, <b>D: </b>{gt(15639)}. )
            </Upload>)
        }
    }

    apiCustPerTag = () => {
        this.sendAPI(this.api.get_cust, this.state.custTagList, (ob) => {
            let data = ob.data ? ob.data.split('\r') : [];
            let custList = data.map(x => {
                let y = x.split('\f');
                return { A: y[0], B: y[1], C: y[2], D: y[3] }
            });
            this.setState({ custList, custTagGet: true, modalCustList: true })
        })
    }

    getPerCustTags = () => {
        let gt = this.getText;
        if (!this.state.custTagGet) {
            return (<div style={{ width: 500, display: 'inline-block' }}>
                <GenericSelectorCustTags
                    {...this.props} multi
                    //  {...this.selectors[4]}  optionsGroup style={{ width: 500 }}
                    value={this.state.custTagList ? this.state.custTagList.split(',') : []}
                    onChange={(e) => { this.setState({ custTagList: e ? e.join(',') : '' }) }}
                />
                <Button key={"upButton"} type="primary" onClick={this.apiCustPerTag} style={{ marginTop: 5 }}><Icon type="upload" style={{ marginLeft: 5 }} />{gt(15668)}</Button>
            </div>)
        } else {
            return [
                <Button key={"view"} type="primary" onClick={() => { this.setState({ modalCustList: true }) }}><Icon type="eye" style={{ marginLeft: 5 }} />{gt(15660)}</Button>,
                <Button key={"delete"} onClick={() => { this.setState({ custList: [], custTagGet: false }) }}><Icon type="delete" style={{ marginLeft: 5 }} />{gt(15661)}</Button>,
            ]
        }
    }

    clearFilters = () => {
        this.setState({ filterActive: false, valueSearch: "" })
    }

    renderValidDateTimeEnd = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        console.log("renderValidDateTimeEnd", text, record, editing, editedRecord)
        const value = editedRecord.cValidDateTimeEnd;
        if (editing === record.key) {
            return (<span>
                <Icon type={"check"} style={{ marginLeft: 5 }} onClick={() => {
                    if (this.state.modalCreateEdit && this.state.modalReadOnly) {
                        this.setState({ modalCreateEdit: editedRecord }, () => {
                            this.props.dataActions.genericDataJustSaveEditWithParams(
                                this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, {
                                ...editedRecord,
                                mailingToCust: this.state.mailingMassage,
                                preCouponNumber: this.state.preCouponNumber,
                                lenCouponNumber: this.state.lenCouponNumber,
                            });
                            this.props.dataActions.genericDataCancelEditing(this.id)
                        })
                    } else {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, {
                            ...editedRecord,
                            mailingToCust: this.state.mailingMassage,
                            preCouponNumber: this.state.preCouponNumber,
                            lenCouponNumber: this.state.lenCouponNumber,
                        });
                    }
                }} />
                <Icon type={"close"} style={{ marginLeft: 5 }} onClick={() => { this.props.dataActions.genericDataCancelEditing(this.id) }} />
                <DateTimePicker
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={e => {
                        this.props.dataActions.genericDataSetEdit(this.id, { cValidDateTimeEnd: e })
                    }}
                />
            </span>)
        } else {
            return (<span>
                <Tooltip title={this.getText(17533)} style={{ marginLeft: 5 }}>
                    <Icon type="edit" onClick={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }} />
                </Tooltip>
                {text ? " " + text : ""}
            </span>)
        }
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: this.props.isCouponCheckComponent ? 400 : "auto",
            margin: "auto",
        };

        const gt = this.getText;
        const rgt = t => (<span><span style={{ color: 'red' }}> * </span> {gt(t)}</span>)

        let yesNoTxt = (t) => t == 1 ? gt(11020) : gt(11021);

        let typeText = (t) => {
            switch (t) {
                case '1': return gt(17343);
                case '2': return gt(17344);
                case '3': return gt(17345);
                case '4': return gt(17346);
                case '5': return gt(17347);
                case '6': return gt(17348);
                case '7': return gt(17349);
                case '8': return gt(17350);
            }
        }

        let typeDetailed = (t, r) => {
            if (r.cItemCode) return r.cItemName;
            if (r.cDealCode) return r.cDealName;
            else return this.getText(15640);
        }

        let branchesText = (t) => {
            let fData = this.props.data[this.selectors[2].id].dataset.find(f => f.code === t)
            return t == '0' ? gt(15640) : fData ? fData.name : '';
        }


        let columns = [
            { title: gt(11011), dataIndex: "cCode", key: "cCode", sorter: true, width: '9%', render: t => t },
            { title: gt(15620), dataIndex: "cName", key: "cName", sorter: true, width: '13%', render: (t, r) => (<Tooltip title={r.cDescription}>{t}</Tooltip>) },
            { title: gt(15627), dataIndex: "cType", key: "cType", sorter: true, width: '9%', render: typeText },
            { title: gt(15686), dataIndex: "cPrice", key: "cPrice", sorter: true, width: '8%', render: t => t },
            { title: gt(15687), width: '13%', render: typeDetailed },
            { title: gt(15629), dataIndex: "cBranches", key: "cBranches", sorter: true, width: '9%', render: branchesText },
            { title: gt(15631), dataIndex: "cIsUsed", key: "cIsUsed", sorter: true, width: '6%', render: yesNoTxt },
            { title: gt(15624), dataIndex: "cValidDateTimeEnd", key: "cValidDateTimeEnd", sorter: true, width: '8%', render: this.renderValidDateTimeEnd },
            { title: gt(16352), dataIndex: "cCustomerName", key: "cCustomerName", sorter: true, width: '10%', render: t => t },
            { title: gt(16353), dataIndex: "cCustomerPhone", key: "cCustomerPhone", sorter: true, width: '10%', render: t => t },
            { title: gt(15654), width: '5%', render: this.editIcons },
        ];

        let modalContent = [];

        if (this.state.modalCreateEdit) {
            let isNotDealCode = this.state.modalCreateEdit && !parseInt(this.state.modalCreateEdit.cDealCode);
            let isTypeForItem = ['1', '2', '3'].indexOf(this.state.modalCreateEdit.cType) > -1;
            let isTypeForTag = ['4', '5', '6'].indexOf(this.state.modalCreateEdit.cType) > -1;
            let isTypeForPercent = ['1', '4', '7'].indexOf(this.state.modalCreateEdit.cType) > -1;
            let isBranchesReq = !this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '522')

            let list1 = [
                { key: gt(11011), value: this.state.modalCreateEdit.cCode },
                { key: rgt(15620), value: this.getInput('cName', 'text') },
                { key: rgt(15622), value: this.getInput('cDistributorId', 'select1') },
                { key: rgt(15621), value: this.getInput('cCompanyId', 'select2') },
                { key: gt(15734), value: this.getInput('cCustomerCost', 'number') },
                { key: gt(15735), value: this.getInput('cFranchiseCost', 'number') },
                { key: gt(11012), value: this.state.modalCreateEdit.cDescription },
                { key: gt(15683), value: this.getInput('cIsOverrideDiscount', 'checkbox') },
            ];

            let list2 = [
                { key: gt(15623), value: !isNotDealCode ? this.state.modalCreateEdit.cValidDateTimeBegin : this.getInput('cValidDateTimeBegin', 'datetime') },
                { key: gt(15624), value: !isNotDealCode && !this.state.modalReadOnly ? this.state.modalCreateEdit.cValidDateTimeEnd : this.getInput('cValidDateTimeEnd', 'datetime') },
                { key: gt(15625), value: this.getInput('cValidationMethod', 'select3') },
                { key: gt(15626), value: this.state.modalCreateEdit.cConfirmationCode },
                { key: isBranchesReq ? rgt(15629) : gt(15629), value: this.getInput('cBranches', 'select5') },
                { key: gt(15685), value: this.getInput('cDealCode', 'select6') },
                { key: rgt(15627), value: !isNotDealCode ? typeText(this.state.modalCreateEdit.cType) : this.getInput('cType', 'select4') },
                { key: rgt(15686), value: !isNotDealCode ? this.state.modalCreateEdit.cPrice : this.getInput('cPrice', 'number') },
                { key: rgt(18098), value: !isNotDealCode ? this.state.modalCreateEdit.cMinimumTotalForUse : this.getInput('cMinimumTotalForUse', 'number') },
            ];

            if (isTypeForPercent) list2.push({ key: gt(19349), value: this.getInput('cMaxBenefitValue', 'number') })
            if (isTypeForItem) list2.push({ key: rgt(15684), value: !isNotDealCode ? this.state.modalCreateEdit.cItemName : this.getInput('cItemCode', 'items') })
            if (isTypeForTag) list2.push({ key: gt(17358), value: !isNotDealCode ? this.state.modalCreateEdit.cTagName : this.getInput('cTagId', 'tags') })
            if (isTypeForItem || isTypeForTag) list2.push({ key: rgt(17359), value: !isNotDealCode ? this.state.modalCreateEdit.cItemQty : this.getInput('cItemQty', 'number') })

            let list3 = [
                { key: gt(this.state.modalCreateEdit.cIsUsed != '1' ? 15630 : 15821), value: this.state.modalCreateEdit.cDateTimeClosed },
                { key: gt(15631), value: yesNoTxt(this.state.modalCreateEdit.cIsUsed) },
                { key: gt(15632), value: this.state.modalCreateEdit.cDocNumber },
                { key: gt(15633), value: this.state.modalCreateEdit.cDocTypeId },
                { key: gt(15634), value: this.state.modalCreateEdit.cDocPosId + ' - ' + this.state.modalCreateEdit.cDocPosName },
                { key: gt(15822), value: this.state.modalCreateEdit.cDocBranch },
                { key: gt(15635), value: this.state.modalCreateEdit.cTransactionId },
            ];

            if (!this.state.modalMultiCoupons) {
                list3 = [
                    ...list3,
                    { key: gt(15636), value: this.getInput('cCustomerName', 'text') },
                    { key: gt(15637), value: this.getInput('cCustomerPhone', 'text') },
                    { key: gt(15638), value: this.getInput('cCustomerNumber', 'customer') },
                    { key: gt(15639), value: this.getInput('cCustomerMail', 'text') },
                ]
            }

            modalContent = [
                { span: 8, title: gt(15617), list: list1 },
                { span: 8, title: gt(15618), list: list2 },
                { span: 8, title: gt(15619), list: list3 },
            ]
        }

        let okButton = (<Button key={"create"} type="primary" onClick={this.handleOk} style={{ margin: 5 }}>{gt(15650)}</Button>);
        let cCount = this.state.modalMultiCoupons == 1 ? this.state.countCoupons : this.state.modalMultiCoupons == 2 ? this.state.custList.length : 0
        if (cCount > 1) {
            okButton = (<Popconfirm title={gt(15665) + ' ' + cCount + ' ' + gt(15666)} okText={gt(15652)} cancelText={gt(15653)} onConfirm={this.handleOk} >
                <Button key={"create"} type="primary" style={{ margin: 5 }}>{gt(15650)}</Button>
            </Popconfirm>)
        }

        let footerButtonsModal = false;
        let isUnlockPermision = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '513') ? true : false;

        if (!this.state.modalReadOnly) {
            footerButtonsModal = [
                <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(15649)}</Button>,
                okButton
            ]
        } else if (isUnlockPermision && this.state.modalCreateEdit && this.state.modalCreateEdit.cDateTimeClosed && this.state.modalCreateEdit.cDocPosId && this.state.modalCreateEdit.cIsUsed != '1') {
            footerButtonsModal = [
                <Popconfirm title={gt(18647)} okText={gt(15652)} cancelText={gt(15653)} onConfirm={this.handleUnlock} >
                    <Button key={"Unlock"} type="primary" style={{ margin: 5 }}>{gt(18648)}</Button>
                </Popconfirm>
            ]
        }

        let isUserAddPermision = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset ?
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '515')
            : false;

        let actionButtons = [
            {
                buttonType: 'filter',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchCoupons) }
            }, {
                buttonDisabled: !this.state.filterActive,
                buttonType: 'clear',
                buttonFunction: this.clearFilters,
            }
        ]

        let genericActionButtons = [this.reportButton()]


        if (isUserAddPermision) {
            actionButtons = [
                ...actionButtons,
                {
                    buttonType: 'add',
                    buttonFunction: () => {
                        this.setState({ modalCreateEdit: this.templateFields }, () => {
                            this.loadSelector(0);
                            this.loadSelector(2);
                            this.loadSelector(3, { cdType: 0 });
                        })
                    }
                },
            ]

            genericActionButtons = [
                <Tooltip title={gt(15656)}>
                    <Button style={{ marginTop: 5, marginLeft: 5 }} onClick={() => {
                        this.setState({ modalCreateEdit: this.templateFields, modalMultiCoupons: 1 }, () => {
                            this.loadSelector(0);
                            this.loadSelector(2);
                            this.loadSelector(3, { cdType: 0 });
                        })
                    }}><Icon style={{ fontSize: 16 }} type="block" /></Button>
                </Tooltip>,
                ...genericActionButtons
            ]
        }


        return (<div style={divStyle}>
            {this.props.isCouponCheckComponent ?
                <div>
                    <PageToolbar title={this.getText(18773)} actionButtons={[]} />
                    <h2>{this.getText(18774)}</h2>
                    <Input value={this.state.couponCheckInput} onChange={e => { this.setState({ couponCheckInput: e.target.value }) }} />
                    <Button type="primary" style={{ marginTop: 10 }} onClick={() => {
                        this.sendAPI(this.api.check_coupon, this.state.couponCheckInput, (ob) => {
                            let data = ob.data ? ob.data.split("\r") : []
                            if (data[1]) {
                                let keys = data[0].split("\f");
                                let values = data[1].split("\f");
                                let record = { key: values[0], index: 0 }
                                keys.forEach((x, i) => {
                                    record = { ...record, [x]: values[i] }
                                });

                                this.setState({ modalCreateEdit: record, modalReadOnly: true }, () => {
                                    this.loadSelector(0);
                                    this.loadSelector(1, { cDistributorId: record.cDistributorId });
                                    this.loadSelector(2);
                                    this.loadSelector(3, { cdType: 0 });
                                    this.props.dataActions.setJson(this.id, { data: [data[1]], headers: keys, count: 1 })
                                })
                            } else {
                                message.error(this.getText(18776))
                            }
                        })

                    }}>{this.getText(18775)}</Button>
                </div>
                : <GenericTable
                    {...this.props}
                    id={this.id}
                    getApi={this.api.get}
                    columns={columns}
                    filters={this.filters}
                    title={gt(15615)}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}
                    searchBox
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    percentageToMobile={[30, 40, 30]}
                    rowHeight={42}
                />}
            <GenericModal
                visible={this.state.modalCreateEdit !== null}
                onCancel={this.handleCancel}
                title={gt(this.state.modalReadOnly ? 18777 : this.state.modalMultiCoupons ? 15656 : 15616)}
                footer={footerButtonsModal}
                genericActionButtons={
                    this.state.modalCreateEdit && this.state.modalCreateEdit.cIsUsed != '1' ?
                        [
                            <Popconfirm title={this.getText(15651)} okText={this.getText(15652)} cancelText={this.getText(15653)} onConfirm={() => {
                                this.handleCancel()
                                this.props.dataActions.genericDataDeleteRow(
                                    this.id, this.state.modalCreateEdit.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                            }}>
                                <Button style={{ marginTop: 5, marginLeft: 5 }}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Button>
                            </Popconfirm>
                        ]
                        : []
                }>
                <Row>
                    <Col span={window.innerWidth > 600 ? 4 : 24}>
                        {this.state.modalMultiCoupons ?
                            (<Radio.Group value={this.state.modalMultiCoupons} onChange={(e) => { this.setState({ modalMultiCoupons: e.target.value }) }}>
                                <Radio value={1}>{gt(15657)}</Radio><br />
                                <Radio value={2}>{gt(15658)}</Radio><br />
                                <Radio value={3}>{gt(15667)}</Radio><br />
                            </Radio.Group>)
                            : ""}
                    </Col>
                    <Col span={window.innerWidth > 600 ? 8 : 24}>
                        {this.state.modalMultiCoupons == 1 ? (<InputNumber min={1} max={30000} value={this.state.countCoupons} onChange={(e) => { this.setState({ countCoupons: e }) }} />) :
                            this.state.modalMultiCoupons == 2 ? this.uploadExcelButton() :
                                this.state.modalMultiCoupons == 3 ? this.getPerCustTags()
                                    : ""}
                    </Col>
                    <Col span={window.innerWidth > 600 ? 3 : 24}>
                        <Form.Item label={this.getText(19528)}>
                            <InputNumber max={9999}
                                value={this.state.preCouponNumber}
                                onChange={e => { this.setState({ preCouponNumber: e }) }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 3 : 24}>
                        <Form.Item label={this.getText(19529)}>
                            <InputNumber min={6} max={10}
                                value={this.state.lenCouponNumber}
                                onChange={e => { this.setState({ lenCouponNumber: e }) }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 6 : 24}>
                        {this.state.modalMultiCoupons > 1 || (this.state.modalCreateEdit?.cCustomerNumber && !this.state.modalCreateEdit.cCode) ? (
                            <Checkbox
                                checked={this.state.mailingToCust}
                                onChange={(e) => { this.setState({ mailingToCust: e.target.checked }) }}>{gt(this.state.modalMultiCoupons ? 15669 : 15837)}</Checkbox>)
                            : ''}
                        {this.state.mailingToCust ?
                            (<Form.Item label={gt(15670)}>
                                <GenericSelector
                                    {...this.props} {...this.selectors[5]}
                                    value={this.state.mailingMassage}
                                    onChange={(e) => { this.setState({ mailingMassage: e }) }}
                                />
                            </Form.Item>)
                            : ''}
                    </Col>
                </Row>

                <GridKeyValues content={modalContent} />

            </GenericModal>
            <ModalSearchCoupons
                {...this.props} version2
                id={TableIDs.modalSearchCoupons}
                dataSetFilter={this.dataSetFilter}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchCoupons)}
                getParamsToReport={this.getParamsToReport}
                filterActive={this.state.filterActive}
                setFilterActive={e => {
                    if (e) this.setState({ filterActive: true })
                    else this.clearFilters()
                }}
            />
            <GenericModal
                visible={this.state.modalCustList}
                onCancel={() => { this.setState({ modalCustList: false }) }}
                width={1000}
                title={gt(15662) + " " + this.state.custList.length + " " + gt(15663)}>
                <ResponsiveTable
                    dataSource={this.state.custList}
                    columns={[
                        { title: gt(15638), key: 'A', dataIndex: 'A' },
                        { title: gt(15636), key: 'B', dataIndex: 'B' },
                        { title: gt(15637), key: 'C', dataIndex: 'C' },
                        { title: gt(15639), key: 'D', dataIndex: 'D' },
                    ]}
                    tableOnly
                    scroll={{ y: window.innerHeight / 2 }}
                    pagination={false}
                />
            </GenericModal>
            <ModalProgress
                id={TableIDs.modalProgress}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalProgress)}
                title={gt(17480)}
                handleCancel={() => {
                    this.progressStop = true;
                }}
                handleRefrash={() => { }}
                handleIgnore={() => { }}
                percent={Math.ceil((this.state.progressMultiCounter / this.state.progressMaxCounter) * 100)}
                status={"active"}
                message={gt(17479)}
                type={"exception"}
            />
        </div>)
    }

}

export default Coupons;