/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string
};

type State = {
    menuTag: any,
    menuItemsTop: Array<Object>,
    menuItemsDrop: Array<Object>
};

class SettingMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props: Props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            menuTag: (<div></div>),
            menuItemsTop: [
                { KeyComp: "187", key: "woo-commerce", text: "אתרי ווקמרס", keyLang: 17697, icon: "shop" },
                { KeyComp: "91", key: "hakafa-docs", text: "הגדרות הקפה", keyLang: 10203, icon: "setting" },
                { KeyComp: "82", key: "customer-fields", text: "שדות לקוחות", keyLang: 10215, icon: "setting" },
                // { KeyComp: "92", key: "edit-buttons", text: "עורך כפתורים", icon: "setting" },
                { KeyComp: "93", key: "standingOrders-defaults", text: "הגדרות קליטת קובץ חיובים", keyLang: 10204, icon: "setting" },
                { KeyComp: "94", key: "business-logic", text: "לוגיקות עסקיות", keyLang: 10205, icon: "setting" },
                { KeyComp: "96", key: "external-orders", text: "קליטת הזמנות", keyLang: 10207, icon: "setting" },
                { KeyComp: "98", key: "logo-settings", text: "הגדרת לוגו", keyLang: 10209, icon: "setting" },
                { KeyComp: "92", key: "price-list", text: "מחירונים", keyLang: 10211, icon: "setting" },
                { KeyComp: "144", key: "orders-site", text: "אתר הזמנות", keyLang: 13448, icon: "setting" },
                { KeyComp: "145", key: "import-excel-actions", text: "פעילויות לקליטה מאקסל", keyLang: 13874, icon: "setting" },
                { KeyComp: "147", key: "google-billing", text: "בילינג גוגל", keyLang: 14084, icon: "setting" },
                { KeyComp: "148", key: "payment-types", text: "אמצעי תקבול", keyLang: 14211, icon: "setting" },
                { KeyComp: "150", key: "export-excel", text: "יצוא לאקסל", keyLang: 14635, icon: "setting" },
                { KeyComp: "182", key: "priority", text: "פריוריטי", keyLang: 15251, icon: "setting" },
                { KeyComp: "183", key: "credit-terms", text: "מועדי תקבול אשראי", keyLang: 15282, icon: "setting" },
                { KeyComp: "184", key: "kds-configurations", text: "מערכת צגים להזמנות", keyLang: 15842, icon: "fund" },
                { KeyComp: "185", key: "customer-portal", text: "פורטל אישי ללקוח", keyLang: 15972, icon: "contacts" },
                { KeyComp: "186", key: "benefits", text: "מפיצי קופונים", keyLang: 17462, icon: "build" },
                { KeyComp: "188", key: "insert-users-log", text: "הצגת כניסות משתמשים", keyLang: 18856, icon: "eye" },
                //{ key: "earning-points", text: "צבירת נקודות", icon: "setting" },
                //{ key: "using-points", text: "שימוש בנקודות", icon: "setting" },
                //{ key: "customer-fields", text: "שדות לקוחות", icon: "setting" },
                //{ key: "customer-club", text: "מועדון לקוחות", icon: "setting" },
            ].filter((menuItem, index) => this.isActive(menuItem.KeyComp)),
            menuItemsDrop: []
        };
     }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/settings/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        let maxHeight = 50; //px
        let _height = -1;
        let firstIter = true;

        let listTop = this.state.menuItemsTop;
        let listDrop = this.state.menuItemsDrop;
        // re-populate menu
        for (let i = 0; i < listDrop.length; i++) {
            listTop.push(listDrop.pop());
        }

        //ReactDOM.findDOMNode(this).clientHeight;
        while (_height > maxHeight || _height < 0) {
            //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            if (!firstIter) {
                this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            }
            else {
                firstIter = false;
            }
            this.setState((prevState, props) => {
                return {
                    menuTag: <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[
                            this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                        ]}
                        onSelect={this.onMenuSelect.bind(this)}
                    >
                        {this.renderMenuItemsTop()}
                        <SubMenu title={<Badge count={listDrop.length}>
                            <span><Icon type="ellipsis" /></span></Badge>}>
                            {this.renderMenuItemsDrop()}
                        </SubMenu>
                    </Menu>
                }
            });
            console.log("findDomNode settings: ", (this) ? "true" : "false", this);
            let e: Element | null | Text = ReactDOM.findDOMNode(this);
            _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
            //alert(_height);
        }
    }

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }

    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        //window.addEventListener("resize", this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    // Remove event listener
    componentWillUnmount() {
        //window.removeEventListener("resize", this.updatePriorityMenu);
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
        return this.state.menuTag;
    }
}

export default withRouter(SettingMenu);
