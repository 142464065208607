/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Button, Checkbox, Input, message, Row } from "antd";
import { Col } from "antd/es/grid";
import ColorPicker from "../../components/ColorPicker";
import TableLineIcons from "../../components/TableLineIcons";


class ServiceCallStatuses extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "SERVICE_CALL_STATUSES";

        this.api = {
            get: "get_service_call_statuses",
            create_edit: "create_edit_service_call_statuses",
            delete: "delete_service_call_statuses",
            order: "order_service_call_statuses",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19934);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    renderName = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<div>
                <ColorPicker
                    title={this.getText(19958)}
                    value={editedRecord.sColor}
                    onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { sColor: e }) }}
                    style={{ width: '10%', marginLeft: 0 }}
                />
                <Input
                    value={editedRecord.sName}
                    onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { sName: e.target.value }) }}
                    style={{ width: '90%' }}
                />
            </div>)
        } else {
            return (<div style={{ background: record.sColor }}>{text}</div>)
        }
    }

    renderCheckbox = (text, record, field) => {
        const { editedRecord, editing } = this.props.data[this.id];

        return (<Checkbox
            disabled={editing != record.key}
            checked={editing == record.key ? editedRecord[field] == '1' : text == 1}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e.target.checked ? '1' : '0' }) }}
        />)
    }

    renderEditCol = (text, record) => {
        const { editing} = this.props.data[this.id];


        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={this.handelOk}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(19959), okText: this.getText(19960), cancelText: this.getText(19961) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
            />)
        }
    }

    handelOk = () => {
        const { editedRecord, data, headers } = this.props.data[this.id];
        const allData = data.map(x => {
            let y = x.split('\f');
            let obj = { key: y[0] }
            headers.forEach((h, i) => { obj = { ...obj, [h]: y[i] } })
            return obj;
        })

        if (!editedRecord.sName) {
            message.error(this.getText(19962))
        } else if (allData.find(f => f.key != editedRecord.key && f.sName == editedRecord.sName)) {
            message.error(this.getText(19963))
        } else if (allData.find(f => f.key != editedRecord.key && f.sIsActive == '1' && f.sOpen == '1' && editedRecord.sOpen == '1')) {
            message.error(this.getText(19964))
        } else if (allData.find(f => f.key != editedRecord.key && f.sIsActive == '1' && f.sClose == '1' && editedRecord.sClose == '1')) {
            message.error(this.getText(19965))
        } else if (editedRecord.sOpen == '1' && editedRecord.sClose == '1') {
            message.error(this.getText(19966))
        } else {
            this.props.dataActions.genericDataSaveEdit(
                this.id, this.props.user.companyCode, this.props.user.token,
                this.api.create_edit,
                this.api.get);
        }
    }


    render() {
        const gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const render = t => t;



        return (<div style={divStyle}>
            <ResponsiveTable
                title={gt(19951)}
                actionButtons={[
                    {
                        buttonType: 'add',
                        buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    },
                ]}
                idTable={this.props.data[this.id]}
                columns={[
                    { title: gt(19952), key: "sOrder", dataIndex: "sOrder", width: "10%", render },
                    { title: gt(19953), key: "sName", dataIndex: "sName", width: "50%", render: this.renderName },
                    { title: gt(19954), key: "sOpen", dataIndex: "sOpen", width: "10%", render: (t, r) => this.renderCheckbox(t, r, 'sOpen') },
                    { title: gt(19955), key: "sClose", dataIndex: "sClose", width: "10%", render: (t, r) => this.renderCheckbox(t, r, 'sClose') },
                    { title: gt(19956), key: "sIsActive", dataIndex: "sIsActive", width: "10%", render: (t, r) => this.renderCheckbox(t, r, 'sIsActive') },
                    { title: gt(19957), width: "10%", render: this.renderEditCol },
                ]}
                pagination={false}
                scroll={{ y: window.innerHeight - 250 }}
                id={this.id}
                onDragAndDrop={(keyFrom, keyTo) => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        this.api.order, this.api.get,
                        { _id: keyFrom, _toId: keyTo })
                }}
            />
        </div>)
    }

}

export default ServiceCallStatuses;