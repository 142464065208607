/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import PageToolbar from "../../components/PageToolbar";
import "../../App.css";
import { Button, DatePicker, Select, Icon, Col, Row, Card, Switch, Input, message } from "antd";
import moment from "moment";
import DataMultiSelectModal from "../../components/DataMultiSelectModal";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import LangContext from "../../contextProvider/LangContext";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { disabledDatePicker } from "../../utils/utils";
import DateTimePicker from "../../components/DateTimePicker";

const Option = Select.Option;
const OptGroup = Select.OptGroup;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};
type State = {
    lowerDate: string,
    upperDate: string,
    filterSelection: number,
    locations: Array<string>,
    customerList: Array<number>,
    preferredMethod: number,
    predefinedMessage: number,
    couponId: -1,
    isMultiSelectOpen: boolean,
    isSmsForbidden: boolean,
    capabilities: {
        total: number,
        push: number,
        sms?: number,
        email: number
    },
    areResultsValid: boolean,
    couponSentCount: number,
    messageSentCount: number,
    parameter1: string,
    parameter2: string,
    textMessageBalance: number,
    branchList: Array<number>
};

class MailingList extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lowerDate: "",
            upperDate: "",
            filterSelection: 0,
            locations: [],
            customerList: [],
            preferredMethod: 0,
            predefinedMessage: -1,
            couponId: -1,
            isMultiSelectOpen: false,
            isSmsForbidden: true,
            capabilities: {
                total: -1,
                push: -1,
                sms: -1,
                email: -1
            },
            areResultsValid: false,
            couponSentCount: -1,
            messageSentCount: -1,
            parameter1: "",
            parameter2: "",
            requierdDateTime: null,
            textMessageBalance: -1,
            tagList: '',
            branchList: []
        };

        this.selectors = [
            { id: TableIDs.genericMultiSelectorCustomerTags, api: "get_customer_tags_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, "get_storage_list"]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorBranches, this.props.user.companyCode, this.props.user.token, "get_branch_list"]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, {
                script: "get_all_customers_by_branch",
                params: { mCreatingBranchList: "" }
            }, "Customers", {
                loading: "",
                failed: "Error loading",//"חלה שגיאה בטעינת הנתונים",
                success: ""
            }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    json.push({
                        id: parseInt(row[0], 10),
                        firstName: row[1],
                        lastName: row[2],
                        contactPerson: row[3],
                        address: row[4],
                        zipCode: parseInt(row[5], 10),
                        phone: row[6].length > 0 ? row[6] : row[7]
                    });
                }
                return Jax.set(store, "Customers", json);
            }]
        });


    }

    getCustomerList = (branchList) => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, {
                script: "get_all_customers_by_branch",
                params: { mCreatingBranchList: branchList }
            }, "Customers", {
                loading: "",
                failed: "Error loading",//"חלה שגיאה בטעינת הנתונים",
                success: ""
            }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                if (data) {
                    for (let i = 0, j = arr.length; i < j; i++) {
                        let row = arr[i].split("\f");
                        json.push({
                            id: parseInt(row[0], 10),
                            firstName: row[1],
                            lastName: row[2],
                            contactPerson: row[3],
                            address: row[4],
                            zipCode: parseInt(row[5], 10),
                            phone: row[6].length > 0 ? row[6] : row[7]
                        });
                    }
                }
                return Jax.set(store, "Customers", json);
            }]
        });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }
    componentDidMount() {
        this.getBalance();
    }
    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10176);
    }

    isValidMsg() {
        if (!this.state.predefinedMessage || this.state.predefinedMessage <= 0) {
            message.error('לא נבחרה הודעה למשלוח');
            return false;
        }
        return true;
    }

    isValidMethod() {
        if (!this.state.preferredMethod || this.state.preferredMethod <= 0) {
            message.error('לא סומן אמצעי שילוח');
            return false;
        }
        return true;
    }

    isValidCoupon() {
        if (!this.state.couponId || this.state.couponId <= 0) {
            message.error('לא נבחר קופון למשלוח');
            return false;
        }
        return true;
    }

    parameterBuilder(request: Object) {
        request.setColumn("mode", this.state.filterSelection);
        request.setColumn("lowerDate", this.state.lowerDate);
        request.setColumn("upperDate", this.state.upperDate);
        request.setColumn("storageList", this.state.locations.join(","));
        request.setColumn("customerList", this.state.customerList.join(","));
        request.setColumn("preferredMethod", this.state.preferredMethod);
        request.setColumn("predefinedMessage", this.state.predefinedMessage);
        request.setColumn("couponId", this.state.couponId);
        request.setColumn("parameter1", this.state.parameter1);
        request.setColumn("parameter2", this.state.parameter2);
        request.setColumn("requierdDateTime", this.state.requierdDateTime);
        request.setColumn("tagList", this.state.tagList);
        request.setColumn("branchList", this.state.branchList.join(","));
    }

    // (script: string, companyCode: string, token: string, payloadBuilder: Function, stateBuilder: Function, messages: { loading: string, failed: string, success: string })

    query() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericNetworkRequest,
            args: [
                "mailing_list_query",
                this.props.user.companyCode,
                this.props.user.token,
                this.parameterBuilder.bind(this),
                function (state, error, data) {
                    let oState = state;
                    if (error != null) {
                        console.error(error.errorCode, error.errorMessage);
                    } else {
                        window.console.log(data);
                        let oData = JSON.parse(data);
                        oState = Jax.set(state, "availableMessages", oData.messages);
                        oState = Jax.set(oState, "availableCoupons", oData.coupons);
                        let localState = Jax.set(this.state, "isSmsForbidden", oData.isSmsForbidden === 1);
                        localState = Jax.set(localState, "areResultsValid", true);
                        this.setState(Jax.set(localState, "capabilities", oData.capabilities));
                    }

                    ///console.log("vvvvv state: ", oState);

                    return oState;
                }.bind(this),
                {
                    loading: this.getText(12277),
                    failed: this.getText(12278),
                    success: ""
                }
            ]
        });
    }

    getBalance() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericNetworkRequest,
            args: [
                "mailing_get_sms_balance",
                this.props.user.companyCode,
                this.props.user.token,
                this.parameterBuilder.bind(this),
                function (state, error, data) {
                    let oState = state;
                    if (error != null) {
                        console.error(error.errorCode, error.errorMessage);
                    } else {
                        window.console.log(data);
                        let oData = data.split("\r");
                        let oRow = oData[1].split("\f");
                        let iTextMessageQuota = parseInt(oRow[0], 10);
                        let iTextMessageUsage = parseInt(oRow[1], 10);
                        let textMessageBalance = iTextMessageQuota - iTextMessageUsage;

                        oState = Jax.set(state, "textMessageBalance", textMessageBalance);
                        this.setState(Jax.set(this.state, "textMessageBalance", textMessageBalance));
                    }
                    return oState;
                }.bind(this),
                {
                    loading: this.getText(12277),
                    failed: this.getText(12278),
                    success: ""
                }
            ]
        });
    }

    sendMessage() {
        this.setState(
            Jax.set(this.state, "messageSentCount", -1)
            , function () {
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericNetworkRequest,
                    args: [
                        "mailing_list_send_message",
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.parameterBuilder.bind(this),
                        function (state, error, data) {
                            if (error != null) {
                                console.error(error.errorCode, error.errorMessage);
                            } else {
                                this.setState(
                                    Jax.set(this.state, "messageSentCount", parseInt(data, 10))
                                );

                                this.getBalance();
                            }
                            return state;
                        }.bind(this),
                        {
                            loading: this.getText(12279),
                            failed: this.getText(12280),
                            success: this.getText(12281)
                        }
                    ]
                });
            }.bind(this));

    }

    sendCoupon() {
        this.setState(
            Jax.set(this.state, "couponSentCount", -1)
            , function () {
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericNetworkRequest,
                    args: [
                        "mailing_list_send_coupon",
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.parameterBuilder.bind(this),
                        function (state, error, data) {
                            if (error != null) {
                                console.error(error.errorCode, error.errorMessage);
                            } else {
                                this.setState(
                                    Jax.set(this.state, "couponSentCount", parseInt(data, 10))
                                )
                            }
                            return state;
                        }.bind(this),
                        {
                            loading: this.getText(12282),
                            failed: this.getText(12283),
                            success: this.getText(12284)
                        }
                    ]
                });
            }.bind(this));

    }

    exportData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericNetworkRequest,
            args: [
                "mailing_list_export_data",
                this.props.user.companyCode,
                this.props.user.token,
                this.parameterBuilder.bind(this),
                function (state, error, data) {
                    if (error != null) {
                        console.error(error.errorCode, error.errorMessage);
                    } else {
                        let csv = new Blob(["\ufeff", data], { type: "text/csv;charset=utf8" });
                        const a = document.createElement("a");
                        if (document.body) {
                            document.body.appendChild(a);
                        }
                        a.style.display = "none";

                        let url = window.URL.createObjectURL(csv);
                        a.href = url;
                        a.download = "MailingList.csv";
                        a.click();
                        window.URL.revokeObjectURL(url);

                    }
                    return state;
                },
                {
                    loading: this.getText(12285),
                    failed: this.getText(12286),
                    success: this.getText(12287)
                }
            ]
        });
    }

    getDatePicker = (field) => {
        let minDate = field == 'upperDate' ? this.state.lowerDate : null
        let maxDate = field == 'lowerDate' ? this.state.upperDate : null
        return (<DatePicker
            disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
            defaultValue={this.state[field].length > 0 ? moment(this.state[field], "YYYY-MM-DD") : null}
            format={"DD/MM/YYYY"} onChange={(date: moment, dateString: string) => {
                this.setState(Jax.set(this.state, field, date != null ? date.format("YYYY-MM-DD") : ""));
            }} />)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dateFormat = "DD/MM/YYYY";
        const sqlFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";

        let listBranch = Jax.get(this.props.data, TableIDs.genericSelectorBranches + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let filteredCustomers = Jax.get(this.props.data, "Customers", []).filter((obj) => {
            return this.state.customerList.indexOf(obj.id) > -1
        });
        let listCustomers = filteredCustomers.map((obj) => {
            window.console.log(obj);
            return (<Option key={obj.id} value={obj.id}>{obj.firstName + " " + obj.lastName}</Option>)
        });

        let listMessages = Jax.get(this.props.data, "availableMessages", []).map((obj) => {
            return (<Option key={obj.id} value={obj.id}>{obj.value}</Option>)
        });

        let listCoupons = Jax.get(this.props.data, "availableCoupons", []).map((obj) => {
            return (<Option key={obj.id} value={obj.id}>{obj.value}</Option>)
        });

        let resSpan = (num) => window.innerWidth > 600 ? num : 24;

        console.log('test customers: ',
            Jax.map(this.props.data, "Customers", function (v) {
                return v.id + "\f" + v.firstName + " " + v.lastName;
            }))

        return (
            <div style={divStyle}>

                <PageToolbar title={this.getText(12288)} actionButtons={[
                    {
                        buttonType: "refresh",
                        buttonFunction: () => {
                            this.props.ActionQueue.addToQueue({
                                action: this.props.dataActions.networkFetch,
                                args: [this.props.user.companyCode, this.props.user.token, {
                                    script: "mailing_send_pending",
                                    params: {}
                                }, "reSend", {
                                    loading: "",
                                    failed: this.getText(12289),
                                    success: this.getText(12290)
                                }, false, null, function (actionName, store, data) {
                                    //let arr = data.split("\r");
                                    let json = [];
                                    /*
                                    for (let i = 0, j = arr.length; i < j; i++) {
                                        let row = arr[i].split("\f");
                                        json.push({
                                            id: parseInt(row[0], 10),
                                            firstName: row[1],
                                            lastName: row[2],
                                            contactPerson: row[3],
                                            address: row[4],
                                            zipCode: parseInt(row[5], 10),
                                            phone: row[6].length > 0 ? row[6] : row[7]
                                        });
                                    }
                                    */
                                    return Jax.set(store, "reSend", json);
                                }]
                            });
                        }
                    }
                ]} isOfModal={false} setSearch={null} />

                <Row>
                    {/* MAIN SELECTOR */}
                    <Row style={{ marginBottom: 8 }}>
                        <Select key={"filterType"} defaultValue={0} onChange={(value) => {
                            if (value > 0) {
                                let newState = Jax.set(this.state, "filterSelection", value);
                                newState = Jax.set(newState, "areResultsValid", false);
                                this.setState(newState);
                            }
                        }}
                            style={{ display: "block" }}>
                            <Option value={0}>- {this.getText(12291)} -</Option>
                            {/*
                            <OptGroup key={"prospective"} label={"לקוחות פוטנציאליים"}>
                                <Option value={1}>כל הלקוחות הפוטנציאליים</Option>
                                <Option value={2}>לקוחות פוטנציאליים לפי תאריך הצטרפות</Option>
                            </OptGroup>
                            */}
                            <OptGroup key={"customers"} label={this.getText(12292)}>
                                <Option value={3}>{this.getText(12293)}</Option>
                                <Option value={4}>{this.getText(12294)}</Option>
                                <Option value={5}>{this.getText(12295)}</Option>
                                <Option value={8}>{this.getText(12296)}</Option>
                                <Option value={6}>{this.getText(12297)}</Option>
                                <Option value={9}>{this.getText(16076)}</Option>
                                <Option value={10}>{this.getText(16737)}</Option>
                            </OptGroup>
                            {/*
                            <OptGroup label={"כולם"}>
                                <Option value={7}>כל הלקוחות והלקוחות הפוטנציאליים</Option>
                            </OptGroup>
                            */}
                        </Select>
                    </Row>

                    {/* FILTERS */}
                    <Row gutter={16} style={{ marginBottom: 4, display: this.state.filterSelection === 2 ? "block" : "none" }}>
                        <Col span={8}>
                            {this.getText(12298)} -
                        </Col>
                        <Col span={8}>
                            {this.getText(12299)}:
                            {this.getDatePicker("lowerDate")}
                        </Col>
                        <Col span={8}>
                            {this.getText(12300)}:
                            {this.getDatePicker("upperDate")}
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: 4, display: this.state.filterSelection === 3 ? "block" : "none" }}>
                        <Col span={8}>
                            {this.getText(12301)}:
                        </Col>
                        <Col span={16}>
                            <Select key={"warehouses"} style={{ width: "100%" }}
                                mode="multiple"
                                onChange={(value) => {
                                    this.setState(Jax.set(this.state, "locations", value))
                                }}
                                value={this.state.locations}
                                notFoundContent={this.getText(12302)}
                                placeholder={this.getText(12303)}

                            >
                                {listStorage}
                            </Select>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: 4, display: this.state.filterSelection === 4 ? "block" : "none" }}>
                        <Col span={8}>
                            {this.getText(12304)}:
                        </Col>
                        <Col span={16}>
                            <Select key={"customers"} style={{ width: "80%", marginLeft: 8 }}
                                mode="multiple"
                                maxTagCount={6}
                                onChange={(value) => {
                                    this.setState(Jax.set(this.state, "customerList", value))
                                }}
                                value={this.state.customerList}
                                notFoundContent={this.getText(12305)}
                                showArrow={false}

                            >
                                {listCustomers}
                            </Select>
                            <Button htmlType={"button"} onClick={() => {
                                this.setState(
                                    Jax.set(this.state, "isMultiSelectOpen", true)
                                );
                            }}><Icon type="plus" /> {this.getText(12306)}</Button>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: 4, display: this.state.filterSelection === 5 ? "block" : "none" }}>
                        <Row gutter={16} style={{ marginBottom: 4 }}>
                            <Col span={8}>
                                {this.getText(12307)} -
                            </Col>
                            <Col span={8}>
                                {this.getText(12299)}:
                                {this.getDatePicker("lowerDate")}
                            </Col>
                            <Col span={8}>
                                {this.getText(12300)}:
                                {this.getDatePicker("upperDate")}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>{this.getText(12308)}:</Col>
                            <Col span={16}>
                                <Select key={"storages"} style={{ width: '90%' }}
                                    mode="multiple"
                                    onChange={(value) => {
                                        this.setState(Jax.set(this.state, "locations", value))
                                    }}
                                    value={this.state.locations}
                                    notFoundContent={this.getText(12302)}
                                >
                                    {listStorage}
                                </Select>
                            </Col>
                        </Row>
                    </Row>

                    <Row style={{ marginBottom: 4, display: this.state.filterSelection === 8 ? "block" : "none" }}>
                        <Row gutter={16}>
                            <Col span={8}>{this.getText(12309)} -</Col>
                            <Col span={8}>
                                {this.getText(12299)}:
                                {this.getDatePicker("lowerDate")}
                            </Col>
                            <Col span={8}>
                                {this.getText(12300)}:
                                {this.getDatePicker("upperDate")}
                            </Col>
                        </Row>

                    </Row>

                    <Row style={{ marginBottom: 4, display: this.state.filterSelection === 6 ? "block" : "none" }}>
                        <Row gutter={16}>
                            <Col span={8}>
                                {this.getText(12310)} -
                            </Col>
                            <Col span={8}>
                                {this.getText(12299)}:
                                {this.getDatePicker("lowerDate")}
                            </Col>
                            <Col span={8}>
                                {this.getText(12300)}:
                                {this.getDatePicker("upperDate")}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                {this.getText(12311)}:
                            </Col>
                            <Col span={"16"}>
                                <Select key={"locations"} style={{ width: "100%" }}
                                    mode="multiple"
                                    onChange={(value) => {
                                        this.setState(Jax.set(this.state, "locations", value))
                                    }}
                                    value={this.state.locations}
                                    notFoundContent={this.getText(12302)}
                                >
                                    {listStorage}
                                </Select>
                            </Col>
                        </Row>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: 4, display: this.state.filterSelection === 9 ? "block" : "none" }}>
                        <Col span={8}>
                            {this.getText(16077)}:
                        </Col>
                        <Col span={16}>
                            <GenericSelectorCustTags
                                {...this.props}
                                // {...this.selectors[0]}
                                // optionsGroup
                                multi
                                maxTagCount={10}
                                value={this.state.tagList ? this.state.tagList.split(',') : []}
                                onChange={(e) => { this.setState({ tagList: e ? e.join(',') : '' }) }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: 4, display: this.state.filterSelection === 10 ? "block" : "none" }}>
                        <Row gutter={16}>
                            <Col span={8}>{this.getText(16738)} -</Col>
                            <Col span={8}>
                                {this.getText(16739)}:
                                {this.getDatePicker("lowerDate")}
                            </Col>
                            <Col span={8}>
                                {this.getText(16740)}:
                                {this.getDatePicker("upperDate")}
                            </Col>
                        </Row>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: 4, display: this.state.filterSelection !== 0 ? "block" : "none" }}>
                        <Col span={8}>{this.getText(18347)}:</Col>
                        <Col span={16}>
                            <Select key={"branches"} style={{ width: '90%' }}
                                mode="multiple"
                                onChange={(value) => {
                                    //this.setState(Jax.set(this.state, "branchList", value))
                                    //this.setState(Jax.set(this.state, "customerList", []))
                                    this.setState({ ...this.state, "branchList": value, "customerList": [] })
                                    this.getCustomerList(value);
                                }}
                                value={this.state.branchList}
                                notFoundContent={this.getText(18348)}
                                placeholder={this.getText(18350)}>
                                <Option key={0} value={0}>{'0' + '-' + this.getText(18349)}</Option>
                                {listBranch}
                            </Select>
                        </Col>
                    </Row>

                    {/* FILTER BUTTON */}
                    <Row>
                        <Button onClick={() => {
                            if (this.state.filterSelection > 0) {
                                // let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']);
                                // if (!this.state.locations[0])
                                //     this.setState({ locations: fullStoragesList }, () => { this.query() })
                                // else
                                this.query();
                            }
                        }
                        } loading={this.props.data.ongoingRequests > 0}
                            type={"primary"}
                            style={{ float: "left", marginTop: "8px" }}
                            htmlType={"button"}>
                            {this.getText(12312)}
                            <Icon type="left-circle" style={{ marginLeft: "auto", marginRight: "8px" }} />
                        </Button>
                        {this.state.areResultsValid ? <span className="result-count"> {this.getText(12313) + " " + this.state.capabilities.total + " " + this.getText(12314)}</span> : null}
                    </Row>

                    <Row gutter={16} style={{ marginTop: "16px", display: this.state.areResultsValid ? "block" : "none" }}>
                        <Col span={resSpan(8)}>
                            <Card title={this.getText(12315)}>
                                <div>
                                    <Select key={"messages"} notFoundContent={this.getText(12316)}
                                        onChange={(value) => {
                                            this.setState(
                                                Jax.set(this.state, "predefinedMessage", parseInt(value, 10))
                                            )
                                        }}
                                        style={{ width: "90%", display: "block", margin: "0 auto" }}>
                                        {listMessages}
                                    </Select>
                                    <div className={"messageParameters"}>
                                        <Input placeholder={this.getText(12317)} onChange={(e) => { this.setState(Jax.set(this.state, "parameter1", e.target.value)) }} style={{ width: "90%", display: "block", margin: "5px auto" }} />
                                        <Input placeholder={this.getText(12318)} onChange={(e) => { this.setState(Jax.set(this.state, "parameter2", e.target.value)) }} style={{ width: "90%", display: "block", margin: "5px auto" }} />
                                        <DateTimePicker
                                            style={{ width: "90%", display: "block", margin: "5px auto" }}
                                            format="DD/MM/YYYY HH:mm:ss"
                                            showTime
                                            placeholder={this.getText(18315)}
                                            value={this.state.requierdDateTime}
                                            onChange={(e) => { this.setState(Jax.set(this.state, "requierdDateTime", e)) }}
                                        />
                                    </div>



                                    <Row style={{ width: "90%", margin: "8px auto 0 auto" }}>
                                        <Col span={"20"}>{this.getText(12319)}
                                            <em> ({this.state.capabilities.push})</em>
                                        </Col>
                                        <Col>
                                            {this.state.preferredMethod === 8 ? <Switch checked={true} disabled /> :
                                                <Switch onChange={(value) => {
                                                    this.setState(
                                                        Jax.set(this.state, "preferredMethod", (value ? this.state.preferredMethod + 1 : this.state.preferredMethod - 1))
                                                    );
                                                }} />}
                                        </Col>
                                    </Row>
                                    {this.state.isSmsForbidden ? null :
                                        <Row style={{ width: "90%", margin: "0px auto 0 auto" }}>
                                            <Col span={"20"}>{this.getText(12320)}
                                                <em> ({this.state.capabilities.sms})</em>
                                                <div className={"textMessageBalance"} style={{ marginRight: "1em", fontSize: "0.8em" }}>
                                                    {this.getText(12321)}:

                                                    <em>{this.state.textMessageBalance}</em>
                                                </div>
                                            </Col>
                                            <Col>
                                                {this.state.preferredMethod === 8 ?
                                                    <Switch checked={true} disabled /> :
                                                    <Switch onChange={(value) => {
                                                        this.setState(
                                                            Jax.set(this.state, "preferredMethod", (value ? this.state.preferredMethod + 2 : this.state.preferredMethod - 2))
                                                        );
                                                    }} />}
                                            </Col>
                                        </Row>
                                    }
                                    <Row style={{ width: "90%", margin: "0px auto 0 auto" }}>
                                        <Col span={"20"}>{this.getText(12322)}
                                            <em> ({this.state.capabilities.email})</em>
                                        </Col>
                                        <Col>
                                            {this.state.preferredMethod === 8 ? <Switch disabled checked={true} /> :
                                                <Switch onChange={(value) => {
                                                    this.setState(
                                                        Jax.set(this.state, "preferredMethod", (value ? this.state.preferredMethod + 4 : this.state.preferredMethod - 4))
                                                    );
                                                }} />}
                                        </Col>
                                    </Row>
                                    {this.state.isSmsForbidden ? null :
                                        <Row style={{ width: "90%", margin: "0px auto 8px auto" }}>
                                            <Col span={"20"}>{this.getText(12323)}</Col>
                                            <Col><Switch onChange={(value) => {
                                                this.setState(
                                                    Jax.set(this.state, "preferredMethod", (value ? 8 : 7))
                                                );
                                            }} /></Col>
                                        </Row>
                                    }
                                </div>
                                <Button
                                    htmlType={"button"}
                                    onClick={() => {
                                        this.sendMessage()
                                    }}
                                    disabled={!this.state.predefinedMessage || !this.state.preferredMethod}
                                    loading={this.props.data.ongoingRequests > 0}
                                    style={{ float: "left", marginLeft: "5%", marginTop: "8px" }}
                                >
                                    {this.getText(12324)}
                                    <Icon type="message" style={{ marginLeft: "auto", marginRight: "8px" }} />
                                </Button>
                                {this.state.messageSentCount > -1 ? <div className={"recipient-count"}>{this.getText(12325) + " " + this.state.messageSentCount + " " + this.getText(12326)}  </div> : null}
                            </Card>
                        </Col>

                        <Col span={resSpan(8)}>
                            <Card title={this.getText(12327)}>
                                <div>
                                    <Select key={"coupons"} notFoundContent={this.getText(12328)}
                                        onChange={(value) => {
                                            this.setState(
                                                Jax.set(this.state, "couponId", parseInt(value, 10))
                                            )
                                        }}
                                        style={{ width: "90%", display: "block", margin: "0 auto" }}>
                                        {listCoupons}
                                    </Select>
                                </div>
                                <Button htmlType={"button"} onClick={() => {
                                    if (!this.isValidCoupon()) { return; }
                                    //if (!this.isValidMethod()) { return; }
                                    this.sendCoupon()
                                }} loading={this.props.data.ongoingRequests > 0}
                                    style={{ float: "left", marginLeft: "5%", marginTop: "8px" }}>
                                    {this.getText(12329)}
                                    <Icon type="mail" style={{ marginLeft: "auto", marginRight: "8px" }} />
                                </Button>
                                {this.state.couponSentCount > -1 ? <div className={"recipient-count"}>{this.getText(12325) + " " + this.state.couponSentCount + " " + this.getText(12326)}</div> : null}
                            </Card>
                        </Col>

                        <Col span={resSpan(8)}>
                            <Card title={this.getText(12330)}>
                                <Button htmlType={"button"} onClick={() => {
                                    this.exportData()
                                }} loading={this.props.data.ongoingRequests > 0}
                                    style={{ float: "left", marginLeft: "5%", marginTop: "8px" }}>
                                    {this.getText(12331)}
                                    <Icon type="download" style={{ marginLeft: "auto", marginRight: "8px" }} />
                                </Button>
                            </Card>
                        </Col>
                    </Row>

                    <DataMultiSelectModal addToSelection={(id) => {
                        let key = parseInt(id, 10);
                        let state = Jax.splice(this.state, "customerList", this.state.customerList.indexOf(key), 1);
                        this.setState(
                            Jax.push(state, "customerList", key)
                        )
                    }} removeFromSelection={(id) => {
                        let key = parseInt(id, 10);
                        this.setState(
                            Jax.splice(this.state, "customerList", this.state.customerList.indexOf(key), 1)
                        )
                    }}
                        clearSelection={() => {
                            this.setState(
                                Jax.set(this.state, "customerList", [])
                            );
                        }}
                        closeDialog={() => {
                            this.setState(
                                Jax.set(this.state, "isMultiSelectOpen", false)
                            );
                        }}
                        data={Jax.map(this.props.data, "Customers", function (v) {
                            return v.id + "\f" + v.firstName + " " + v.lastName + "\f" + v.phone;
                        })}
                        moreColumns={[{key: "phone", title: this.getText(19624), index: 2}]}
                        keyColumnLabel={this.getText(12332)}
                        nameColumnLabel={this.getText(12333)}
                        title={this.getText(12334)}
                        searchPlaceholder={this.getText(12335)}
                        tableNotFound={this.getText(12336)}
                        zIndex={50}
                        visible={this.state.isMultiSelectOpen}
                        selected={this.state.customerList}
                        closeButton={this.getText(12337)}
                        closeButtonIsPrimary
                    />
                </Row>
            </div>
        );
    }
}

export default MailingList;