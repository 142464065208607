/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string
};

type State = {
    menuTag: any,
    menuItemsTop: Array<Object>,
    menuItemsDrop: Array<Object>
};

class ReportMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props: Props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            menuTag: (<div></div>),
            menuItemsTop: [
                { KeyComp: "11", key: "doc-create", text: "יצירת מסמך", keyLang: 10162, icon: "setting" },
                { KeyComp: "12", key: "doc-report-copy", text: "העתקי מסמך", keyLang: 10163, icon: "setting" },
                { KeyComp: "13", key: "doc-fix-lab", text: "מודול תיקונים", keyLang: 10164, icon: "tool" },
                { KeyComp: "14", key: "orders", text: "הזמנות", keyLang: 12789, icon: "carry-out" },
                { KeyComp: "15", key: "food-orders", text: "אתר הזמנות", keyLang: 12934, icon: "chrome" },
                { KeyComp: "17", key: "new-orders", text: "הזמנות חדשות", keyLang: 14478, icon: "select" },
                { KeyComp: "18", key: "deliveries", text: "משלוחים", keyLang: 14591, icon: "car" },
                { KeyComp: "16", key: "inventories", text: "הכרזת ספירת מלאי", keyLang: 13906, icon: "snippets" },
                { KeyComp: "19", key: "transfers-pending", text: "העברות ממתינות", keyLang: 16079, icon: "select" },
                { KeyComp: "20", key: "questionnaires", text: "שאלונים", keyLang: 16294, icon: "file-unknown" },
                { KeyComp: "201", key: "sum-deliverie-docs", text: "הפקת חשבוניות מרכזות", keyLang: 16745, icon: "file-search" },
                { KeyComp: "202", key: "transactions", text: "שוברי אשראי", keyLang: 16909, icon: "credit-card" },
                { KeyComp: "203", key: "meters", text: "מונים", keyLang: 17666, icon: "project" },
                { KeyComp: "204", key: "documents-cancelled", text: "דו\"ח ביטולים", keyLang: 19119, icon: "file-exclamation" },
                { KeyComp: "205", key: "fix-remarks", text: "הערות קבועות", keyLang: 19228, icon: "setting" },
                { KeyComp: "206", key: "k100", text: "הפקת קובץ 100", keyLang: 19778, icon: "file-pdf" },
            ].filter((menuItem, index) => this.isActive(menuItem.KeyComp)),
            menuItemsDrop: []
        };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/documents/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        if (window.location.pathname.indexOf("/food-orders") == -1) {
            let maxHeight = 50; //px
            let _height = -1;
            let firstIter = true;

            let listTop = this.state.menuItemsTop;
            let listDrop = this.state.menuItemsDrop;
            // re-populate menu
            for (let i = 0; i < listDrop.length; i++) {
                listTop.push(listDrop.pop());
            }

            //ReactDOM.findDOMNode(this).clientHeight;
            while (_height > maxHeight || _height < 0) {
                //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
                if (!firstIter) {
                    this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
                }
                else {
                    firstIter = false;
                }
                this.setState((prevState, props) => {
                    return {
                        menuTag: <Menu
                            mode="horizontal"
                            defaultSelectedKeys={[
                                this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                            ]}
                            onSelect={this.onMenuSelect.bind(this)}
                        >
                            {this.renderMenuItemsTop()}
                            <SubMenu title={<Badge count={listDrop.length}><span><Icon type="ellipsis" /></span></Badge>}>
                                {this.renderMenuItemsDrop()}
                            </SubMenu>
                        </Menu>
                    }
                });
                console.log("findDomNode docs: ", (this) ? "true" : "false", this);
                let e: Element | null | Text = ReactDOM.findDOMNode(this);
                _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
                //alert(_height);
            }
        }
    }

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }

    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        //window.addEventListener("resize", this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    // Remove event listener
    componentWillUnmount() {
        //window.removeEventListener("resize", this.updatePriorityMenu);
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
        return this.state.menuTag;
    }
}

export default withRouter(ReportMenu);
