/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Checkbox, Popconfirm, Button, Tooltip, message, Radio, Popover, Input, Modal } from "antd";
import TableIDs from "../../data/TableIDs";
import ModalCreateEditItem from "../../components/ModalCreateEditItem";
import ModalAdvanceSearch from "../../components/ModalAdvanceSearch";
import LabelPrinter from "../../components/LabelPrinterV2";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalMatrixReverse from "../../components/ModalMatrixReverse";
import LangContext from "../../contextProvider/LangContext";
import ModalSalesAlert from "../../components/ModalSalesAlert";
import ModalDownloadPLUfile from "../../components/ModalDownloadPLUfile";
import ModalDownloadPricerfile from "../../components/ModalDownloadPricerfile";
import RecyclingBin from "../../components/RecyclingBin";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ModalConnectedItems from "./ModalConnectedItems";
import StockItemCard from "../reports/StockItemCardReport";
import { escapeRegExp } from "../../utils/utils";
import GenericModal from "../../components/GenericModal";
import ModalCreateEditItemStock from "../../components/ModalCreateEditItemStock";
import ModalItemMivzaim from "../../components/ModalItemMivzaim";
import PictureView from "../../components/PictureView";
import moment from "moment";

//const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 50;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class ItemsTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            isValidItemCode: false,
            searchFilter: '',
            itemLabelData: null,
            itemIdToPrint: 0,
            itemNameToPrint: '',
            mParentItemCode: null,
            height: window.innerHeight,
            modalConnectedItems: false,
            isConnectedItems: null,
            tagToConnectItems: null,
            searchColFilter: "1",
            valueSearch: '',
            modalStockItemCard: '',
            modalStockItemCardName: '',
            modalStockItemCardStorageList: '',
            filterActive: false,
            modalChangeBtnText: null,

            modalInventoryLimits: null,
            modalMivzaim: null,

        }

        this.doOnResize = null;

        this.id = "ITEMS_TABLE";
        this.recycling_id = "ITEMS_DELETION_RECYCLING";
        this.connected_id = "CONNECTED_ITEMS";
        this.stock_id = "STOCK_ITEM_CARD";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };
        if (props.data[this.connected_id] === undefined) props.data[this.connected_id] = { ...props.data.genericData };
        if (props.data[this.stock_id] === undefined) props.data[this.stock_id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };

        this.api = {
            get: "get_items_list",
            delete: "delete_item",

            get_recycling: "get_items_deletion_recycling",
            restoration: "restoration_items",

            get_connected: "get_connected_items",
            edit_connected: "edit_connected_items",
            delete_connected: "delete_connected_items",
            save_isConnected: "update_item_connected_items",

            selector_tags: "get_tags_with_types",
            import_connect: "import_connect_items_from_tag",

            get_stock: "get_stock_item_data",
        }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });



    }


    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10171);

        // let curFilter = this.props.data[this.id].filters ? this.props.data[this.id].filters._filter : "";
        // let prevFilter = prev.data[this.id].filters ? prev.data[this.id].filters._filter : "";
        // if (prevFilter !== curFilter) {
        //     this.setState({ valueSearch: curFilter })
        // }
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getIcon = (type, tooltip, onClick) => {
        return (<Tooltip title={tooltip}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} onClick={onClick} />
        </Tooltip>)
    }

    openConnectedItems = (mCode, isConnectedItems) => {
        this.setState({ modalConnectedItems: mCode, isConnectedItems }, () => {
            this.props.dataActions.genericDataSetFilter(this.connected_id, this.props.user.companyCode, this.props.user.token, { mCode }, this.api.get_connected)
        })
    }

    saveConnectedItems = () => {
        let dataSend = "mCode\fmConnectedItems\r" + this.state.modalConnectedItems + "\f";
        if (this.state.isConnectedItems) dataSend += this.state.isConnectedItems;

        this.sendAPI(this.api.save_isConnected, dataSend, () => {
            this.setState({
                modalConnectedItems: false,
                isConnectedItems: null,
                tagToConnectItems: null
            }, this.refreshData)
        })
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 14;
        // fall through
        switch (sorter.field) {
            case "key": col--;  // fallsthrough // 0
            case "mId": col--; // fallsthrough 
            case "mCode": col--; // fallsthrough 
            case "mBarCode": col--; // fallsthrough 
            case "mShortName": col--; // fallsthrough 
            case "mIsActive": col--; // fallsthrough 
            case "mIsByWeight": col--; // fallsthrough 
            case "mIsSold": col--; // fallsthrough 
            case "mPrice": col--; // fallsthrough 
            case "mIsPriceFixed": col--; // fallsthrough 
            case "mIsDiscountProhibited": col--; // fallsthrough 
            case "mIsForInventory": col--; // fallsthrough 
            case "mIsSerialize": col--; // fallsthrough 
            case "mIsReverse": col--; // fallsthrough 
            case "mCost": break; // 14
            default:
                col = 0;
        }

        console.log("pagination: ", pagination, "sorter: ", sorter, "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //sortColumn: 'col_1',
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _filter: escapeRegExp(this.state.valueSearch),
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0,
            // ui
            page: 1,
            recordsPerPage: /*5,*/numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            //dataObject: this.id,
            // api
            _filter: '',
            _page_num: 1,
            _rows_num: /*5,*/numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            _tagList: '',
            _isAll: '',
            _isActive: '',
            _isWeightable: '',
            _isFixedPrice: '',
            _isForSale: '',
            _isDiscount: '',
            _saleAlert: '',
            _supplierList: '',
            _isForInventory: '',
            _isSerialize: '',
            _isMatrixReverse: '',
            _connectedItems: '',
            _taxType: '',
            _searchColFilter: '1',
            _notAssociatedTag: '0',
            _notAssociatedSupp: '0',
            _rulerList: '',
            _notAssociatedRuler: '0',
            _itemSerialNumber: '',
            _supplierItemCode: '',
            _tagRelation: '1',
            _mivzaimList: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    filterWithNewItem = (item) => {
        this.setState({ valueSearch: item.mCode }, () => {
            this.showsearch(item.mCode)
        })
    }

    showsearch = (val) => {
        if (val) { this.setState({ filterActive: true }) }

        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                ...this.props.data[this.id].filters,
                page: 1,
                _page_num: 1,

                // _tagList: '',
                // _isAll: '',
                // _isActive: '',
                // _isWeightable: '',
                // _isFixedPrice: '',
                // _isForSale: '',
                // _isDiscount: '',
                // _saleAlert: '',
                // _supplierList: '',
                // _isForInventory: '',
                // _isSerialize: '',
                // _isMatrixReverse: '',
                // _connectedItems: '',
                // _taxType: '',

                _filter: escapeRegExp(val),
                //  _filter: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                _searchColFilter: this.state.searchColFilter
            },
            this.api.get)
    }

    onOpenItemCardModal = (mItemCode, mItemName, mStorageList) => {
        console.log("onOpenItemCardModal", { mItemCode, mItemName, mStorageList })
        this.setState({
            modalStockItemCard: mItemCode,
            modalStockItemCardName: mItemName,
            modalStockItemCardStorageList: mStorageList,
        }
            // , () => {
            //     this.getDataStockItemCard({
            //         mItemCode,
            //         mSerialNumber: "",
            //         mLowerSelectionDate: null,
            //         mUpperSelectionDate: null,
            //         mStorageList,
            //         mCustList: '',
            //     })
            // }
        )
    }

    renderEditCol = (text, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            return (
                <span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                        this.props.uiActions.setIsEditFatherItem(false);
                        this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { itemId: record['mCode'], isMatrixReverseFather: record['mIsReverse'] == '1' });
                    }} />
                    <Popconfirm title={this.getText(10237)}
                        onConfirm={() => {
                            this.sendAPI(this.api.delete, record['mCode'], (ob) => {
                                this.refreshData();
                                if (ob.data === 'not deleted') {
                                    setTimeout(() => { message.error(this.getText(15382)) }, 1000);
                                } else {
                                    if (record.mIsReverse == '1') {
                                        Modal.confirm({
                                            title: "",
                                            content: this.getText(19519),
                                            iconType: 'exclamation-circle',
                                            okText: this.getText(19520),
                                            cancelText: this.getText(19521),
                                            onOk: () => { this.sendAPI("set_matrix_reverse_to_normal", record['mCode'], () => { }) },
                                            onCancel: () => { this.sendAPI("delete_matrix_reverse", record['mCode'], () => { }) },

                                        })
                                    }
                                }
                            });
                        }}
                        okText={this.getText(10238)} cancelText={this.getText(10239)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                    {this.getIcon('copy', this.getText(16417), () => {
                        this.props.uiActions.setIsEditFatherItem(false);
                        this.props.uiActions.showModal(TableIDs.modalCreateEditItem, {
                            itemId: null,
                            copyItemId: record['mCode'],
                            onSave: this.filterWithNewItem
                        });
                        setTimeout(() => {
                            this.props.dataActions.setJson('ITEM_CREATE_EDIT', {
                                editedRecord: { ...this.props.data.ITEM_CREATE_EDIT.editedRecord, key: 0, mId: '', mCode: '' }
                            })
                        }, 1000);
                    })}

                    {this.getIcon('barcode', this.getText(16132), () => {
                        this.setState({
                            itemLabelData: {
                                _item_code: record.mCode,
                                _item_name: record.mShortName,
                                _item_price: record.mPrice,
                                _cust_name: '',
                                _package_qty: 1,
                                _quantity: 1,
                                checked: true,
                                index: 0
                            }
                        });
                        // this.setState({ itemIdToPrint: record.mCode, itemNameToPrint: record.mShortName });
                        this.props.uiActions.showModal(TableIDs.labelPrinter);
                    })}
                    {this.getIcon('apartment', this.getText(16133), () => { this.openConnectedItems(record.mCode, record.mConnectedItems) })}
                    {this.getIcon('eye', this.getText(17164), () => {
                        this.onOpenItemCardModal(record.mCode, record.mShortName, "");
                    })}
                    {this.getIcon('stock', this.getText(16395), () => {
                        const { mCode, mShortName } = record;
                        this.setState({ modalInventoryLimits: { mCode, mShortName } })
                    })}
                    {this.getIcon('table', this.getText(19729), () => {
                        const { mCode, mShortName } = record;
                        this.setState({ modalMivzaim: { mCode, mShortName } })
                    })}

                </span >);
        } else {
            let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        if (isCreate)
                            this.props.dataActions.genericDataCancelInlineCreate(this.id);
                        else
                            this.props.dataActions.genericDataCancelEditing(this.id);
                    }} />
            </span>)
        }
    }

    renderReverseCol = (text, record) => {
        if (text === '1') {
            return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => {
                this.setState({ mParentItemCode: record['mCode'] }, () => {
                    this.props.uiActions.showModal(TableIDs.modalMatrixReverse/*, record['mCode']*/)
                })
            }} />)
        }
    }

    getDataStockItemCard = (params) => {
        this.props.dataActions.genericDataSetFilter(this.stock_id, this.props.user.companyCode, this.props.user.token, params, this.api.get_stock)
    }

    updateBtnName = (text, record) => {
        return (<Button type={"primary"} size="small" onClick={() => {
            this.setState({
                modalChangeBtnText: this.state.modalChangeBtnText.map(x => {
                    return text === 'all' || record.key === x.key ? { ...x, newValue: x.sName, newImage: x.sImgFile } : x
                })
            })
        }}>{this.getText(text === 'all' ? 18295 : 18294)}</Button>)
    }

    inputBtnName = (text, record) => {
        return (<Input value={text} onChange={e => {
            this.setState({
                modalChangeBtnText: this.state.modalChangeBtnText.map(x => {
                    return record.key === x.key ? { ...x, newValue: e.target.value } : x
                })
            })
        }} />)
    }

    inputBtnImg = (text, record) => {
        const onChange = (url) => {
            this.setState({
                modalChangeBtnText: this.state.modalChangeBtnText.map(x => {
                    return record.key === x.key ? { ...x, newImage: url } : x
                })
            })
        }

        return (<PictureView
            {...this.props}
            url={text}
            onChange={onChange}
            onRemove={() => { onChange("") }}
            onUploadedSuccess={() => { }}
            onUploadedFailed={() => { }}
            imageType={'B'}
            aspect={1} ///sliceImage
        />)
    }

    saveUpdateBtnName = () => {
        let dataset = this.state.modalChangeBtnText.filter(f => f.value1 !== f.newValue || f.image !== f.newImage);
        if (dataset.length) {
            let dataSend = "_pos\f_page\f_btn\f_value\f_image";
            dataset.forEach(x => {
                dataSend += "\r" + x.pos + "\f" + x.page + "\f" + x.btn + "\f" + x.newValue + "\f" + (x.newImage ?? "");
            })
            this.sendAPI("save_update_btn_name", dataSend, this.closeModalChangeBtnText)
        } else {
            this.closeModalChangeBtnText()
        }
    }

    closeModalChangeBtnText = () => {
        this.setState({ modalChangeBtnText: null })
    }



    render() {

        let gt = this.getText;
        let sorter = true;
        let render = text => text;
        let renderTooltip = (text, record) => (<Tooltip title={this.getText(19393) + ": " + moment(record.mCreationDate).format("DD/MM/YYYY HH:mm:ss") + " " + this.getText(19394) + ": " + record.mUserName}>{text}</Tooltip>);
        let renderCheckbox = text => (<Checkbox checked={text > 0} disabled={true} />)
        let renderCost = (t, r) => r.mCurrencySymbol + " " + t

        let columns = [
            { title: gt(10224), dataIndex: "mCode", key: "mCode", width: '10%', sorter, render: renderTooltip },
            { title: gt(10225), dataIndex: "mBarCode", key: "mBarCode", width: '10%', sorter, render: renderTooltip },
            { title: gt(10226), dataIndex: "mShortName", key: "mShortName", width: '16%', sorter, render: renderTooltip },
            { title: gt(10227), dataIndex: "mPrice", key: "mPrice", width: '7%', sorter, render },
            { title: gt(13596), dataIndex: "mCost", key: "mCost", width: '7%', sorter, render: renderCost },
            { title: gt(10228), dataIndex: "mIsActive", key: "mIsActive", width: '5%', render: renderCheckbox },
            { title: gt(10229), dataIndex: "mIsByWeight", key: "mIsByWeight", width: '5%', render: renderCheckbox },
            { title: gt(10230), dataIndex: "mIsSold", key: "mIsSold", width: '5%', render: renderCheckbox },
            { title: gt(10231), dataIndex: "mIsPriceFixed", key: "mIsPriceFixed", width: '5%', render: renderCheckbox },
            { title: gt(10232), dataIndex: "mIsDiscountProhibited", key: "mIsDiscountProhibited", width: '5%', render: renderCheckbox },
            { title: gt(10233), dataIndex: "mIsForInventory", key: "mIsForInventory", width: '5%', render: renderCheckbox },
            { title: gt(10234), dataIndex: "mIsSerialize", key: "mIsSerialize", width: '5%', render: renderCheckbox },
            { title: gt(10235), dataIndex: "mIsReverse", key: "mIsReverse", width: '6%', render: this.renderReverseCol },
            { title: gt(10236), width: '9%', render: this.renderEditCol }
        ];

        let actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    this.props.uiActions.setIsEditFatherItem(false);
                    this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { itemId: null, onSave: this.filterWithNewItem })
                }
            }, {
                buttonIsClicked: false,
                buttonType: 'filter',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchItem); },
            }, {
                buttonDisabled: !this.state.filterActive,
                buttonType: 'clear',
                buttonFunction: this.initFilters,
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        let genericActionButtons = [
            <Tooltip title={this.getText(18601)}>
                <Button /*key={0}*/ className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => this.props.uiActions.showModal(TableIDs.modalPricerfile, {})}
                ><Icon style={{ fontSize: 16 }} type={"download"} theme="outlined" /><span>{'Pricer'}</span></Button>
            </Tooltip>,
            <Tooltip title={this.getText(51834)}>
                <Button /*key={0}*/ className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => this.props.uiActions.showModal(TableIDs.modalPLUfile, {})}
                ><Icon style={{ fontSize: 16 }} type={"download"} theme="outlined" /></Button>
            </Tooltip>,
            <RecyclingBin
                id={this.recycling_id}
                getApi={this.api.get_recycling}
                restorationApi={this.api.restoration}
                title={this.getText(15403)}
                columnTitles={[this.getText(15404), this.getText(15405), this.getText(15406), this.getText(15421), this.getText(15407)]}
                popconfirmText={{ title: this.getText(15408), okText: this.getText(15409), cancelText: this.getText(15410) }}
                onClose={this.refreshData}
                {...this.props}
            />]

        let showsearchSuffix = (
            <Tooltip title={this.getText(16498)} >
                <Popover trigger="click" title={this.getText(16498)} placement="bottomLeft" content={(
                    <Radio.Group
                        style={{ textAlign: 'right', direction: "rtl" }}
                        value={this.state.searchColFilter}
                        onChange={e => { this.setState({ searchColFilter: e.target.value }) }}>
                        <Radio value="1">{this.getText(16499)}</Radio><br />
                        <Radio value="2">{this.getText(16500)}</Radio><br />
                        <Radio value="3">{this.getText(16501)}</Radio><br />
                        <Radio value="4">{this.getText(16502)}</Radio><br />
                        <Radio value="5">{this.getText(19886)}</Radio><br />
                        <Radio value="6">{this.getText(19887)}</Radio><br />
                    </Radio.Group>)}>
                    <Icon type="filter" style={{ cursor: 'pointer', marginLeft: 10 }} />
                </Popover>
            </Tooltip>);


        let _subTotal = 0;

        let dataSourceTableStockCard = [];

        const { mMovinType } = this.props.data[this.stock_id].filters;

        this.props.data[this.stock_id].data.forEach((x, index) => {
            let y = x.split("\f");
            if (index == 0) {
                _subTotal = parseFloat(y[0]);
                dataSourceTableStockCard.push({ _outQty: this.getText(17302), _subTotal })
            }

            let _qty = parseFloat(y[9]);
            let _isInDoc = y[17] == '1';
            if (mMovinType == 1 && !_isInDoc) return;
            if (mMovinType == 2 && _isInDoc) return;
            _subTotal += _qty;
            let _inQty = _isInDoc ? _qty : null;
            let _outQty = !_isInDoc ? _qty * -1 : null;

            dataSourceTableStockCard.push({
                index,
                key: index + 1,
                _time: y[3],
                _type: y[5],
                _number: y[6],
                _sumNoTax: y[7],
                _discount: y[8],
                _inQty,
                _outQty,
                _subTotal,
                _storage: y[18] + " - " + y[10],
                _opposite: parseInt(y[16]) ? y[16] + ' - ' + y[15] : '',
                _docCreationDate: y[20]
            })

        })

        return (
            <div/* style={divStyle}*/>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[30, false, 40, 30]}
                    title={this.getText(10240)}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}
                    showsearch={this.showsearch}
                    showsearchWidth={300}
                    showsearchSuffix={showsearchSuffix}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />

                <ModalSalesAlert
                    id={TableIDs.modalSalesAlert}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSalesAlert)}
                    title={this.getText(12574)}
                />

                <ModalCreateEditItem
                    id={TableIDs.modalCreateEditItem}
                    context={this.id} context_api_get={this.api.get}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                    title={this.getText(10241)}
                    onOpenModalConnectedItems={(mCode) => {
                        let isConnectedItems = this.props.data[this.id].data.map(x => x.split('\f')).find(f => f[1] === mCode)[14];
                        this.openConnectedItems(mCode, isConnectedItems)
                    }}
                    checkButtonsOnSave={(code, sName, sImgFile) => {
                        this.sendAPI("check_buttons_on_save_item", "_code\f_name\f_imgFile\r" + code + "\f" + sName + "\f" + (sImgFile ?? ""), ob => {
                            if (ob.data) {
                                this.setState({
                                    modalChangeBtnText: ob.data.split("\r").map((x, index) => {
                                        let y = x.split("\f");
                                        return {
                                            key: x, index, pos: y[0], page: y[1], btn: y[2],
                                            value1: y[3], newValue: y[3],
                                            image: y[4], newImage: y[4],
                                            code, sName, sImgFile
                                        }
                                    })
                                })
                            }
                        })
                    }}
                />

                <GenericModal
                    visible={this.state.modalChangeBtnText !== null}
                    onCancel={this.closeModalChangeBtnText}
                    title={this.getText(18289)}
                    width={1000}
                    footer={[
                        <Button onClick={this.closeModalChangeBtnText}>{this.getText(18296)}</Button>,
                        <Button type="primary" onClick={this.saveUpdateBtnName}>{this.getText(18297)}</Button>
                    ]}>
                    {this.state.modalChangeBtnText !== null ? (
                        <ResponsiveTable
                            tableOnly
                            dataSource={this.state.modalChangeBtnText}
                            columns={[
                                { title: this.getText(18290), key: "pos", dataIndex: "pos", render: t => t },
                                { title: this.getText(18291), key: "page", dataIndex: "page", render: t => t },
                                { title: this.getText(18292), key: "btn", dataIndex: "btn", render: t => t },
                                { title: this.getText(18293), key: "newValue", dataIndex: "newValue", render: this.inputBtnName },
                                { title: this.getText(19388), key: "newImage", dataIndex: "newImage", render: this.inputBtnImg },
                                { title: this.updateBtnName("all"), key: "newValue", dataIndex: "newValue", render: this.updateBtnName },
                            ]}
                            pagination={{ pageSize: 10 }}
                        />
                    ) : ""}
                </GenericModal>

                <ModalAdvanceSearch
                    id={TableIDs.modalSearchItem}
                    getApi={this.api.get} context={this.id}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchItem)}
                    title={this.getText(10242)}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e }) }}
                />

                <ModalMatrixReverse
                    id={TableIDs.modalMatrixReverse}
                    context={this.id} context_api_get={this.api.get} mParentItemCode={this.state.mParentItemCode}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalMatrixReverse)}
                    title={this.getText(10243)}
                />

                { /*   <LabelPrinter
                    id={TableIDs.labelPrinter}
                    labels={[{ _ITEM_CODE: this.state.itemIdToPrint, _CUSTOMER_ID: 1, _CART_CUSTOMER_NAME: this.state.itemNameToPrint }]}
                    qtyInput={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
                    title={this.getText(10244)}
                />
*/}
                <LabelPrinter
                    {...this.props}
                    id={TableIDs.labelPrinter}
                    toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
                    title={this.getText(10244)}
                    labels={this.state.itemLabelData? [this.state.itemLabelData]: []}
                    onChangeLabels={l=>{this.setState({itemLabelData: l[0]})}}
                />

                <ModalDownloadPLUfile
                    id={TableIDs.modalPLUfile}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalPLUfile)}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalDownloadPricerfile
                    id={TableIDs.modalPricerfile}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalPricerfile)}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalConnectedItems
                    {...this.props}
                    modalConnectedItems={this.state.modalConnectedItems}
                    isConnectedItems={this.state.isConnectedItems}
                    tagToConnectItems={this.state.tagToConnectItems}
                    onChangeState={(newState, next) => { this.setState(newState, next) }}
                />

                <ModalCreateEditItemStock
                    {...this.props}
                    visible={this.state.modalInventoryLimits != null}
                    onCancel={() => { this.setState({ modalInventoryLimits: null }) }}
                    itemCode={this.state.modalInventoryLimits?.mCode}
                    itemName={this.state.modalInventoryLimits?.mShortName}
                    onOpenItemCardModal={this.onOpenItemCardModal}
                />

                <ModalItemMivzaim
                    {...this.props}
                    visible={this.state.modalMivzaim != null}
                    onCancel={() => { this.setState({ modalMivzaim: null }) }}
                    itemCode={this.state.modalMivzaim?.mCode}
                    itemName={this.state.modalMivzaim?.mShortName}
                />


                <StockItemCard
                    {...this.props}
                    isModalFromItems
                    modalItemCode={this.state.modalStockItemCard}
                    modalItemName={this.state.modalStockItemCardName}
                    modalItemStorageList={this.state.modalStockItemCardStorageList}
                    modalItemCodeClose={() => { this.setState({ modalStockItemCard: '', modalStockItemCardName: '', modalStockItemCardStorageList: '' }) }}
                    refreshData={this.getDataStockItemCard}
                    dataSourceTable={dataSourceTableStockCard}
                />


            </div>
        )
    }
}
export default ItemsTable;
