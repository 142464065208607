/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Button, Input } from "antd";
import "../App.css";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

const Search = Input.Search;

type DataSelectModalProps = {
    data: Array<string>,
    visible: boolean,
    onChange: Function,
    defaultValue: string,
    zIndex: number,
    keyColumnLabel: string,
    nameColumnLabel: string,
    title: string,
    searchPlaceholder: string,
    tableNotFound: string
};

type State = { pagination: Object, query: string };

class DataSelectModal extends Component<DataSelectModalProps, State> {
    constructor(props: DataSelectModalProps) {
        super(props);
        this.state = {
            query: "",
            pagination: {
                size: "small",
                hideOnSinglePage: true,
                defaultCurrent: 1,
            },
            filters: {},
            sorter: {},
        };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev: DataSelectModalProps) {
        if (this.props.defaultValue !== prev.defaultValue) {
            this.handleSearch(this.props.defaultValue, true);
        }

        if (this.props.visible && !prev.visible) {
            setTimeout(() => {
                let searchInput = document.getElementById("search_in_select_modal");
                if (searchInput) {
                    searchInput.focus();
                    searchInput.select();
                }
            }, 1000);
        }
    }

    handleSearch(query: string, force: boolean) {

        if (query) {
            if (query.length > 2 || force) {
                this.setState({ ...this.state, query: query, pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
            }
        } else {
            this.clearSearch();
        }
    }

    clearSearch() {
        this.setState({ ...this.state, query: "", pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
    }

    render() {
        let data = Jax.get(this.props, "data", [])
            .filter((value) => value && (this.state.query.length === 0 || value.indexOf(this.state.query) > -1))
            .map((node: string) => {
                let data = node.split("\f");
                let headers = this.props.columns ? this.props.columns.map(k => k.key) : ["key", "name"]
                let res = {}
                headers.forEach((y, yi) => res = { ...res, [y]: data[yi] })
                return res
                // return { key: data[0], name: data[1] };
            });

        if (this.state.sorter.field) {
            const { order, field } = this.state.sorter
            data.sort((a, b) => {
                if (order === "ascend") {
                    return field === 'key' ? a[field] - b[field] : a[field].localeCompare(b[field])
                } else {
                    return field === 'key' ? b[field] - a[field] : b[field].localeCompare(a[field])
                }
            })
        }


        const keyColumnLabel = this.props.keyColumnLabel;
        const nameColumnLabel = this.props.nameColumnLabel;

        const columns = [
            ...(this.props.columns ?? [{
                title: keyColumnLabel,
                dataIndex: "key",
                key: "key",
                sorter: true
            },
            {
                title: nameColumnLabel,
                dataIndex: "name",
                key: "name",
                sorter: true
            }]),
            {
                title: "",
                render: (text, record) => {
                    return <Button type={record.key === this.props.defaultValue ? "primary" : ""} onClick={() => {
                        this.props.onChange({ data: record.key, dataName: record[this.props.dataNameField ?? 'name'], visible: false, })
                    }}>{this.getText(12492)}</Button>
                }
            }
        ];


        return (
            <Modal zIndex={this.props.zIndex} title={this.props.title} visible={this.props.visible} closable={false} destroyOnClose={true} footer={[
                <Button key={"back"} onClick={() => { this.props.onChange({ visible: false }) }}>{this.getText(12490)}</Button>,
                <Button key={"clear"} onClick={() => { this.props.onChange({ data: null, dataName: "", visible: false }) }}>{this.getText(12491)}</Button>
            ]} afterClose={this.clearSearch.bind(this)} width={this.props.width}>
                <div>
                    <Search
                        id={"search_in_select_modal"}
                        defaultValue={this.props.defaultValue}
                        placeholder={this.props.searchPlaceholder}
                        onChange={(value) => { this.handleSearch.apply(this, [value.target.value, false]); }}
                        onSearch={(value) => { this.handleSearch.apply(this, [value, true]); }}
                        size={"large"} enterButton
                    />
                    <ResponsiveTable
                        ui={this.props.ui}
                        tableOnly={true}
                        columns={columns}
                        dataSource={data}
                        pagination={this.state.pagination}
                        onChange={(pagination, filters, sorter) => { this.setState(Object.assign({}, this.state, { pagination, filters, sorter })) }}
                        locale={{ emptyText: this.props.tableNotFound }}
                    />
                </div>
            </Modal>
        );
    }

}

export default DataSelectModal;