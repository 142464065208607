/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Checkbox, Input, message } from "antd";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import { escapeRegExp } from "lodash";
import ResponsiveTable from "../../components/ResponsiveTable";


class ServiceSubjects extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalDetails: null,
        };

        this.id = "SERVICE_SUBJECTS";
        this.details_id = "SERVICE_SUBJECTS_DETAILS";

        this.api = {
            get: "get_service_subjects",
            create_edit: "create_edit_service_subjects",
            delete: "delete_service_subjects",

            get_details: "get_service_subjects_details",
            create_edit_details: "create_edit_service_subjects_details",
            order_details: "order_service_subjects_details",
            delete_details: "delete_service_subjects_details",
        }

        this.selectors = [
            { id: "SELECTOR_TAG_TYPE_9", api: "get_tags_from_type_9" },
        ];


        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.details_id] === undefined) props.data[this.details_id] = { ...props.data.genericData };

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19933);
    }

    renderDesc = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        if (editing == record.key) {
            return (<Input
                value={editedRecord.mDesc}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mDesc: e.target.value }) }}
            />)
        } else {
            return text;
        }
    }

    renderIsActive = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];

        return (<Checkbox
            disabled={editing != record.key}
            checked={editing == record.key ? editedRecord.mIsActive == '1' : text == 1}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mIsActive: e.target.checked ? '1' : '0' }) }}
        />)

    }

    renderTagList = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.id];
        const value = editing == record.key ? editedRecord.mTagList : text

        return (<GenericSelector {...this.props} {...this.selectors[0]} multi
            disabled={editing != record.key}
            value={value ? value.split(",") : []}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mTagList: e ? e.join(",") : "" }) }}
        />)
    }

    renderEditCol = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id];

        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    if (!editedRecord.mDesc) {
                        message.error(this.getText(19946))
                    } else if (!editedRecord.mTagList) {
                        message.error(this.getText(19945))
                    } else {
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            this.api.create_edit,
                            this.api.get);
                    }
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(19941), okText: this.getText(19942), cancelText: this.getText(19943) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                genericIcons={[{ type: "table", onClick: () => { this.setState({ modalDetails: record }, () => { this.apiDetails(record.key) }) }, tooltip: this.getText(19944) }]}
            />)
        }
    }

    apiDetails = (_id) => {
        this.props.dataActions.genericDataSetFilter(
            this.details_id,
            this.props.user.companyCode,
            this.props.user.token,
            { _id },
            this.api.get_details);
    }

    renderDetailDesc = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.details_id];

        if (editing == record.key) {
            return (<Input
                value={editedRecord.dDesc}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.details_id, { dDesc: e.target.value }) }}
            />)
        } else {
            return text;
        }
    }

    renderDetailIsActive = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.details_id];

        return (<Checkbox
            disabled={editing != record.key}
            checked={editing == record.key ? editedRecord.dIsActive == '1' : text == 1}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.details_id, { dIsActive: e.target.checked ? '1' : '0' }) }}
        />)

    }

    renderDetailTagList = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.details_id];
        const value = editing == record.key ? editedRecord.dTagList : text

        return (<GenericSelector {...this.props} {...this.selectors[0]} multi
            disabled={editing != record.key}
            value={value ? value.split(",") : []}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.details_id, { dTagList: e ? e.join(",") : "" }) }}
        />)
    }

    renderDetailEditCol = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.details_id];

        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    if (!editedRecord.dDesc) {
                        message.error(this.getText(19946))
                    } else {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.details_id, this.props.user.companyCode, this.props.user.token,
                            this.api.create_edit_details, this.api.get_details,
                            { _ssId: this.state.modalDetails.key });
                    }
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.details_id);
                    else this.props.dataActions.genericDataCancelEditing(this.details_id);
                }} />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.details_id, record.key, record.index) }}
                deleteQuetion={{ title: this.getText(19950), okText: this.getText(19942), cancelText: this.getText(19943) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.details_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_details, this.api.get_details);
                }}
            />)
        }
    }


    render() {
        const gt = this.getText;
        const render = t => t;
        const sorter = true;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(19935)}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
                searchBox
                columns={[
                    { title: gt(19936), key: "mID", dataIndex: "mID", width: "10%", sorter, render },
                    { title: gt(19937), key: "mDesc", dataIndex: "mDesc", width: "30%", sorter, render: this.renderDesc },
                    { title: gt(19938), key: "mIsActive", dataIndex: "mIsActive", width: "10%", sorter, render: this.renderIsActive },
                    { title: gt(19939), key: "mTagList", dataIndex: "mTagList", width: "40%", render: this.renderTagList },
                    { title: gt(19940), width: "10%", render: this.renderEditCol },
                ]}
                />
            <GenericModal
                visible={this.state.modalDetails !== null}
                onCancel={() => { this.setState({ modalDetails: null }) }}
                title={gt(19949) + " " + this.state.modalDetails?.mDesc}
                actionButtons={[
                    {
                        buttonType: 'add',
                        buttonFunction: () => {
                            this.props.dataActions.genericDataStartInlineCreate(this.details_id)
                        }
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: () => {
                            this.props.dataActions.genericDataGetRequested(
                                this.details_id, this.props.user.companyCode, this.props.user.token, this.api.get_details);
                            }
                        },
                    ]} >
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.details_id]}
                    columns={[
                        { title: gt(19936), key: "dID", dataIndex: "dID", width: "10%", render },
                        { title: gt(19947), key: "dOrder", dataIndex: "dOrder", width: "10%", render },
                        { title: gt(19948), key: "dDesc", dataIndex: "dDesc", width: "30%", render: this.renderDetailDesc },
                        { title: gt(19938), key: "dIsActive", dataIndex: "dIsActive", width: "10%", render: this.renderDetailIsActive },
                        { title: gt(19939), key: "dTagList", dataIndex: "dTagList", width: "30%", render: this.renderDetailTagList },
                        { title: gt(19940), width: "10%", render: this.renderDetailEditCol },
                    ]}
                    pagination={false}
                    scroll={{ y: window.innerHeight - 300 }}
                    id={this.details_id}
                    onDragAndDrop={(keyFrom, keyTo) => {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            this.details_id, this.props.user.companyCode, this.props.user.token,
                            this.api.order_details, this.api.get_details,
                            { _ssId: this.state.modalDetails.key, _id: keyFrom, _toId: keyTo })
                    }}
                />
            </GenericModal>
        </div>)
    }

}

export default ServiceSubjects;